.184_2 { 
	overflow:hidden;
}
.e184_2 { 
	background-color:rgba(255, 255, 255, 1);
	width:1200px;
	height:1294px;
	position:absolute;
}
.e184_3 { 
	background-color:rgba(83.00000265240669, 117.00000062584877, 226.0000017285347, 1);
	width:1200px;
	height:87px;
	position:absolute;
	left:0px;
	top:1207px;
}
.e184_4 { 
	color:rgba(255, 255, 255, 1);
	width:267px;
	height:24px;
	position:absolute;
	left:41px;
	top:30px;
	font-family:'Poppins';
	text-align:left;
	font-size:15px;
	letter-spacing:0;
}
.e184_5 { 
	color:rgba(255, 255, 255, 1);
	width:97px;
	height:24px;
	position:absolute;
	left:960px;
	top:30px;
	font-family:'Poppins';
	text-align:left;
	font-size:15px;
	letter-spacing:0;
}
.e184_6 { 
	color:rgba(255, 255, 255, 1);
	width:327px;
	height:26px;
	position:absolute;
	left:436px;
	top:28px;
	font-family:'Poppins';
	text-align:left;
	font-size:15px;
	letter-spacing:0;
}
.e184_8 { 
	width:24px;
	height:24px;
	position:absolute;
	left:1067px;
	top:28px;
	background-image:url(images/facebook.png);
	background-repeat:no-repeat;
	background-size:cover;
}
.e184_9 { 
	width:64px;
	height:64px;
	position:absolute;
	left:1093px;
	top:9px;
}
.e184_10 { 
	width:24px;
	height:24px;
	position:absolute;
	left:1101px;
	top:28px;
	background-image:url(images/instagram.png);
	background-repeat:no-repeat;
	background-size:cover;
}
.e184_11 { 
	width:24px;
	height:24px;
	position:absolute;
	left:1134px;
	top:28px;
	background-image:url(images/linkedin.png);
	background-repeat:no-repeat;
	background-size:cover;
}
.e184_12 { 
	background-color:rgba(255, 255, 255, 0);
	width:1200px;
	height:106px;
	position:absolute;
	left:0px;
	top:0px;
}
.e184_13 { 
	width:183px;
	height:47px;
	position:absolute;
	left:48px;
	top:30px;
	background-image:url(images/monitor-exam-logo.png);
	background-repeat:no-repeat;
	background-size:cover;
}
.e184_19 { 
	width:126px;
	height:38px;
	position:absolute;
	left:1005px;
	top:35px;
}
.e184_20 { 
	box-shadow:1px 1px 6px rgba(0, 0, 0, 0.10999999940395355);
	background-color:rgba(83.00000265240669, 117.00000062584877, 226.0000017285347, 1);
	width:126px;
	height:38px;
	position:absolute;
	left:0px;
	top:0px;
	border-top-left-radius:3px;
	border-top-right-radius:3px;
	border-bottom-left-radius:3px;
	border-bottom-right-radius:3px;
}
.e184_21 { 
	color:rgba(255, 255, 255, 1);
	width:61px;
	height:23px;
	position:absolute;
	left:33px;
	top:6px;
	font-family:'Poppins';
	text-align:center;
	font-size:15px;
	letter-spacing:0;
}
.e184_118 { 
	width:496px;
	height:25px;
	position:absolute;
	left:484px;
	top:41px;
}
.e184_119 { 
	color:rgba(0, 0, 0, 1);
	width:45px;
	height:23px;
	position:absolute;
	left:0px;
	top:2px;
	font-family:'Poppins';
	text-align:center;
	font-size:15px;
	letter-spacing:0;
}
.e184_120 { 
	color:rgba(0, 0, 0, 1);
	width:51px;
	height:23px;
	position:absolute;
	left:69px;
	top:2px;
	font-family:'Poppins';
	text-align:center;
	font-size:15px;
	letter-spacing:0;
}
.e184_121 { 
	color:rgba(0, 0, 0, 1);
	width:61px;
	height:23px;
	position:absolute;
	left:141px;
	top:2px;
	font-family:'Poppins';
	text-align:center;
	font-size:15px;
	letter-spacing:0;
}
.e184_122 { 
	color:rgba(0, 0, 0, 1);
	width:60px;
	height:23px;
	position:absolute;
	left:226px;
	top:2px;
	font-family:'Poppins';
	text-align:center;
	font-size:15px;
	letter-spacing:0;
}
.e184_123 { 
	color:rgba(0, 0, 0, 1);
	width:67px;
	height:23px;
	position:absolute;
	left:310px;
	top:1px;
	font-family:'Poppins';
	text-align:center;
	font-size:15px;
	letter-spacing:0;
}
.e184_124 { 
	color:rgba(0, 0, 0, 1);
	width:98px;
	height:23px;
	position:absolute;
	left:398px;
	top:0px;
	font-family:'Poppins';
	text-align:center;
	font-size:15px;
	letter-spacing:0;
}
.e184_22 { 
	background-color:rgba(247.00000047683716, 250.00000029802322, 253.0000001192093, 1);
	width:1200px;
	height:1101px;
	position:absolute;
	left:0px;
	top:106px;
}
.184_23 { 
	border:5px solid rgba(0, 0, 0, 1);
}
.e184_23 { 
	width:326px;
	height:393px;
	position:absolute;
	left:71px;
	top:460px;
}
.e184_24 { 
	color:rgba(83.00000265240669, 117.00000062584877, 226.0000017285347, 1);
	width:246px;
	height:36px;
	position:absolute;
	left:35px;
	top:114px;
	font-family:'Poppins';
	text-align:center;
	font-size:24px;
	letter-spacing:0;
}
.e184_32 { 
	width:85px;
	height:85px;
	position:absolute;
	left:115px;
	top:19px;
	background-image:url(images/plan_s1.png);
	background-repeat:no-repeat;
	background-size:cover;
}
.e189_199 { 
	color:rgba(0, 0, 0, 1);
	width:253px;
	height:210px;
	position:absolute;
	left:35px;
	top:159px;
	font-family:'Poppins';
	text-align:left;
	font-size:20px;
	letter-spacing:0;
}
.184_33 { 
	border:5px solid rgba(0, 0, 0, 1);
}
.e184_33 { 
	width:326px;
	height:393px;
	position:absolute;
	left:447px;
	top:583px;
}
.e184_34 { 
	color:rgba(101.00000157952309, 118.00000056624413, 255, 1);
	width:258px;
	height:36px;
	position:absolute;
	left:33px;
	top:104px;
	font-family:'Poppins';
	text-align:center;
	font-size:24px;
	letter-spacing:0;
}
.e184_43 { 
	width:85px;
	height:84.03955078125px;
	position:absolute;
	left:119px;
	top:20px;
	background-image:url(images/plan_s2.png);
	background-repeat:no-repeat;
	background-size:cover;
}
.e189_202 { 
	color:rgba(0, 0, 0, 1);
	width:299px;
	height:231px;
	position:absolute;
	left:12px;
	top:149px;
	font-family:'Poppins';
	text-align:left;
	font-size:20px;
	letter-spacing:0;
}
.e189_208 { 
	width:502px;
	height:273px;
	position:absolute;
	left:373px;
	top:105px;
	background-image:url(images/how_it_works.png);
	background-repeat:no-repeat;
	background-size:cover;
}
.184_44 { 
	border:5px solid rgba(0, 0, 0, 1);
}
.e184_44 { 
	width:326px;
	height:393px;
	position:absolute;
	left:824px;
	top:460px;
}
.e184_45 { 
	color:rgba(101.00000157952309, 118.00000056624413, 255, 1);
	width:209px;
	height:36px;
	position:absolute;
	left:51px;
	top:109px;
	font-family:'Poppins';
	text-align:center;
	font-size:24px;
	letter-spacing:0;
}
.e184_53 { 
	width:85px;
	height:85.25148010253906px;
	position:absolute;
	left:113px;
	top:24px;
	background-image:url(images/plan_s3.png);
	background-repeat:no-repeat;
	background-size:cover;
}
.e189_203 { 
	color:rgba(0, 0, 0, 1);
	width:290px;
	height:180px;
	position:absolute;
	left:23px;
	top:151px;
	font-family:'Poppins';
	text-align:left;
	font-size:20px;
	letter-spacing:0;
}
.e184_54 { 
	width:873px;
	height:99px;
	position:absolute;
	left:64px;
	top:46px;
}
.e184_55 { 
	color:rgba(0, 0, 0, 1);
	width:873px;
	height:38px;
	position:absolute;
	left:0px;
	top:0px;
	font-family:'Poppins';
	text-align:left;
	font-size:36px;
	letter-spacing:0;
}
.e184_56 { 
	color:rgba(33.00000183284283, 37.00000159442425, 44.000001177191734, 1);
	width:864.5419311523438px;
	height:40px;
	position:absolute;
	left:0px;
	top:59px;
	font-family:'Poppins';
	text-align:left;
	font-size:22px;
	letter-spacing:0;
}
.e189_321 { 
	width:330px;
	height:92px;
	position:absolute;
	left:67px;
	top:366px;
}
.e189_322 { 
	border-radius:500px;
	background-color:rgba(83.00000265240669, 117.00000062584877, 226.0000017285347, 1);
	width:47.37635040283203px;
	height:58px;
	position:absolute;
	left:140.49459838867188px;
	top:34px;
}
.e189_323 { 
	color:rgba(255, 255, 255, 1);
	width:7.0519914627075195px;
	height:19px;
	position:absolute;
	left:160.75230407714844px;
	top:54px;
	font-family:'Poppins';
	text-align:left;
	font-size:24px;
	letter-spacing:0;
}
.e189_324 { 
	transform: rotate(179.60848095115873deg);
	width:142.13229370117188px;
	height:0px;
	position:absolute;
	left:142.12905883789062px;
	top:62px;
}
.189_324 { 
	border:2px solid rgba(83.00000265240669, 117.00000062584877, 226.0000017285347, 1);
}
.e189_325 { 
	transform: rotate(180deg);
	width:142.1290740966797px;
	height:0px;
	position:absolute;
	left:330px;
	top:63px;
}
.189_325 { 
	border:2px solid rgba(83.00000265240669, 117.00000062584877, 226.0000017285347, 1);
}
.e189_326 { 
	color:rgba(0, 0, 0, 1);
	width:42.31195068359375px;
	height:27px;
	position:absolute;
	left:142.61843872070312px;
	top:0px;
	font-family:'Poppins';
	text-align:center;
	font-size:18px;
	letter-spacing:0;
}
.e189_327 { 
	width:330px;
	height:92px;
	position:absolute;
	left:444px;
	top:488px;
}
.e189_328 { 
	border-radius:500px;
	background-color:rgba(83.00000265240669, 117.00000062584877, 226.0000017285347, 1);
	width:47.37635040283203px;
	height:58px;
	position:absolute;
	left:140.49459838867188px;
	top:34px;
}
.e189_329 { 
	color:rgba(255, 255, 255, 1);
	width:7.0519914627075195px;
	height:19px;
	position:absolute;
	left:157.75244140625px;
	top:54px;
	font-family:'Poppins';
	text-align:left;
	font-size:24px;
	letter-spacing:0;
}
.e189_330 { 
	transform: rotate(179.60848095115873deg);
	width:142.13229370117188px;
	height:0px;
	position:absolute;
	left:142.12905883789062px;
	top:62px;
}
.189_330 { 
	border:2px solid rgba(83.00000265240669, 117.00000062584877, 226.0000017285347, 1);
}
.e189_331 { 
	transform: rotate(180deg);
	width:142.1290740966797px;
	height:0px;
	position:absolute;
	left:330px;
	top:63px;
}
.189_331 { 
	border:2px solid rgba(83.00000265240669, 117.00000062584877, 226.0000017285347, 1);
}
.e189_332 { 
	color:rgba(0, 0, 0, 1);
	width:42.31195068359375px;
	height:27px;
	position:absolute;
	left:142.61843872070312px;
	top:0px;
	font-family:'Poppins';
	text-align:center;
	font-size:18px;
	letter-spacing:0;
}
.e189_333 { 
	width:330px;
	height:92px;
	position:absolute;
	left:815px;
	top:364px;
}
.e189_334 { 
	border-radius:500px;
	background-color:rgba(83.00000265240669, 117.00000062584877, 226.0000017285347, 1);
	width:47.37635040283203px;
	height:58px;
	position:absolute;
	left:140.49459838867188px;
	top:34px;
}
.e189_335 { 
	color:rgba(255, 255, 255, 1);
	width:7.0519914627075195px;
	height:19px;
	position:absolute;
	left:157.75244140625px;
	top:54px;
	font-family:'Poppins';
	text-align:left;
	font-size:24px;
	letter-spacing:0;
}
.e189_336 { 
	transform: rotate(179.60848095115873deg);
	width:142.13229370117188px;
	height:0px;
	position:absolute;
	left:142.12905883789062px;
	top:62px;
}
.189_336 { 
	border:2px solid rgba(83.00000265240669, 117.00000062584877, 226.0000017285347, 1);
}
.e189_337 { 
	transform: rotate(180deg);
	width:142.1290740966797px;
	height:0px;
	position:absolute;
	left:330px;
	top:63px;
}
.189_337 { 
	border:2px solid rgba(83.00000265240669, 117.00000062584877, 226.0000017285347, 1);
}
.e189_338 { 
	color:rgba(0, 0, 0, 1);
	width:42.31195068359375px;
	height:27px;
	position:absolute;
	left:142.61843872070312px;
	top:0px;
	font-family:'Poppins';
	text-align:center;
	font-size:18px;
	letter-spacing:0;
}
