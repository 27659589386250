/* THIS IS A FREE VERSION OF STARTUP! */
/* 
	Table of contents:
	1. Common styles
	2. Common responsive styles
	3. Headers
	4. Navigations
	5. Contents
	6. Testimonials
	7. Features
	8. Forms
	9. Call to Actions
	10. Teams
	11. Pricing Tables
	12. Contacts
	13. Showcases
	14. Footers
	15. Ecommerce
	16. Applications
*/
/* 1. Common styles */
body {
  font-family: 'Poppins' ;
  font-weight: 400;
  line-height: 26px;
  color: #666666;
  -webkit-font-smoothing: antialiased;
}
body.dev {
  background: #ccc;
  min-height: 10000px;
}

nav, header, section, footer {
  position: relative;
}

footer {
	color:white;
}

header, section, [data-bg-src], [data-bg-src-2x] {
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
}

header, section {
  overflow: hidden;
}

header nav {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000;
}

header {
	z-index:100;
}

nav {
  width: 100%;
}
nav.transparent {
  background: transparent;
}

a, button {
  cursor: pointer;
  transition: all 0.25s;
}
a:hover, button:hover {
  text-decoration: none;
}
a:active, a:focus, button:active, button:focus {
  text-decoration: none;
  outline: none;
}


h1, h2, h3, h4, h5, h6 {
  -webkit-margin-before: 0;
  -webkit-margin-after: 0;
  margin: 0;
  font-weight: 700;
  font-family: 'Poppins', ;
}

h1, h2 {
  font-size: 58px;
  line-height: 70px;
}
h1.big, h2.big {
  font-size: 72px;
  line-height: 86px;
}
h1.small, h2.small {
  font-size: 42px;
  line-height: 52px;
  font-weight: 700;
}

h3 {
  font-size: 42px;
  line-height: 52px;
}

b, strong {
  font-weight: 700;
}

.alert {
  display: none;
  position: fixed;
  top: 50px;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 970px;
  z-index: 2000;
}
.alert-dismissable .close, .alert-dismissible .close {
  top: 0;
}

/* Change AOS plugin default settings */
[data-aos=fade-down] {
  transform: translate3d(0, -35px, 0);
}

[data-aos=fade-up] {
  transform: translate3d(0, 35px, 0);
}


.container {
  position: relative;
}

::-moz-selection {
  background: #f5f6fa;
  color: #aaaaaa;
}

::-webkit-selection {
  background: #f5f6fa;
  color: #aaaaaa;
}

::selection {
  background: #f5f6fa;
  color: #aaaaaa;
}

/* 2. Common responsive styles */
/* Smaller than 1200px */
@media (max-width: 1199.98px) {
  header[class^=pt-], header[class*=" pt-"], section[class^=pt-], section[class*=" pt-"] {
    padding-top: 80px;
  }
  header[class^=pb-], header[class*=" pb-"], section[class^=pb-], section[class*=" pb-"] {
    padding-bottom: 80px;
  }

  h1 br, h2 br, h3 br, .text-adaptive br {
    display: none;
  }

  h1, h2 {
    font-size: 52px;
    line-height: 62px;
  }
  h1.big, h2.big {
    font-size: 62px;
    line-height: 72px;
  }
  h1.small, h2.small {
    font-size: 36px;
    line-height: 46px;
  }

  h3 {
    font-size: 36px;
    line-height: 46px;
  }

  .navigation_mobile {
    display: block;
  }
}
/* Smaller than 992px */
@media (max-width: 991.98px) {
  h1, h2 {
    font-size: 42px;
    line-height: 52px;
  }
  h1.big, h2.big {
    font-size: 52px;
    line-height: 62px;
  }
  h1.small, h2.small {
    font-size: 32px;
    line-height: 42px;
  }

  h3 {
    font-size: 32px;
    line-height: 42px;
  }

  .text-adaptive.f-24 {
    font-size: 22px;
    line-height: 32px;
  }
  .text-adaptive.f-22 {
    font-size: 20px;
    line-height: 30px;
  }
  .text-adaptive.f-20 {
    font-size: 18px;
    line-height: 28px;
  }

  .alert {
    top: 10px;
    left: 20px;
    right: 20px;
    transform: none;
    width: auto;
    padding-left: 40px;
  }
}
/* Smaller than 768px */
@media (max-width: 767.98px) {
  h1, h2 {
    font-size: 32px;
    line-height: 42px;
  }
  h1.big, h2.big {
    font-size: 42px;
    line-height: 52px;
  }
  h1.small, h2.small {
    font-size: 26px;
    line-height: 36px;
  }

  h3 {
    font-size: 26px;
    line-height: 36px;
  }

  .text-adaptive.f-24 {
    font-size: 20px;
    line-height: 30px;
  }
  .text-adaptive.f-22 {
    font-size: 18px;
    line-height: 28px;
  }
  .text-adaptive.f-20 {
    font-size: 16px;
    line-height: 26px;
  }
  .text-adaptive.f-18 {
    font-size: 16px;
    line-height: 26px;
  }

  .btn.lg {
    height: 50px;
    line-height: 50px;
    padding: 0 30px;
    font-size: 18px;
    font-weight: 500;
  }
  .btn.lg[class^=border-], .btn.lg[class*=" border-"] {
    line-height: 46px;
  }
  .btn.xl {
    height: 60px;
    line-height: 60px;
    padding: 0 35px;
    font-size: 18px;
    font-weight: 500;
  }
  .btn.xl[class^=border-], .btn.xl[class*=" border-"] {
    line-height: 56px;
  }
  .footer-social-icons {
    padding-top: 20px;
    text-align: center;
    border-top: 1px solid rgb(202 202 202 / 34%);
  }
  span.copyright {
    padding-top: 20px;
    border-top: 1px solid rgb(202 202 202 / 34%);
  }

  .input.lg {
    height: 50px;
    font-size: 18px;
    font-weight: 500;
  }
}
/* Smaller than 576px */
@media (max-width: 575.98px) {
  h1, h2 {
    font-size: 26px;
    line-height: 36px;
  }
  h1.big, h2.big {
    font-size: 32px;
    line-height: 42px;
  }
  h1.small, h2.small {
    font-size: 22px;
    line-height: 32px;
  }

  .btn.xl {
    height: 50px;
    line-height: 50px;
    padding: 0 30px;
  }
  .btn.xl[class^=border-], .btn.xl[class*=" border-"] {
    line-height: 46px;
  }
}
/* 3. Headers */
@media (max-width: 767.98px) {
  .header_1 .text-adaptive {
    margin-top: 20px;
  }
  .header_1 .buttons {
    margin-top: 40px;
  }
}

@media (max-width: 767.98px) {
  .header_2 .text-adaptive {
    margin-top: 20px;
  }
  .header_2 form {
    margin-top: 40px;
  }
  .header_2 form .input {
    text-align: center;
    max-width: 100%;
    width: 100%;
  }
  .header_2 form .btn {
    margin-top: 15px;
  }
}

/* 4. Navigations */
@media (max-width: 1199.98px) {
  .navigation_2 a {
    margin: 0 15px;
  }
  .navigation_2 .dropdown a {
    margin: 0;
  }
}

/* 5. Contents */
@media (max-width: 767.98px) {
  .content_1 h2 {
    margin-top: 10px;
  }
}

@media (max-width: 991.98px) {
  .content_2 .text-adaptive {
    margin-top: 15px;
  }
}

/* 6. Testimonials */
@media (max-width: 767.98px) {
  .testimonial_2 .text-adaptive {
    margin-top: 10px;
    margin-bottom: 25px;
  }
}

/* 7. Features */
@media (max-width: 1199.98px) {
  .feature_1[class^=pb-], .feature_1[class*=" pb-"] {
    padding-bottom: 30px;
  }
}
@media (max-width: 767.98px) {
  .feature_1 .title {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .feature_1 .title br {
    display: none;
  }
}

@media (max-width: 1199.98px) {
  .feature_2[class^=pb-], .feature_2[class*=" pb-"] {
    padding-bottom: 30px;
  }
  .feature_2 .description {
    margin-top: 20px;
  }
}


/* 9. Call to Actions */
@media (max-width: 767.98px) {
  .call_to_action_1 .description {
    margin: 10px 0 30px 0;
  }
  .call_to_action_1 .btn {
    margin-bottom: 15px;
  }
}

.call_to_action_2 .inner {
  border: 2px solid #cccccc;
}
@media (max-width: 991.98px) {
  .call_to_action_2 .inner {
    padding: 40px 30px;
  }
}
@media (max-width: 575.98px) {
  .call_to_action_2 .inner {
    padding: 30px 20px;
  }
}

/* 10. Teams */
.team_1 .block {
  border: 2px solid #cccccc;
}
.team_1 .block_socials {
  border-top: 2px solid #cccccc;
}
.team_1 .block_socials .border_right {
  border-right: 2px solid #cccccc;
}

.team_2 .bordered {
  border-left: 2px solid #cccccc;
  border-right: 2px solid #cccccc;
}
@media (max-width: 767.98px) {
  .team_2 .bordered {
    border: none;
    border-top: 2px solid #cccccc;
    border-bottom: 2px solid #cccccc;
  }
}

/* 11. Pricing Tables */
.pricing_table_1 .block {
  border: 2px solid #cccccc;
  border-bottom-left-radius: 10px;
}
.pricing_table_1 .block.noborder_right {
  border-right: none;
}
.pricing_table_1 .item {
  padding: 16px 0 14px 0;
  border-top: 2px solid #cccccc;
}
.pricing_table_1 .price span.absolute {
  top: 5px;
  left: -15px;
}
@media (max-width: 991.98px) {
  .pricing_table_1[class^=pb-], .pricing_table_1[class*=" pb-"] {
    padding-bottom: 30px;
  }
  .pricing_table_1 .block {
    margin: 0 auto 50px auto;
    max-width: 300px;
    border-radius: 10px;
  }
  .pricing_table_1 .block.noborder_right {
    border-right: 2px solid #cccccc;
  }
}

/* 12. Contacts */
.contact_1 textarea.input {
  height: 170px;
}
.contact_1 .separate {
  position: relative;
}
.contact_1 .separate:before, .contact_1 .separate:after {
  content: " ";
  display: block;
  position: absolute;
  width: calc(100% / 2 - 60px);
  left: 0;
  top: 50%;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}
.contact_1 .separate:after {
  left: auto;
  right: 0;
}

.contact_2 textarea.input {
  height: 170px;
}

/* 13. Showcases */
.showcase_1 .block a:hover {
  opacity: 0.8;
}
@media (max-width: 991.98px) {
  .showcase_1 .description {
    margin: 10px 0 0 0;
  }
}

.showcase_2 a:hover {
  opacity: 0.8;
}

/* 14. Footers */
@media (max-width: 575.98px) {
  .footer_1 .links a {
    margin: 0 10px;
  }
}

/* 15. Ecommerce */
.ecommerce_1 .product .img_link img {
  transition: all 0.25s;
}
.ecommerce_1 .product .img_link:hover img {
  opacity: 0.8;
}
.ecommerce_1 .product .btn {
  left: 0;
  bottom: 0;
}
@media (max-width: 1199.98px) {
  .ecommerce_1 .main_info .btn {
    margin-top: 30px;
  }
}

.ecommerce_2 .product {
  border: 2px solid #cccccc;
}
.ecommerce_2 .product.big {
  min-height: calc(100% - 30px);
}
.ecommerce_2 .product.big ul {
  column-count: 2;
}
.ecommerce_2 .product li {
  list-style: none;
  position: relative;
}
.ecommerce_2 .product li:before {
  content: "";
  display: block;
  position: absolute;
  top: 11px;
  left: -10px;
  width: 4px;
  height: 4px;
  border-radius: 100%;
  background: #AF1212;
}
@media (max-width: 575.98px) {
  .ecommerce_2 .product {
    max-width: 290px;
    padding: 20px 20px 30px 20px;
  }
  .ecommerce_2 .product.big ul {
    column-count: 1;
  }
  .ecommerce_2 .product .left {
    margin-right: 0;
  }
  .ecommerce_2 .product img {
    width: 100%;
    max-width: 100%;
  }
}

/* 16. Applications */
@media (max-width: 767.98px) {
  .application_2 .application_2_left_img {
    max-height: 50vh;
  }
}
.application_2 .with_borders:nth-of-type(1) {
  border-bottom: 2px solid #EBEAED;
  border-right: 2px solid #EBEAED;
}
.application_2 .with_borders:nth-of-type(2) {
  border-bottom: 2px solid #EBEAED;
  border-right: 2px solid #EBEAED;
}
.application_2 .with_borders:nth-of-type(3) {
  border-bottom: 2px solid #EBEAED;
}
.application_2 .with_borders:nth-of-type(4) {
  border-right: 2px solid #EBEAED;
}
.application_2 .with_borders:nth-of-type(5) {
  border-right: 2px solid #EBEAED;
}
@media (max-width: 991.98px) {
  .application_2 .with_borders:nth-of-type(2) {
    border-right: none;
  }
  .application_2 .with_borders:nth-of-type(3) {
    border-right: 2px solid #EBEAED;
    padding-top: 35px;
  }
  .application_2 .with_borders:nth-of-type(4) {
    border-right: none;
    border-bottom: 2px solid #EBEAED;
  }
}
@charset "UTF-8";
/* Change bootstrap's .container default width */
@media (min-width: 768px) {
  .container {
    max-width: 750px;
  }
}
@media (min-width: 992px) {
  .container {
    max-width: 970px;
  }
}
@media (min-width: 1200px) {
  .container {
    max-width: 1170px;
  }
}
/* Font styles */
.underline {
  text-decoration: underline;
}

.strike {
  text-decoration: line-through;
}

.thin {
  font-weight: 100;
}

.extralight {
  font-weight: 200;
}

.light {
  font-weight: 300;
}

.regular {
  font-weight: 400;
}

.medium {
  font-weight: 500;
}

.semibold {
  font-weight: 600;
}

.bold {
  font-weight: 700;
}

.extrabold {
  font-weight: 800;
}

.heavy {
  font-weight: 900;
}

.sp-10 {
  letter-spacing: 1px;
}
.sp-15 {
  letter-spacing: 1.5px;
}
.sp-20 {
  letter-spacing: 2px;
}
.sp-25 {
  letter-spacing: 2.5px;
}

/* Opacity */
.op-0, .aos-init.aos-animate.op-0 {
  opacity: 0;
}

.op-1, .aos-init.aos-animate.op-1 {
  opacity: 0.1;
}

.op-2, .aos-init.aos-animate.op-2 {
  opacity: 0.2;
}

.op-3, .aos-init.aos-animate.op-3 {
  opacity: 0.3;
}

.op-4, .aos-init.aos-animate.op-4 {
  opacity: 0.4;
}

.op-5, .aos-init.aos-animate.op-5 {
  opacity: 0.5;
}

.op-6, .aos-init.aos-animate.op-6 {
  opacity: 0.6;
}

.op-7, .aos-init.aos-animate.op-7 {
  opacity: 0.7;
}

.op-8, .aos-init.aos-animate.op-8 {
  opacity: 0.8;
}

.op-9, .aos-init.aos-animate.op-9 {
  opacity: 0.9;
}

.op-10, .aos-init.aos-animate.op-10 {
  opacity: 1;
}

/* z-index */
.zi--1 {
  z-index: -1;
}
.zi-0 {
  z-index: 0;
}
.zi-1 {
  z-index: 1;
}
.zi-2 {
  z-index: 2;
}
.zi-3 {
  z-index: 3;
}
.zi-4 {
  z-index: 4;
}
.zi-5 {
  z-index: 5;
}

/* Links */
.link {
  display: inline-block;
}
.link.underline:hover, .link.underline:focus {
  text-decoration: underline;
}
.link.img_link:hover img {
  opacity: 0.8;
}
.link.img_link img {
  transition: opacity 0.25s;
}

/* Buttons */
.btn {
  vertical-align: top;
  line-height: 50px;
  padding: 0px 30px;
  border: none;
  border-radius: 4px;
  color: #cccccc;
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  background: transparent;
  transition: all 0.25s;
  /* sizes */
  /* Colors */
  /* Borders */
}
.btn.active, .btn:active {
  box-shadow: none;
}
.btn:active, .btn:focus {
  box-shadow: none;
}
.btn[class^=border-], .btn[class*=" border-"] {
  line-height: 46px;
}
.btn.sm {
  height: 40px;
  line-height: 40px;
  padding: 0 15px;
}
.btn.sm[class^=border-], .btn.sm[class*=" border-"] {
  line-height: 36px;
}
.btn.md {
  /* regular size */
}
.btn.lg {
  height: 60px;
  line-height: 60px;
  padding: 0 45px;
  font-size: 30px;
  font-weight: 600;
}
.btn.lg[class^=border-], .btn.lg[class*=" border-"] {
  line-height: 56px;
}
.btn.xl {
  height: 70px;
  line-height: 70px;
  padding: 0 45px;
  font-size: 30px;
  font-weight: 600;
  box-shadow: 0px 4px 4px rgba(0,0,0,0.25);
}
.btn.xl[class^=border-], .btn.xl[class*=" border-"] {
  line-height: 66px;
}
.btn.action-1 {
  background-color: #f65868;
  color: #ffffff;
  border-radius:9px;
  position: relative;
  display:inline-table;
}
.btn.action-1:hover {
  background-color: #cccccc;
}
.btn.action-2 {
  background-color: #000d79;
  color: #cccccc;
  border-radius:9px;
}
.btn.action-2:hover {
  background-color: #cccccc;
}
.btn.action-3 {
  background-color: #000d79;
  color: #cccccc;
}
.btn.action-3:hover {
  background-color: #cccccc;
}
.btn.action-white {
  background-color: #cccccc;
  color: #666666;
}
.btn.action-white:hover {
  background-color: #e6e6e6;
}
.btn.action-main {
  background-color: #666666;
  color: #cccccc;
}
.btn.action-main:hover {
  background-color: #1a1a1a;
}
.btn.action-heading {
  background-color: #000d79;
  color: #cccccc;
}
.btn.action-heading:hover {
  background-color: #dd1717;
}
.btn.action-gray {
  background-color: #EDEDED;
  color: #666666;
}
.btn.action-gray:hover {
  background-color: #d4d4d4;
}
.btn.action-bg-light {
  background-color: #c7dcff;
  color: #000d79;
}
.btn.action-bg-light:hover {
  background-color: #e6e6e6;
}
.btn.action-bg-dark {
  background-color: #000d79;
  color: #cccccc;
}
.btn.action-bg-dark:hover {
  background-color: #dd1717;
}
.btn.action-red {
  background-color: #EA3223;
  color: #cccccc;
}
.btn.action-red:hover {
  background-color: #c72113;
}
.btn.action-facebook {
  background-color: #2e4da7;
  color: #cccccc;
}
.btn.action-facebook:hover {
  background-color: #3e62ca;
}
.btn.action-twitter {
  background-color: #0aa0f5;
  color: #cccccc;
}
.btn.action-twitter:hover {
  background-color: #0880c4;
}
.btn.action-dribbble {
  background-color: #E93A7D;
  color: #cccccc;
}
.btn.action-dribbble:hover {
  background-color: #d81862;
}
.btn.border-main {
  border: 2px solid #666666;
}
.btn.border-main:hover {
  border-color: #333333;
}
.btn.border-transparent-main {
  color: #666666;
  border: 2px solid rgba(0, 0, 0, 0.3);
}
.btn.border-transparent-main:hover {
  border-color: rgba(0, 0, 0, 0.7);
}
.btn.border-heading {
  border: 2px solid #000d79;
}
.btn.border-heading:hover {
  border-color: #eb3c3c;
}
.btn.border-white {
  border: 2px solid #cccccc;
}
.btn.border-white:hover {
  border-color: #cccccc;
  color: #cccccc;
}
.btn.border-transparent-white {
  border: 2px solid rgba(255, 255, 255, 0.3);
}
.btn.border-transparent-white:hover {
  border-color: rgba(255, 255, 255, 0.7);
  color: #cccccc;
}
.btn.border-gray {
  border: 2px solid #EDEDED;
}
.btn.border-gray:hover {
  border-color: #bababa;
}
.btn.border-light {
  border: 2px solid #cccccc;
}
.btn.border-light:hover {
  border-color: #cccccc;
}
.btn.border-dark {
  border: 2px solid #000d79;
}
.btn.border-dark:hover {
  border-color: #eb3c3c;
}
.btn.border-action-1 {
  border: 2px solid #666666;
  color: #666666;
}
.btn.border-action-1:hover {
  border-color: black;
  color: black;
}
.btn.border-action-2 {
  border: 2px solid #000d79;
  color: #000d79;
}
.btn.border-action-2:hover {
  border-color: #041e5f;
  color: #041e5f;
}
.btn.border-action-3 {
  border: 2px solid #000d79;
  color: #000d79;
}
.btn.border-action-3:hover {
  border-color: #9a8208;
  color: #9a8208;
}

/* inputs */
.input {
  box-sizing: border-box;
  display: inline-block;
  vertical-align: top;
  padding: 0 25px;
  height: 50px;
  font-size: 18px;
  font-weight: 500;
  border-radius: 35px;
  transition: border-color 0.3s;
  /* Sizes */
  /* Borders */
  /* styles on focus */
  /* placeholders */
}
.input:focus::-moz-placeholder {
  color: transparent !important;
}
.input:focus::-webkit-input-placeholder {
  color: transparent !important;
}
.input:focus:-ms-input-placeholder {
  color: transparent !important;
}
.input.sm {
  font-size: 16px;
  height: 40px;
  padding-left: 20px;
  padding-right: 20px;
  font-weight: 400;
}
.input.lg {
  font-size: 20px;
  height: 60px;
  padding-left: 25px;
  padding-right: 25px;
  font-weight: 600;
}
.input.xl {
  font-size: 20px;
  height: 70px;
  padding-left: 30px;
  padding-right: 30px;
  font-weight: 600;
}
.input.border-main {
  border: 2px solid #666666;
}
.input.border-heading {
  border: 2px solid #000d79;
}
.input.border-white {
  border: 2px solid #cccccc;
}
.input.border-transparent-white {
  border: 2px solid rgba(255, 255, 255, 0.4);
}
.input.border-gray {
  border: 2px solid #EDEDED;
}
.input.border-light {
  border: 2px solid #cccccc;
}
.input.border-dark {
  border: 2px solid #000d79;
}
.input.border-action-1 {
  border: 2px solid #666666;
}
.input.border-action-2 {
  border: 2px solid #000d79;
}
.input.border-action-3 {
  border: 2px solid #000d79;
}
.input.focus-main:focus {
  border-color: #666666;
}
.input.focus-heading:focus {
  border-color: #000d79;
}
.input.focus-white:focus {
  border-color: #cccccc;
}
.input.focus-gray:focus {
  border-color: #EDEDED;
}
.input.focus-light:focus {
  border-color: #cccccc;
}
.input.focus-dark:focus {
  border-color: #000d79;
}
.input.focus-action-1:focus {
  border-color: #666666;
}
.input.focus-action-2:focus {
  border-color: #000d79;
}
.input.focus-action-3:focus {
  border-color: #000d79;
}
.input.placeholder-white::-moz-placeholder {
  color: #cccccc;
}
.input.placeholder-white::-webkit-input-placeholder {
  color: #cccccc;
}
.input.placeholder-white:-ms-input-placeholder {
  color: #cccccc;
}
.input.placeholder-transparent-white::-moz-placeholder {
  color: rgba(255, 255, 255, 0.4);
}
.input.placeholder-transparent-white::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.4);
}
.input.placeholder-transparent-white:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.4);
}
.input.placeholder-main::-moz-placeholder {
  color: #666666;
}
.input.placeholder-main::-webkit-input-placeholder {
  color: #666666;
}
.input.placeholder-main:-ms-input-placeholder {
  color: #666666;
}
.input.placeholder-heading::-moz-placeholder {
  color: #000d79;
}
.input.placeholder-heading::-webkit-input-placeholder {
  color: #000d79;
}
.input.placeholder-heading:-ms-input-placeholder {
  color: #000d79;
}
.input.placeholder-gray::-moz-placeholder {
  color: #EDEDED;
}
.input.placeholder-gray::-webkit-input-placeholder {
  color: #EDEDED;
}
.input.placeholder-gray:-ms-input-placeholder {
  color: #EDEDED;
}
.input.placeholder-action-1::-moz-placeholder {
  color: #666666;
}
.input.placeholder-action-1::-webkit-input-placeholder {
  color: #666666;
}
.input.placeholder-action-1:-ms-input-placeholder {
  color: #666666;
}
.input.placeholder-action-2::-moz-placeholder {
  color: #000d79;
}
.input.placeholder-action-2::-webkit-input-placeholder {
  color: #000d79;
}
.input.placeholder-action-2:-ms-input-placeholder {
  color: #000d79;
}
.input.placeholder-action-3::-moz-placeholder {
  color: #000d79;
}
.input.placeholder-action-3::-webkit-input-placeholder {
  color: #000d79;
}
.input.placeholder-action-3:-ms-input-placeholder {
  color: #000d79;
}

/* Selects */
select {
  appearance: none;
  text-indent: 0.01px;
  text-overflow: "";
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: 90% 50%;
  background-position: calc(100% - 20px) 50%;
  background-size: 11px 6px;
  border: 0;
  outline: none;
  color: #666666;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  text-indent: 1px;
  text-overflow: "";
}
select::-ms-expand {
  display: none;
}
select.color-white {
  color: #cccccc;
}
select.color-white option {
  color: #666666;
  background: #fff;
}

/* Textareas */
textarea.input {
  padding-top: 15px;
  padding-bottom: 15px;
}

/* Checkboxes, Radio buttons, Toggles */
.input-control {
  display: inline-flex;
  align-items: flex-start;
  /* styles when checked */
  /* Borders */
  /* styles when checked */
}
.input-control.input-check.checked-main input:checked ~ .input-control-box, .input-control.input-toggle.checked-main input:checked ~ .input-control-box {
  background-color: #666666;
}
.input-control.input-check.checked-heading input:checked ~ .input-control-box, .input-control.input-toggle.checked-heading input:checked ~ .input-control-box {
  background-color: #000d79;
}
.input-control.input-check.checked-white input:checked ~ .input-control-box, .input-control.input-toggle.checked-white input:checked ~ .input-control-box {
  background-color: #cccccc;
}
.input-control.input-check.checked-gray input:checked ~ .input-control-box, .input-control.input-toggle.checked-gray input:checked ~ .input-control-box {
  background-color: #EDEDED;
}
.input-control.input-check.checked-light input:checked ~ .input-control-box, .input-control.input-toggle.checked-light input:checked ~ .input-control-box {
  background-color: #cccccc;
}
.input-control.input-check.checked-dark input:checked ~ .input-control-box, .input-control.input-toggle.checked-dark input:checked ~ .input-control-box {
  background-color: #000d79;
}
.input-control.input-check.checked-action-1 input:checked ~ .input-control-box, .input-control.input-toggle.checked-action-1 input:checked ~ .input-control-box {
  background-color: #666666;
}
.input-control.input-check.checked-action-2 input:checked ~ .input-control-box, .input-control.input-toggle.checked-action-2 input:checked ~ .input-control-box {
  background-color: #000d79;
}
.input-control.input-check.checked-action-3 input:checked ~ .input-control-box, .input-control.input-toggle.checked-action-3 input:checked ~ .input-control-box {
  background-color: #000d79;
}
.input-control.input-radio input:checked ~ .input-control-box .input-control-icon {
  transform: translate(-50%, -50%) scale(1);
}
.input-control.input-radio .input-control-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0);
  border-radius: 100%;
}
.input-control.input-toggle input:checked ~ .input-control-box .input-control-icon {
  left: 100%;
  transform: translate(calc(-100% - 3px), -50%);
}
.input-control.input-toggle .input-control-icon {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 50%;
  left: 0%;
  transform: translate(3px, -50%);
  border-radius: 100%;
  visibility: visible;
  opacity: 1;
}
.input-control.border-main .input-control-box {
  border: 2px solid #666666;
}
.input-control.border-heading .input-control-box {
  border: 2px solid #000d79;
}
.input-control.border-white .input-control-box {
  border: 2px solid #cccccc;
}
.input-control.border-transparent-white .input-control-box {
  border: 2px solid rgba(255, 255, 255, 0.4);
}
.input-control.border-gray .input-control-box {
  border: 2px solid #EDEDED;
}
.input-control.border-light .input-control-box {
  border: 2px solid #cccccc;
}
.input-control.border-dark .input-control-box {
  border: 2px solid #000d79;
}
.input-control.border-action-1 .input-control-box {
  border: 2px solid #666666;
}
.input-control.border-action-2 .input-control-box {
  border: 2px solid #000d79;
}
.input-control.border-action-3 .input-control-box {
  border: 2px solid #000d79;
}
.input-control.checked-main input:checked ~ .input-control-box {
  border-color: #666666;
}
.input-control.checked-heading input:checked ~ .input-control-box {
  border-color: #000d79;
}
.input-control.checked-white input:checked ~ .input-control-box {
  border-color: #cccccc;
}
.input-control.checked-gray input:checked ~ .input-control-box {
  border-color: #EDEDED;
}
.input-control.checked-light input:checked ~ .input-control-box {
  border-color: #cccccc;
}
.input-control.checked-dark input:checked ~ .input-control-box {
  border-color: #000d79;
  background-color: #000d79;
}
.input-control.checked-action-1 input:checked ~ .input-control-box {
  border-color: #666666;
}
.input-control.checked-action-2 input:checked ~ .input-control-box {
  border-color: #000d79;
}
.input-control.checked-action-3 input:checked ~ .input-control-box {
  border-color: #000d79;
}
.input-control input {
  display: none;
}
.input-control input:checked ~ .input-control-box .input-control-icon {
  visibility: visible;
  opacity: 1;
}
.input-control-box {
  position: relative;
  width: 24px;
  height: 24px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  transition: all 0.2s;
}
.input-control-icon {
  visibility: hidden;
  opacity: 0;
  transition: all 0.2s;
}

/* Navigation Mobile */
.navigation_mobile {
  display: none;
  position: fixed;
  top: 0px;
  left: -40px;
  height: 100%;
  width: 0px;
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 1000;
  transition: width 0.2s;
}
.navigation_mobile.opened {
  left: 0px;
  width: 320px;
}
.navigation_mobile.opened .close_menu {
  display: block;
}

.open_menu, .close_menu {
  display: none;
  top: 20px;
  width: 40px;
  height: 40px;
  color: #666666;
  font-size: 20px;
  line-height: 40px;
  text-align: center;
  z-index: 990;
}
.open_menu.color-white, .close_menu.color-white {
  color: #cccccc !important;
}
.open_menu.right, .close_menu.right {
  left: auto;
  right: 20px;
}

.open_menu {
  position: fixed;
  left: 20px;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.04);
}

.close_menu {
  right: 20px;
  position: absolute;
}

/* Video block */
.video {
  position: relative;
  overflow: hidden;
}
.video .poster {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: all 0.2s;
  cursor: pointer;
  z-index: 500;
}
.video .poster img {
  width: 100%;
  max-width: 100%;
}
.video video {
  width: 100%;
  height: 100%;
  display: none;
}

/* Play button */
.play {
  width: 70px;
  height: 70px;
  line-height: 70px;
  font-size: 16px;
  border-radius: 50%;
  text-align: center;
  transition: all 0.2s;
  z-index: 501;
}
.play:hover {
  transform: scale(1.08);
}
.play:not(.relative) {
  margin: -35px 0 0 -35px;
  position: absolute;
  top: 50%;
  left: 50%;
}
.play.relative {
  display: inline-block;
}
.play.sm {
  width: 60px;
  height: 60px;
  line-height: 60px;
}
.play.sm:not(.relative) {
  margin: -30px 0 0 -30px;
}
.play.red {
  background: #EA3223;
  color: #cccccc;
}
.play.white {
  background: #ffffff;
  color: #666666;
}
.play.action-1 {
  background: #666666;
  color: #cccccc;
}
.play.action-2 {
  background: #000d79;
  color: #cccccc;
}
.play.action-3 {
  background: #000d79;
  color: #cccccc;
}
.play .fa, .play .fas {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* Opening tabs */
.opening_tabs .tab_opener {
  cursor: pointer;
}
.opening_tabs .tab_text {
  overflow: hidden;
  height: 0;
}

/* Alerts */
.alert {
  display: none;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 970px;
  color: #cccccc;
  z-index: 2000;
}
.alert-dismissable .close, .alert-dismissible .close {
  top: 0;
}
.alert.fixed-top {
  margin-top: 1rem;
}
.alert-primary {
  background: #666666;
  border-color: #666666;
}
.alert-secondary {
  background: #000d79;
  border-color: #000d79;
}
.alert-success {
  background: #666666;
  border-color: #666666;
}
.alert-danger {
  background: #000d79;
  border-color: #000d79;
}
.alert-warning, .alert-info {
  background: #000d79;
  border-color: #000d79;
}
.alert-light {
  background: #cccccc;
  border-color: #cccccc;
  color: #666666;
}
.alert-light.alert .close:hover {
  color: #666666;
}
.alert-dark {
  background: #000d79;
  border-color: #000d79;
}
.alert .close {
  text-shadow: none;
}
.alert .close:hover {
  color: #cccccc;
}

/* Google ReCaptcha */
.grecaptcha-overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  cursor: pointer;
}

.grecaptcha-popup {
  display: none;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
}

/* Dropdown menu */
.dropdown.show .dropdown-toggle:after {
  transform: scaleY(-1);
}
.dropdown a {
  white-space: nowrap;
}
a:link { text-decoration: none; }


a:visited { text-decoration: none; }


a:hover { text-decoration: none; }


a:active { text-decoration: none; }
.footer-social-icons .icon.ni {
  font-size: 26px;
}

.dropdown-toggle:after {
  content: "";
  height: 100%;
  vertical-align: top;
  border: none;
  font-size: 18px;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  transition: transform 0.2s;
}

.dropdown-menu {
  margin-top: 0;
  margin-left: -20px;
  padding: 0px 20px;
  border: none;
  border-radius: 0px;
}
.dropdown-menu.dropdown-menu-right {
  margin-left: auto;
  margin-right: -20px;
}

/* Dropdown popup in Navigation */
.navigation_popup {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  border-top: 2px solid #EDEDED;
  opacity: 0;
  visibility: hidden;
  overflow: hidden;
  transform-origin: 50% 0;
  transform: scaleY(0);
  transition: opacity 0.25s, transform 0.25s, visibility 0.25s;
}
.navigation_popup.opened {
  opacity: 1;
  visibility: visible;
  transform: scaleY(1);
}

/* Slider stylers */
.slider.arrows0 .slick-prev {
  left: 0px;
}
.slider.arrows0 .slick-next {
  right: 0px;
}
.slider.arrows10 .slick-prev {
  left: 10px;
}
.slider.arrows10 .slick-next {
  right: 10px;
}
.slider.arrows20 .slick-prev {
  left: 20px;
}
.slider.arrows20 .slick-next {
  right: 20px;
}
.slider.arrows30 .slick-prev {
  left: 30px;
}
.slider.arrows30 .slick-next {
  right: 30px;
}
.slider.arrows40 .slick-prev {
  left: 40px;
}
.slider.arrows40 .slick-next {
  right: 40px;
}
.slider.arrows50 .slick-prev {
  left: 50px;
}
.slider.arrows50 .slick-next {
  right: 50px;
}
.slider.arrows60 .slick-prev {
  left: 60px;
}
.slider.arrows60 .slick-next {
  right: 60px;
}
.slider.arrows70 .slick-prev {
  left: 70px;
}
.slider.arrows70 .slick-next {
  right: 70px;
}
.slider.arrows80 .slick-prev {
  left: 80px;
}
.slider.arrows80 .slick-next {
  right: 80px;
}
.slider.arrows90 .slick-prev {
  left: 90px;
}
.slider.arrows90 .slick-next {
  right: 90px;
}
.slider.arrows100 .slick-prev {
  left: 100px;
}
.slider.arrows100 .slick-next {
  right: 100px;
}
.slider.dots_color_main .slick-dots li button {
  border-color: #666666;
}
.slider.dots_color_main .slick-dots li.slick-active button {
  background: #666666;
}
.slider.dots-100 .slick-dots {
  bottom: -100px;
}
.slider.dots-90 .slick-dots {
  bottom: -90px;
}
.slider.dots-80 .slick-dots {
  bottom: -80px;
}
.slider.dots-70 .slick-dots {
  bottom: -70px;
}
.slider.dots-60 .slick-dots {
  bottom: -60px;
}
.slider.dots-50 .slick-dots {
  bottom: -50px;
}
.slider.dots-40 .slick-dots {
  bottom: -40px;
}
.slider.dots-30 .slick-dots {
  bottom: -30px;
}
.slider.dots-20 .slick-dots {
  bottom: -20px;
}
.slider.dots-10 .slick-dots {
  bottom: -10px;
}
.slider.dots0 .slick-dots {
  bottom: 0px;
}
.slider.dots10 .slick-dots {
  bottom: 10px;
}
.slider.dots20 .slick-dots {
  bottom: 20px;
}
.slider.dots30 .slick-dots {
  bottom: 30px;
}
.slider.dots40 .slick-dots {
  bottom: 40px;
}
.slider.dots50 .slick-dots {
  bottom: 50px;
}
.slider.dots60 .slick-dots {
  bottom: 60px;
}
.slider.dots70 .slick-dots {
  bottom: 70px;
}
.slider.dots80 .slick-dots {
  bottom: 80px;
}
.slider.dots90 .slick-dots {
  bottom: 90px;
}
.slider.dots100 .slick-dots {
  bottom: 100px;
}

/* Change some Bootstrap's styles */
.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(237, 237, 237, 0.5);
}

/* Classes for all elements */
/* Margins and paddings */
.mt-0 {
  margin-top: 0px;
}

.mb-0 {
  margin-bottom: 0px;
}

.mr-0 {
  margin-right: 0px;
}

.ml-0 {
  margin-left: 0px;
}

.mx-0 {
  margin-left: 0px;
  margin-right: 0px;
}

.pt-0 {
  padding-top: 0px;
}

.pb-0 {
  padding-bottom: 0px;
}

.pr-0 {
  padding-right: 0px;
}

.pl-0 {
  padding-left: 0px;
}

.px-0 {
  padding-left: 0px;
  padding-right: 0px;
}

.mt-5 {
  margin-top: 5px;
}

.mb-5 {
  margin-bottom: 5px;
}

.mr-5 {
  margin-right: 5px;
}

.ml-5 {
  margin-left: 5px;
}

.mx-5 {
  margin-left: 5px;
  margin-right: 5px;
}

.pt-5 {
  padding-top: 5px;
}

.pb-5 {
  padding-bottom: 5px;
}

.pr-5 {
  padding-right: 5px;
}

.pl-5 {
  padding-left: 5px;
}

.px-5 {
  padding-left: 5px;
  padding-right: 5px;
}

.mt-10 {
  margin-top: 10px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mr-10 {
  margin-right: 10px;
}

.ml-10 {
  margin-left: 10px;
}

.mx-10 {
  margin-left: 10px;
  margin-right: 10px;
}

.pt-10 {
  padding-top: 10px;
}

.pb-10 {
  padding-bottom: 10px;
}

.pr-10 {
  padding-right: 10px;
}

.pl-10 {
  padding-left: 10px;
}

.px-10 {
  padding-left: 10px;
  padding-right: 10px;
}

.mt-15 {
  margin-top: 15px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mr-15 {
  margin-right: 15px;
}

.ml-15 {
  margin-left: 15px;
}

.mx-15 {
  margin-left: 15px;
  margin-right: 15px;
}

.pt-15 {
  padding-top: 15px;
}

.pb-15 {
  padding-bottom: 15px;
}

.pr-15 {
  padding-right: 15px;
}

.pl-15 {
  padding-left: 15px;
}

.px-15 {
  padding-left: 15px;
  padding-right: 15px;
}

.mt-20 {
  margin-top: 20px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mr-20 {
  margin-right: 20px;
}

.ml-20 {
  margin-left: 20px;
}

.mx-20 {
  margin-left: 20px;
  margin-right: 20px;
}

.pt-20 {
  padding-top: 20px;
}

.pb-20 {
  padding-bottom: 20px;
}

.pr-20 {
  padding-right: 20px;
}

.pl-20 {
  padding-left: 20px;
}

.px-20 {
  padding-left: 20px;
  padding-right: 20px;
}

.mt-25 {
  margin-top: 25px;
}

.mb-25 {
  margin-bottom: 25px;
}

.mr-25 {
  margin-right: 25px;
}

.ml-25 {
  margin-left: 25px;
}

.mx-25 {
  margin-left: 25px;
  margin-right: 25px;
}

.pt-25 {
  padding-top: 25px;
}

.pb-25 {
  padding-bottom: 25px;
}

.pr-25 {
  padding-right: 25px;
}

.pl-25 {
  padding-left: 25px;
}

.px-25 {
  padding-left: 25px;
  padding-right: 25px;
}

.mt-30 {
  margin-top: 30px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mr-30 {
  margin-right: 30px;
}

.ml-30 {
  margin-left: 30px;
}

.mx-30 {
  margin-left: 30px;
  margin-right: 30px;
}

.pt-30 {
  padding-top: 30px;
}

.pb-30 {
  padding-bottom: 30px;
}

.pr-30 {
  padding-right: 30px;
}

.pl-30 {
  padding-left: 30px;
}

.px-30 {
  padding-left: 30px;
  padding-right: 30px;
}

.mt-35 {
  margin-top: 35px;
}

.mb-35 {
  margin-bottom: 35px;
}

.mr-35 {
  margin-right: 35px;
}

.ml-35 {
  margin-left: 35px;
}

.mx-35 {
  margin-left: 35px;
  margin-right: 35px;
}

.pt-35 {
  padding-top: 35px;
}

.pb-35 {
  padding-bottom: 35px;
}

.pr-35 {
  padding-right: 35px;
}

.pl-35 {
  padding-left: 35px;
}

.px-35 {
  padding-left: 35px;
  padding-right: 35px;
}

.mt-40 {
  margin-top: 40px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mr-40 {
  margin-right: 40px;
}

.ml-40 {
  margin-left: 40px;
}

.mx-40 {
  margin-left: 40px;
  margin-right: 40px;
}

.pt-40 {
  padding-top: 40px;
}

.pb-40 {
  padding-bottom: 40px;
}

.pr-40 {
  padding-right: 40px;
}

.pl-40 {
  padding-left: 40px;
}

.px-40 {
  padding-left: 40px;
  padding-right: 40px;
}

.mt-45 {
  margin-top: 45px;
}

.mb-45 {
  margin-bottom: 45px;
}

.mr-45 {
  margin-right: 45px;
}

.ml-45 {
  margin-left: 45px;
}

.mx-45 {
  margin-left: 45px;
  margin-right: 45px;
}

.pt-45 {
  padding-top: 45px;
}

.pb-45 {
  padding-bottom: 45px;
}

.pr-45 {
  padding-right: 45px;
}

.pl-45 {
  padding-left: 45px;
}

.px-45 {
  padding-left: 45px;
  padding-right: 45px;
}

.mt-50 {
  margin-top: 50px;
}

.mb-50 {
  margin-bottom: 50px;
}

.mr-50 {
  margin-right: 50px;
}

.ml-50 {
  margin-left: 50px;
}

.mx-50 {
  margin-left: 50px;
  margin-right: 50px;
}

.pt-50 {
  padding-top: 50px;
}

.pb-50 {
  padding-bottom: 50px;
}

.pr-50 {
  padding-right: 50px;
}

.pl-50 {
  padding-left: 50px;
}

.px-50 {
  padding-left: 50px;
  padding-right: 50px;
}

.mt-55 {
  margin-top: 55px;
}

.mb-55 {
  margin-bottom: 55px;
}

.mr-55 {
  margin-right: 55px;
}

.ml-55 {
  margin-left: 55px;
}

.mx-55 {
  margin-left: 55px;
  margin-right: 55px;
}

.pt-55 {
  padding-top: 55px;
}

.pb-55 {
  padding-bottom: 55px;
}

.pr-55 {
  padding-right: 55px;
}

.pl-55 {
  padding-left: 55px;
}

.px-55 {
  padding-left: 55px;
  padding-right: 55px;
}

.mt-60 {
  margin-top: 60px;
}

.mb-60 {
  margin-bottom: 60px;
}

.mr-60 {
  margin-right: 60px;
}

.ml-60 {
  margin-left: 60px;
}

.mx-60 {
  margin-left: 60px;
  margin-right: 60px;
}

.pt-60 {
  padding-top: 60px;
}

.pb-60 {
  padding-bottom: 60px;
}

.pr-60 {
  padding-right: 60px;
}

.pl-60 {
  padding-left: 60px;
}

.px-60 {
  padding-left: 60px;
  padding-right: 60px;
}

.mt-65 {
  margin-top: 65px;
}

.mb-65 {
  margin-bottom: 65px;
}

.mr-65 {
  margin-right: 65px;
}

.ml-65 {
  margin-left: 65px;
}

.mx-65 {
  margin-left: 65px;
  margin-right: 65px;
}

.pt-65 {
  padding-top: 65px;
}

.pb-65 {
  padding-bottom: 65px;
}

.pr-65 {
  padding-right: 65px;
}

.pl-65 {
  padding-left: 65px;
}

.px-65 {
  padding-left: 65px;
  padding-right: 65px;
}

.mt-70 {
  margin-top: 70px;
}

.mb-70 {
  margin-bottom: 70px;
}

.mr-70 {
  margin-right: 70px;
}

.ml-70 {
  margin-left: 70px;
}

.mx-70 {
  margin-left: 70px;
  margin-right: 70px;
}

.pt-70 {
  padding-top: 70px;
}

.pb-70 {
  padding-bottom: 70px;
}

.pr-70 {
  padding-right: 70px;
}

.pl-70 {
  padding-left: 70px;
}

.px-70 {
  padding-left: 70px;
  padding-right: 70px;
}

.mt-75 {
  margin-top: 75px;
}

.mb-75 {
  margin-bottom: 75px;
}

.mr-75 {
  margin-right: 75px;
}

.ml-75 {
  margin-left: 75px;
}

.mx-75 {
  margin-left: 75px;
  margin-right: 75px;
}

.pt-75 {
  padding-top: 75px;
}

.pb-75 {
  padding-bottom: 75px;
}

.pr-75 {
  padding-right: 75px;
}

.pl-75 {
  padding-left: 75px;
}

.px-75 {
  padding-left: 75px;
  padding-right: 75px;
}

.mt-80 {
  margin-top: 80px;
}

.mb-80 {
  margin-bottom: 80px;
}

.mr-80 {
  margin-right: 80px;
}

.ml-80 {
  margin-left: 80px;
}

.mx-80 {
  margin-left: 80px;
  margin-right: 80px;
}

.pt-80 {
  padding-top: 80px;
}

.pb-80 {
  padding-bottom: 80px;
}

.pr-80 {
  padding-right: 80px;
}

.pl-80 {
  padding-left: 80px;
}

.px-80 {
  padding-left: 80px;
  padding-right: 80px;
}

.mt-85 {
  margin-top: 85px;
}

.mb-85 {
  margin-bottom: 85px;
}

.mr-85 {
  margin-right: 85px;
}

.ml-85 {
  margin-left: 85px;
}

.mx-85 {
  margin-left: 85px;
  margin-right: 85px;
}

.pt-85 {
  padding-top: 85px;
}

.pb-85 {
  padding-bottom: 85px;
}

.pr-85 {
  padding-right: 85px;
}

.pl-85 {
  padding-left: 85px;
}

.px-85 {
  padding-left: 85px;
  padding-right: 85px;
}

.mt-90 {
  margin-top: 90px;
}

.mb-90 {
  margin-bottom: 90px;
}

.mr-90 {
  margin-right: 90px;
}

.ml-90 {
  margin-left: 90px;
}

.mx-90 {
  margin-left: 90px;
  margin-right: 90px;
}

.pt-90 {
  padding-top: 90px;
}

.pb-90 {
  padding-bottom: 90px;
}

.pr-90 {
  padding-right: 90px;
}

.pl-90 {
  padding-left: 90px;
}

.px-90 {
  padding-left: 90px;
  padding-right: 90px;
}

.mt-95 {
  margin-top: 95px;
}

.mb-95 {
  margin-bottom: 95px;
}

.mr-95 {
  margin-right: 95px;
}

.ml-95 {
  margin-left: 95px;
}

.mx-95 {
  margin-left: 95px;
  margin-right: 95px;
}

.pt-95 {
  padding-top: 95px;
}

.pb-95 {
  padding-bottom: 95px;
}

.pr-95 {
  padding-right: 95px;
}

.pl-95 {
  padding-left: 95px;
}

.px-95 {
  padding-left: 95px;
  padding-right: 95px;
}

.mt-100 {
  margin-top: 100px;
}

.mb-100 {
  margin-bottom: 100px;
}

.mr-100 {
  margin-right: 100px;
}

.ml-100 {
  margin-left: 100px;
}

.mx-100 {
  margin-left: 100px;
  margin-right: 100px;
}

.pt-100 {
  padding-top: 100px;
}

.pb-100 {
  padding-bottom: 100px;
}

.pr-100 {
  padding-right: 100px;
}

.pl-100 {
  padding-left: 100px;
}

.px-100 {
  padding-left: 100px;
  padding-right: 100px;
}

.mt-105 {
  margin-top: 105px;
}

.mb-105 {
  margin-bottom: 105px;
}

.mr-105 {
  margin-right: 105px;
}

.ml-105 {
  margin-left: 105px;
}

.mx-105 {
  margin-left: 105px;
  margin-right: 105px;
}

.pt-105 {
  padding-top: 105px;
}

.pb-105 {
  padding-bottom: 105px;
}

.pr-105 {
  padding-right: 105px;
}

.pl-105 {
  padding-left: 105px;
}

.px-105 {
  padding-left: 105px;
  padding-right: 105px;
}

.mt-110 {
  margin-top: 110px;
}

.mb-110 {
  margin-bottom: 110px;
}

.mr-110 {
  margin-right: 110px;
}

.ml-110 {
  margin-left: 110px;
}

.mx-110 {
  margin-left: 110px;
  margin-right: 110px;
}

.pt-110 {
  padding-top: 110px;
}

.pb-110 {
  padding-bottom: 110px;
}

.pr-110 {
  padding-right: 110px;
}

.pl-110 {
  padding-left: 110px;
}

.px-110 {
  padding-left: 110px;
  padding-right: 110px;
}

.mt-115 {
  margin-top: 115px;
}

.mb-115 {
  margin-bottom: 115px;
}

.mr-115 {
  margin-right: 115px;
}

.ml-115 {
  margin-left: 115px;
}

.mx-115 {
  margin-left: 115px;
  margin-right: 115px;
}

.pt-115 {
  padding-top: 115px;
}

.pb-115 {
  padding-bottom: 115px;
}

.pr-115 {
  padding-right: 115px;
}

.pl-115 {
  padding-left: 115px;
}

.px-115 {
  padding-left: 115px;
  padding-right: 115px;
}

.mt-120 {
  margin-top: 120px;
}

.mb-120 {
  margin-bottom: 120px;
}

.mr-120 {
  margin-right: 120px;
}

.ml-120 {
  margin-left: 120px;
}

.mx-120 {
  margin-left: 120px;
  margin-right: 120px;
}

.pt-120 {
  padding-top: 120px;
}

.pb-120 {
  padding-bottom: 120px;
}

.pr-120 {
  padding-right: 120px;
}

.pl-120 {
  padding-left: 120px;
}

.px-120 {
  padding-left: 120px;
  padding-right: 120px;
}

.mt-125 {
  margin-top: 125px;
}

.mb-125 {
  margin-bottom: 125px;
}

.mr-125 {
  margin-right: 125px;
}

.ml-125 {
  margin-left: 125px;
}

.mx-125 {
  margin-left: 125px;
  margin-right: 125px;
}

.pt-125 {
  padding-top: 125px;
}

.pb-125 {
  padding-bottom: 125px;
}

.pr-125 {
  padding-right: 125px;
}

.pl-125 {
  padding-left: 125px;
}

.px-125 {
  padding-left: 125px;
  padding-right: 125px;
}

.mt-130 {
  margin-top: 130px;
}

.mb-130 {
  margin-bottom: 130px;
}

.mr-130 {
  margin-right: 130px;
}

.ml-130 {
  margin-left: 130px;
}

.mx-130 {
  margin-left: 130px;
  margin-right: 130px;
}

.pt-130 {
  padding-top: 130px;
}

.pb-130 {
  padding-bottom: 130px;
}

.pr-130 {
  padding-right: 130px;
}

.pl-130 {
  padding-left: 130px;
}

.px-130 {
  padding-left: 130px;
  padding-right: 130px;
}

.mt-135 {
  margin-top: 135px;
}

.mb-135 {
  margin-bottom: 135px;
}

.mr-135 {
  margin-right: 135px;
}

.ml-135 {
  margin-left: 135px;
}

.mx-135 {
  margin-left: 135px;
  margin-right: 135px;
}

.pt-135 {
  padding-top: 135px;
}

.pb-135 {
  padding-bottom: 135px;
}

.pr-135 {
  padding-right: 135px;
}

.pl-135 {
  padding-left: 135px;
}

.px-135 {
  padding-left: 135px;
  padding-right: 135px;
}

.mt-140 {
  margin-top: 140px;
}

.mb-140 {
  margin-bottom: 140px;
}

.mr-140 {
  margin-right: 140px;
}

.ml-140 {
  margin-left: 140px;
}

.mx-140 {
  margin-left: 140px;
  margin-right: 140px;
}

.pt-140 {
  padding-top: 140px;
}

.pb-140 {
  padding-bottom: 140px;
}

.pr-140 {
  padding-right: 140px;
}

.pl-140 {
  padding-left: 140px;
}

.px-140 {
  padding-left: 140px;
  padding-right: 140px;
}

.mt-145 {
  margin-top: 145px;
}

.mb-145 {
  margin-bottom: 145px;
}

.mr-145 {
  margin-right: 145px;
}

.ml-145 {
  margin-left: 145px;
}

.mx-145 {
  margin-left: 145px;
  margin-right: 145px;
}

.pt-145 {
  padding-top: 145px;
}

.pb-145 {
  padding-bottom: 145px;
}

.pr-145 {
  padding-right: 145px;
}

.pl-145 {
  padding-left: 145px;
}

.px-145 {
  padding-left: 145px;
  padding-right: 145px;
}

.mt-150 {
  margin-top: 150px;
}

.mb-150 {
  margin-bottom: 150px;
}

.mr-150 {
  margin-right: 150px;
}

.ml-150 {
  margin-left: 150px;
}

.mx-150 {
  margin-left: 150px;
  margin-right: 150px;
}

.pt-150 {
  padding-top: 150px;
}

.pb-150 {
  padding-bottom: 150px;
}

.pr-150 {
  padding-right: 150px;
}

.pl-150 {
  padding-left: 150px;
}

.px-150 {
  padding-left: 150px;
  padding-right: 150px;
}

.mt-155 {
  margin-top: 155px;
}

.mb-155 {
  margin-bottom: 155px;
}

.mr-155 {
  margin-right: 155px;
}

.ml-155 {
  margin-left: 155px;
}

.mx-155 {
  margin-left: 155px;
  margin-right: 155px;
}

.pt-155 {
  padding-top: 155px;
}

.pb-155 {
  padding-bottom: 155px;
}

.pr-155 {
  padding-right: 155px;
}

.pl-155 {
  padding-left: 155px;
}

.px-155 {
  padding-left: 155px;
  padding-right: 155px;
}

.mt-160 {
  margin-top: 160px;
}

.mb-160 {
  margin-bottom: 160px;
}

.mr-160 {
  margin-right: 160px;
}

.ml-160 {
  margin-left: 160px;
}

.mx-160 {
  margin-left: 160px;
  margin-right: 160px;
}

.pt-160 {
  padding-top: 160px;
}

.pb-160 {
  padding-bottom: 160px;
}

.pr-160 {
  padding-right: 160px;
}

.pl-160 {
  padding-left: 160px;
}

.px-160 {
  padding-left: 160px;
  padding-right: 160px;
}

.mt-165 {
  margin-top: 165px;
}

.mb-165 {
  margin-bottom: 165px;
}

.mr-165 {
  margin-right: 165px;
}

.ml-165 {
  margin-left: 165px;
}

.mx-165 {
  margin-left: 165px;
  margin-right: 165px;
}

.pt-165 {
  padding-top: 165px;
}

.pb-165 {
  padding-bottom: 165px;
}

.pr-165 {
  padding-right: 165px;
}

.pl-165 {
  padding-left: 165px;
}

.px-165 {
  padding-left: 165px;
  padding-right: 165px;
}

.mt-170 {
  margin-top: 170px;
}

.mb-170 {
  margin-bottom: 170px;
}

.mr-170 {
  margin-right: 170px;
}

.ml-170 {
  margin-left: 170px;
}

.mx-170 {
  margin-left: 170px;
  margin-right: 170px;
}

.pt-170 {
  padding-top: 170px;
}

.pb-170 {
  padding-bottom: 170px;
}

.pr-170 {
  padding-right: 170px;
}

.pl-170 {
  padding-left: 170px;
}

.px-170 {
  padding-left: 170px;
  padding-right: 170px;
}

.mt-175 {
  margin-top: 175px;
}

.mb-175 {
  margin-bottom: 175px;
}

.mr-175 {
  margin-right: 175px;
}

.ml-175 {
  margin-left: 175px;
}

.mx-175 {
  margin-left: 175px;
  margin-right: 175px;
}

.pt-175 {
  padding-top: 175px;
}

.pb-175 {
  padding-bottom: 175px;
}

.pr-175 {
  padding-right: 175px;
}

.pl-175 {
  padding-left: 175px;
}

.px-175 {
  padding-left: 175px;
  padding-right: 175px;
}

.mt-180 {
  margin-top: 180px;
}

.mb-180 {
  margin-bottom: 180px;
}

.mr-180 {
  margin-right: 180px;
}

.ml-180 {
  margin-left: 180px;
}

.mx-180 {
  margin-left: 180px;
  margin-right: 180px;
}

.pt-180 {
  padding-top: 180px;
}

.pb-180 {
  padding-bottom: 180px;
}

.pr-180 {
  padding-right: 180px;
}

.pl-180 {
  padding-left: 180px;
}

.px-180 {
  padding-left: 180px;
  padding-right: 180px;
}

.mt-185 {
  margin-top: 185px;
}

.mb-185 {
  margin-bottom: 185px;
}

.mr-185 {
  margin-right: 185px;
}

.ml-185 {
  margin-left: 185px;
}

.mx-185 {
  margin-left: 185px;
  margin-right: 185px;
}

.pt-185 {
  padding-top: 185px;
}

.pb-185 {
  padding-bottom: 185px;
}

.pr-185 {
  padding-right: 185px;
}

.pl-185 {
  padding-left: 185px;
}

.px-185 {
  padding-left: 185px;
  padding-right: 185px;
}

.mt-190 {
  margin-top: 190px;
}

.mb-190 {
  margin-bottom: 190px;
}

.mr-190 {
  margin-right: 190px;
}

.ml-190 {
  margin-left: 190px;
}

.mx-190 {
  margin-left: 190px;
  margin-right: 190px;
}

.pt-190 {
  padding-top: 190px;
}

.pb-190 {
  padding-bottom: 190px;
}

.pr-190 {
  padding-right: 190px;
}

.pl-190 {
  padding-left: 190px;
}

.px-190 {
  padding-left: 190px;
  padding-right: 190px;
}

.mt-195 {
  margin-top: 195px;
}

.mb-195 {
  margin-bottom: 195px;
}

.mr-195 {
  margin-right: 195px;
}

.ml-195 {
  margin-left: 195px;
}

.mx-195 {
  margin-left: 195px;
  margin-right: 195px;
}

.pt-195 {
  padding-top: 195px;
}

.pb-195 {
  padding-bottom: 195px;
}

.pr-195 {
  padding-right: 195px;
}

.pl-195 {
  padding-left: 195px;
}

.px-195 {
  padding-left: 195px;
  padding-right: 195px;
}

.mt-200 {
  margin-top: 200px;
}

.mb-200 {
  margin-bottom: 200px;
}

.mr-200 {
  margin-right: 200px;
}

.ml-200 {
  margin-left: 200px;
}

.mx-200 {
  margin-left: 200px;
  margin-right: 200px;
}

.pt-200 {
  padding-top: 200px;
}

.pb-200 {
  padding-bottom: 200px;
}

.pr-200 {
  padding-right: 200px;
}

.pl-200 {
  padding-left: 200px;
}

.px-200 {
  padding-left: 200px;
  padding-right: 200px;
}

.mt-205 {
  margin-top: 205px;
}

.mb-205 {
  margin-bottom: 205px;
}

.mr-205 {
  margin-right: 205px;
}

.ml-205 {
  margin-left: 205px;
}

.mx-205 {
  margin-left: 205px;
  margin-right: 205px;
}

.pt-205 {
  padding-top: 205px;
}

.pb-205 {
  padding-bottom: 205px;
}

.pr-205 {
  padding-right: 205px;
}

.pl-205 {
  padding-left: 205px;
}

.px-205 {
  padding-left: 205px;
  padding-right: 205px;
}

.mt-210 {
  margin-top: 210px;
}

.mb-210 {
  margin-bottom: 210px;
}

.mr-210 {
  margin-right: 210px;
}

.ml-210 {
  margin-left: 210px;
}

.mx-210 {
  margin-left: 210px;
  margin-right: 210px;
}

.pt-210 {
  padding-top: 210px;
}

.pb-210 {
  padding-bottom: 210px;
}

.pr-210 {
  padding-right: 210px;
}

.pl-210 {
  padding-left: 210px;
}

.px-210 {
  padding-left: 210px;
  padding-right: 210px;
}

.mt-215 {
  margin-top: 215px;
}

.mb-215 {
  margin-bottom: 215px;
}

.mr-215 {
  margin-right: 215px;
}

.ml-215 {
  margin-left: 215px;
}

.mx-215 {
  margin-left: 215px;
  margin-right: 215px;
}

.pt-215 {
  padding-top: 215px;
}

.pb-215 {
  padding-bottom: 215px;
}

.pr-215 {
  padding-right: 215px;
}

.pl-215 {
  padding-left: 215px;
}

.px-215 {
  padding-left: 215px;
  padding-right: 215px;
}

.mt-220 {
  margin-top: 220px;
}

.mb-220 {
  margin-bottom: 220px;
}

.mr-220 {
  margin-right: 220px;
}

.ml-220 {
  margin-left: 220px;
}

.mx-220 {
  margin-left: 220px;
  margin-right: 220px;
}

.pt-220 {
  padding-top: 220px;
}

.pb-220 {
  padding-bottom: 220px;
}

.pr-220 {
  padding-right: 220px;
}

.pl-220 {
  padding-left: 220px;
}

.px-220 {
  padding-left: 220px;
  padding-right: 220px;
}

.mt-225 {
  margin-top: 225px;
}

.mb-225 {
  margin-bottom: 225px;
}

.mr-225 {
  margin-right: 225px;
}

.ml-225 {
  margin-left: 225px;
}

.mx-225 {
  margin-left: 225px;
  margin-right: 225px;
}

.pt-225 {
  padding-top: 225px;
}

.pb-225 {
  padding-bottom: 225px;
}

.pr-225 {
  padding-right: 225px;
}

.pl-225 {
  padding-left: 225px;
}

.px-225 {
  padding-left: 225px;
  padding-right: 225px;
}

.mt-230 {
  margin-top: 230px;
}

.mb-230 {
  margin-bottom: 230px;
}

.mr-230 {
  margin-right: 230px;
}

.ml-230 {
  margin-left: 230px;
}

.mx-230 {
  margin-left: 230px;
  margin-right: 230px;
}

.pt-230 {
  padding-top: 230px;
}

.pb-230 {
  padding-bottom: 230px;
}

.pr-230 {
  padding-right: 230px;
}

.pl-230 {
  padding-left: 230px;
}

.px-230 {
  padding-left: 230px;
  padding-right: 230px;
}

.mt-235 {
  margin-top: 235px;
}

.mb-235 {
  margin-bottom: 235px;
}

.mr-235 {
  margin-right: 235px;
}

.ml-235 {
  margin-left: 235px;
}

.mx-235 {
  margin-left: 235px;
  margin-right: 235px;
}

.pt-235 {
  padding-top: 235px;
}

.pb-235 {
  padding-bottom: 235px;
}

.pr-235 {
  padding-right: 235px;
}

.pl-235 {
  padding-left: 235px;
}

.px-235 {
  padding-left: 235px;
  padding-right: 235px;
}

.mt-240 {
  margin-top: 240px;
}

.mb-240 {
  margin-bottom: 240px;
}

.mr-240 {
  margin-right: 240px;
}

.ml-240 {
  margin-left: 240px;
}

.mx-240 {
  margin-left: 240px;
  margin-right: 240px;
}

.pt-240 {
  padding-top: 240px;
}

.pb-240 {
  padding-bottom: 240px;
}

.pr-240 {
  padding-right: 240px;
}

.pl-240 {
  padding-left: 240px;
}

.px-240 {
  padding-left: 240px;
  padding-right: 240px;
}

.mt-245 {
  margin-top: 245px;
}

.mb-245 {
  margin-bottom: 245px;
}

.mr-245 {
  margin-right: 245px;
}

.ml-245 {
  margin-left: 245px;
}

.mx-245 {
  margin-left: 245px;
  margin-right: 245px;
}

.pt-245 {
  padding-top: 245px;
}

.pb-245 {
  padding-bottom: 245px;
}

.pr-245 {
  padding-right: 245px;
}

.pl-245 {
  padding-left: 245px;
}

.px-245 {
  padding-left: 245px;
  padding-right: 245px;
}

.mt-250 {
  margin-top: 250px;
}

.mb-250 {
  margin-bottom: 250px;
}

.mr-250 {
  margin-right: 250px;
}

.ml-250 {
  margin-left: 250px;
}

.mx-250 {
  margin-left: 250px;
  margin-right: 250px;
}

.pt-250 {
  padding-top: 250px;
}

.pb-250 {
  padding-bottom: 250px;
}

.pr-250 {
  padding-right: 250px;
}

.pl-250 {
  padding-left: 250px;
}

.px-250 {
  padding-left: 250px;
  padding-right: 250px;
}

.mt-255 {
  margin-top: 255px;
}

.mb-255 {
  margin-bottom: 255px;
}

.mr-255 {
  margin-right: 255px;
}

.ml-255 {
  margin-left: 255px;
}

.mx-255 {
  margin-left: 255px;
  margin-right: 255px;
}

.pt-255 {
  padding-top: 255px;
}

.pb-255 {
  padding-bottom: 255px;
}

.pr-255 {
  padding-right: 255px;
}

.pl-255 {
  padding-left: 255px;
}

.px-255 {
  padding-left: 255px;
  padding-right: 255px;
}

.mt-260 {
  margin-top: 260px;
}

.mb-260 {
  margin-bottom: 260px;
}

.mr-260 {
  margin-right: 260px;
}

.ml-260 {
  margin-left: 260px;
}

.mx-260 {
  margin-left: 260px;
  margin-right: 260px;
}

.pt-260 {
  padding-top: 260px;
}

.pb-260 {
  padding-bottom: 260px;
}

.pr-260 {
  padding-right: 260px;
}

.pl-260 {
  padding-left: 260px;
}

.px-260 {
  padding-left: 260px;
  padding-right: 260px;
}

.mt-265 {
  margin-top: 265px;
}

.mb-265 {
  margin-bottom: 265px;
}

.mr-265 {
  margin-right: 265px;
}

.ml-265 {
  margin-left: 265px;
}

.mx-265 {
  margin-left: 265px;
  margin-right: 265px;
}

.pt-265 {
  padding-top: 265px;
}

.pb-265 {
  padding-bottom: 265px;
}

.pr-265 {
  padding-right: 265px;
}

.pl-265 {
  padding-left: 265px;
}

.px-265 {
  padding-left: 265px;
  padding-right: 265px;
}

.mt-270 {
  margin-top: 270px;
}

.mb-270 {
  margin-bottom: 270px;
}

.mr-270 {
  margin-right: 270px;
}

.ml-270 {
  margin-left: 270px;
}

.mx-270 {
  margin-left: 270px;
  margin-right: 270px;
}

.pt-270 {
  padding-top: 270px;
}

.pb-270 {
  padding-bottom: 270px;
}

.pr-270 {
  padding-right: 270px;
}

.pl-270 {
  padding-left: 270px;
}

.px-270 {
  padding-left: 270px;
  padding-right: 270px;
}

.mt-275 {
  margin-top: 275px;
}

.mb-275 {
  margin-bottom: 275px;
}

.mr-275 {
  margin-right: 275px;
}

.ml-275 {
  margin-left: 275px;
}

.mx-275 {
  margin-left: 275px;
  margin-right: 275px;
}

.pt-275 {
  padding-top: 275px;
}

.pb-275 {
  padding-bottom: 275px;
}

.pr-275 {
  padding-right: 275px;
}

.pl-275 {
  padding-left: 275px;
}

.px-275 {
  padding-left: 275px;
  padding-right: 275px;
}

.mt-280 {
  margin-top: 280px;
}

.mb-280 {
  margin-bottom: 280px;
}

.mr-280 {
  margin-right: 280px;
}

.ml-280 {
  margin-left: 280px;
}

.mx-280 {
  margin-left: 280px;
  margin-right: 280px;
}

.pt-280 {
  padding-top: 280px;
}

.pb-280 {
  padding-bottom: 280px;
}

.pr-280 {
  padding-right: 280px;
}

.pl-280 {
  padding-left: 280px;
}

.px-280 {
  padding-left: 280px;
  padding-right: 280px;
}

.mt-285 {
  margin-top: 285px;
}

.mb-285 {
  margin-bottom: 285px;
}

.mr-285 {
  margin-right: 285px;
}

.ml-285 {
  margin-left: 285px;
}

.mx-285 {
  margin-left: 285px;
  margin-right: 285px;
}

.pt-285 {
  padding-top: 285px;
}

.pb-285 {
  padding-bottom: 285px;
}

.pr-285 {
  padding-right: 285px;
}

.pl-285 {
  padding-left: 285px;
}

.px-285 {
  padding-left: 285px;
  padding-right: 285px;
}

.mt-290 {
  margin-top: 290px;
}

.mb-290 {
  margin-bottom: 290px;
}

.mr-290 {
  margin-right: 290px;
}

.ml-290 {
  margin-left: 290px;
}

.mx-290 {
  margin-left: 290px;
  margin-right: 290px;
}

.pt-290 {
  padding-top: 290px;
}

.pb-290 {
  padding-bottom: 290px;
}

.pr-290 {
  padding-right: 290px;
}

.pl-290 {
  padding-left: 290px;
}

.px-290 {
  padding-left: 290px;
  padding-right: 290px;
}

.mt-295 {
  margin-top: 295px;
}

.mb-295 {
  margin-bottom: 295px;
}

.mr-295 {
  margin-right: 295px;
}

.ml-295 {
  margin-left: 295px;
}

.mx-295 {
  margin-left: 295px;
  margin-right: 295px;
}

.pt-295 {
  padding-top: 295px;
}

.pb-295 {
  padding-bottom: 295px;
}

.pr-295 {
  padding-right: 295px;
}

.pl-295 {
  padding-left: 295px;
}

.px-295 {
  padding-left: 295px;
  padding-right: 295px;
}

.mt-300 {
  margin-top: 300px;
}

.mb-300 {
  margin-bottom: 300px;
}

.mr-300 {
  margin-right: 300px;
}

.ml-300 {
  margin-left: 300px;
}

.mx-300 {
  margin-left: 300px;
  margin-right: 300px;
}

.pt-300 {
  padding-top: 300px;
}

.pb-300 {
  padding-bottom: 300px;
}

.pr-300 {
  padding-right: 300px;
}

.pl-300 {
  padding-left: 300px;
}

.px-300 {
  padding-left: 300px;
  padding-right: 300px;
}

.mt-305 {
  margin-top: 305px;
}

.mb-305 {
  margin-bottom: 305px;
}

.mr-305 {
  margin-right: 305px;
}

.ml-305 {
  margin-left: 305px;
}

.mx-305 {
  margin-left: 305px;
  margin-right: 305px;
}

.pt-305 {
  padding-top: 305px;
}

.pb-305 {
  padding-bottom: 305px;
}

.pr-305 {
  padding-right: 305px;
}

.pl-305 {
  padding-left: 305px;
}

.px-305 {
  padding-left: 305px;
  padding-right: 305px;
}

.mt-310 {
  margin-top: 310px;
}

.mb-310 {
  margin-bottom: 310px;
}

.mr-310 {
  margin-right: 310px;
}

.ml-310 {
  margin-left: 310px;
}

.mx-310 {
  margin-left: 310px;
  margin-right: 310px;
}

.pt-310 {
  padding-top: 310px;
}

.pb-310 {
  padding-bottom: 310px;
}

.pr-310 {
  padding-right: 310px;
}

.pl-310 {
  padding-left: 310px;
}

.px-310 {
  padding-left: 310px;
  padding-right: 310px;
}

.mt-315 {
  margin-top: 315px;
}

.mb-315 {
  margin-bottom: 315px;
}

.mr-315 {
  margin-right: 315px;
}

.ml-315 {
  margin-left: 315px;
}

.mx-315 {
  margin-left: 315px;
  margin-right: 315px;
}

.pt-315 {
  padding-top: 315px;
}

.pb-315 {
  padding-bottom: 315px;
}

.pr-315 {
  padding-right: 315px;
}

.pl-315 {
  padding-left: 315px;
}

.px-315 {
  padding-left: 315px;
  padding-right: 315px;
}

.mt-320 {
  margin-top: 320px;
}

.mb-320 {
  margin-bottom: 320px;
}

.mr-320 {
  margin-right: 320px;
}

.ml-320 {
  margin-left: 320px;
}

.mx-320 {
  margin-left: 320px;
  margin-right: 320px;
}

.pt-320 {
  padding-top: 320px;
}

.pb-320 {
  padding-bottom: 320px;
}

.pr-320 {
  padding-right: 320px;
}

.pl-320 {
  padding-left: 320px;
}

.px-320 {
  padding-left: 320px;
  padding-right: 320px;
}

.mt-325 {
  margin-top: 325px;
}

.mb-325 {
  margin-bottom: 325px;
}

.mr-325 {
  margin-right: 325px;
}

.ml-325 {
  margin-left: 325px;
}

.mx-325 {
  margin-left: 325px;
  margin-right: 325px;
}

.pt-325 {
  padding-top: 325px;
}

.pb-325 {
  padding-bottom: 325px;
}

.pr-325 {
  padding-right: 325px;
}

.pl-325 {
  padding-left: 325px;
}

.px-325 {
  padding-left: 325px;
  padding-right: 325px;
}

.mt-330 {
  margin-top: 330px;
}

.mb-330 {
  margin-bottom: 330px;
}

.mr-330 {
  margin-right: 330px;
}

.ml-330 {
  margin-left: 330px;
}

.mx-330 {
  margin-left: 330px;
  margin-right: 330px;
}

.pt-330 {
  padding-top: 330px;
}

.pb-330 {
  padding-bottom: 330px;
}

.pr-330 {
  padding-right: 330px;
}

.pl-330 {
  padding-left: 330px;
}

.px-330 {
  padding-left: 330px;
  padding-right: 330px;
}

.mt-335 {
  margin-top: 335px;
}

.mb-335 {
  margin-bottom: 335px;
}

.mr-335 {
  margin-right: 335px;
}

.ml-335 {
  margin-left: 335px;
}

.mx-335 {
  margin-left: 335px;
  margin-right: 335px;
}

.pt-335 {
  padding-top: 335px;
}

.pb-335 {
  padding-bottom: 335px;
}

.pr-335 {
  padding-right: 335px;
}

.pl-335 {
  padding-left: 335px;
}

.px-335 {
  padding-left: 335px;
  padding-right: 335px;
}

.mt-340 {
  margin-top: 340px;
}

.mb-340 {
  margin-bottom: 340px;
}

.mr-340 {
  margin-right: 340px;
}

.ml-340 {
  margin-left: 340px;
}

.mx-340 {
  margin-left: 340px;
  margin-right: 340px;
}

.pt-340 {
  padding-top: 340px;
}

.pb-340 {
  padding-bottom: 340px;
}

.pr-340 {
  padding-right: 340px;
}

.pl-340 {
  padding-left: 340px;
}

.px-340 {
  padding-left: 340px;
  padding-right: 340px;
}

.mt-345 {
  margin-top: 345px;
}

.mb-345 {
  margin-bottom: 345px;
}

.mr-345 {
  margin-right: 345px;
}

.ml-345 {
  margin-left: 345px;
}

.mx-345 {
  margin-left: 345px;
  margin-right: 345px;
}

.pt-345 {
  padding-top: 345px;
}

.pb-345 {
  padding-bottom: 345px;
}

.pr-345 {
  padding-right: 345px;
}

.pl-345 {
  padding-left: 345px;
}

.px-345 {
  padding-left: 345px;
  padding-right: 345px;
}

.mt-350 {
  margin-top: 350px;
}

.mb-350 {
  margin-bottom: 350px;
}

.mr-350 {
  margin-right: 350px;
}

.ml-350 {
  margin-left: 350px;
}

.mx-350 {
  margin-left: 350px;
  margin-right: 350px;
}

.pt-350 {
  padding-top: 350px;
}

.pb-350 {
  padding-bottom: 350px;
}

.pr-350 {
  padding-right: 350px;
}

.pl-350 {
  padding-left: 350px;
}

.px-350 {
  padding-left: 350px;
  padding-right: 350px;
}

.mt-355 {
  margin-top: 355px;
}

.mb-355 {
  margin-bottom: 355px;
}

.mr-355 {
  margin-right: 355px;
}

.ml-355 {
  margin-left: 355px;
}

.mx-355 {
  margin-left: 355px;
  margin-right: 355px;
}

.pt-355 {
  padding-top: 355px;
}

.pb-355 {
  padding-bottom: 355px;
}

.pr-355 {
  padding-right: 355px;
}

.pl-355 {
  padding-left: 355px;
}

.px-355 {
  padding-left: 355px;
  padding-right: 355px;
}

.mt-360 {
  margin-top: 360px;
}

.mb-360 {
  margin-bottom: 360px;
}

.mr-360 {
  margin-right: 360px;
}

.ml-360 {
  margin-left: 360px;
}

.mx-360 {
  margin-left: 360px;
  margin-right: 360px;
}

.pt-360 {
  padding-top: 360px;
}

.pb-360 {
  padding-bottom: 360px;
}

.pr-360 {
  padding-right: 360px;
}

.pl-360 {
  padding-left: 360px;
}

.px-360 {
  padding-left: 360px;
  padding-right: 360px;
}

.mt-365 {
  margin-top: 365px;
}

.mb-365 {
  margin-bottom: 365px;
}

.mr-365 {
  margin-right: 365px;
}

.ml-365 {
  margin-left: 365px;
}

.mx-365 {
  margin-left: 365px;
  margin-right: 365px;
}

.pt-365 {
  padding-top: 365px;
}

.pb-365 {
  padding-bottom: 365px;
}

.pr-365 {
  padding-right: 365px;
}

.pl-365 {
  padding-left: 365px;
}

.px-365 {
  padding-left: 365px;
  padding-right: 365px;
}

.mt-370 {
  margin-top: 370px;
}

.mb-370 {
  margin-bottom: 370px;
}

.mr-370 {
  margin-right: 370px;
}

.ml-370 {
  margin-left: 370px;
}

.mx-370 {
  margin-left: 370px;
  margin-right: 370px;
}

.pt-370 {
  padding-top: 370px;
}

.pb-370 {
  padding-bottom: 370px;
}

.pr-370 {
  padding-right: 370px;
}

.pl-370 {
  padding-left: 370px;
}

.px-370 {
  padding-left: 370px;
  padding-right: 370px;
}

.mt-375 {
  margin-top: 375px;
}

.mb-375 {
  margin-bottom: 375px;
}

.mr-375 {
  margin-right: 375px;
}

.ml-375 {
  margin-left: 375px;
}

.mx-375 {
  margin-left: 375px;
  margin-right: 375px;
}

.pt-375 {
  padding-top: 375px;
}

.pb-375 {
  padding-bottom: 375px;
}

.pr-375 {
  padding-right: 375px;
}

.pl-375 {
  padding-left: 375px;
}

.px-375 {
  padding-left: 375px;
  padding-right: 375px;
}

.mt-380 {
  margin-top: 380px;
}

.mb-380 {
  margin-bottom: 380px;
}

.mr-380 {
  margin-right: 380px;
}

.ml-380 {
  margin-left: 380px;
}

.mx-380 {
  margin-left: 380px;
  margin-right: 380px;
}

.pt-380 {
  padding-top: 380px;
}

.pb-380 {
  padding-bottom: 380px;
}

.pr-380 {
  padding-right: 380px;
}

.pl-380 {
  padding-left: 380px;
}

.px-380 {
  padding-left: 380px;
  padding-right: 380px;
}

.mt-385 {
  margin-top: 385px;
}

.mb-385 {
  margin-bottom: 385px;
}

.mr-385 {
  margin-right: 385px;
}

.ml-385 {
  margin-left: 385px;
}

.mx-385 {
  margin-left: 385px;
  margin-right: 385px;
}

.pt-385 {
  padding-top: 385px;
}

.pb-385 {
  padding-bottom: 385px;
}

.pr-385 {
  padding-right: 385px;
}

.pl-385 {
  padding-left: 385px;
}

.px-385 {
  padding-left: 385px;
  padding-right: 385px;
}

.mt-390 {
  margin-top: 390px;
}

.mb-390 {
  margin-bottom: 390px;
}

.mr-390 {
  margin-right: 390px;
}

.ml-390 {
  margin-left: 390px;
}

.mx-390 {
  margin-left: 390px;
  margin-right: 390px;
}

.pt-390 {
  padding-top: 390px;
}

.pb-390 {
  padding-bottom: 390px;
}

.pr-390 {
  padding-right: 390px;
}

.pl-390 {
  padding-left: 390px;
}

.px-390 {
  padding-left: 390px;
  padding-right: 390px;
}

.mt-395 {
  margin-top: 395px;
}

.mb-395 {
  margin-bottom: 395px;
}

.mr-395 {
  margin-right: 395px;
}

.ml-395 {
  margin-left: 395px;
}

.mx-395 {
  margin-left: 395px;
  margin-right: 395px;
}

.pt-395 {
  padding-top: 395px;
}

.pb-395 {
  padding-bottom: 395px;
}

.pr-395 {
  padding-right: 395px;
}

.pl-395 {
  padding-left: 395px;
}

.px-395 {
  padding-left: 395px;
  padding-right: 395px;
}

.mt-400 {
  margin-top: 400px;
}

.mb-400 {
  margin-bottom: 400px;
}

.mr-400 {
  margin-right: 400px;
}

.ml-400 {
  margin-left: 400px;
}

.mx-400 {
  margin-left: 400px;
  margin-right: 400px;
}

.pt-400 {
  padding-top: 400px;
}

.pb-400 {
  padding-bottom: 400px;
}

.pr-400 {
  padding-right: 400px;
}

.pl-400 {
  padding-left: 400px;
}

.px-400 {
  padding-left: 400px;
  padding-right: 400px;
}

.mt-405 {
  margin-top: 405px;
}

.mb-405 {
  margin-bottom: 405px;
}

.mr-405 {
  margin-right: 405px;
}

.ml-405 {
  margin-left: 405px;
}

.mx-405 {
  margin-left: 405px;
  margin-right: 405px;
}

.pt-405 {
  padding-top: 405px;
}

.pb-405 {
  padding-bottom: 405px;
}

.pr-405 {
  padding-right: 405px;
}

.pl-405 {
  padding-left: 405px;
}

.px-405 {
  padding-left: 405px;
  padding-right: 405px;
}

.mt-410 {
  margin-top: 410px;
}

.mb-410 {
  margin-bottom: 410px;
}

.mr-410 {
  margin-right: 410px;
}

.ml-410 {
  margin-left: 410px;
}

.mx-410 {
  margin-left: 410px;
  margin-right: 410px;
}

.pt-410 {
  padding-top: 410px;
}

.pb-410 {
  padding-bottom: 410px;
}

.pr-410 {
  padding-right: 410px;
}

.pl-410 {
  padding-left: 410px;
}

.px-410 {
  padding-left: 410px;
  padding-right: 410px;
}

.mt-415 {
  margin-top: 415px;
}

.mb-415 {
  margin-bottom: 415px;
}

.mr-415 {
  margin-right: 415px;
}

.ml-415 {
  margin-left: 415px;
}

.mx-415 {
  margin-left: 415px;
  margin-right: 415px;
}

.pt-415 {
  padding-top: 415px;
}

.pb-415 {
  padding-bottom: 415px;
}

.pr-415 {
  padding-right: 415px;
}

.pl-415 {
  padding-left: 415px;
}

.px-415 {
  padding-left: 415px;
  padding-right: 415px;
}

.mt-420 {
  margin-top: 420px;
}

.mb-420 {
  margin-bottom: 420px;
}

.mr-420 {
  margin-right: 420px;
}

.ml-420 {
  margin-left: 420px;
}

.mx-420 {
  margin-left: 420px;
  margin-right: 420px;
}

.pt-420 {
  padding-top: 420px;
}

.pb-420 {
  padding-bottom: 420px;
}

.pr-420 {
  padding-right: 420px;
}

.pl-420 {
  padding-left: 420px;
}

.px-420 {
  padding-left: 420px;
  padding-right: 420px;
}

.mt-425 {
  margin-top: 425px;
}

.mb-425 {
  margin-bottom: 425px;
}

.mr-425 {
  margin-right: 425px;
}

.ml-425 {
  margin-left: 425px;
}

.mx-425 {
  margin-left: 425px;
  margin-right: 425px;
}

.pt-425 {
  padding-top: 425px;
}

.pb-425 {
  padding-bottom: 425px;
}

.pr-425 {
  padding-right: 425px;
}

.pl-425 {
  padding-left: 425px;
}

.px-425 {
  padding-left: 425px;
  padding-right: 425px;
}

.mt-430 {
  margin-top: 430px;
}

.mb-430 {
  margin-bottom: 430px;
}

.mr-430 {
  margin-right: 430px;
}

.ml-430 {
  margin-left: 430px;
}

.mx-430 {
  margin-left: 430px;
  margin-right: 430px;
}

.pt-430 {
  padding-top: 430px;
}

.pb-430 {
  padding-bottom: 430px;
}

.pr-430 {
  padding-right: 430px;
}

.pl-430 {
  padding-left: 430px;
}

.px-430 {
  padding-left: 430px;
  padding-right: 430px;
}

.mt-435 {
  margin-top: 435px;
}

.mb-435 {
  margin-bottom: 435px;
}

.mr-435 {
  margin-right: 435px;
}

.ml-435 {
  margin-left: 435px;
}

.mx-435 {
  margin-left: 435px;
  margin-right: 435px;
}

.pt-435 {
  padding-top: 435px;
}

.pb-435 {
  padding-bottom: 435px;
}

.pr-435 {
  padding-right: 435px;
}

.pl-435 {
  padding-left: 435px;
}

.px-435 {
  padding-left: 435px;
  padding-right: 435px;
}

.mt-440 {
  margin-top: 440px;
}

.mb-440 {
  margin-bottom: 440px;
}

.mr-440 {
  margin-right: 440px;
}

.ml-440 {
  margin-left: 440px;
}

.mx-440 {
  margin-left: 440px;
  margin-right: 440px;
}

.pt-440 {
  padding-top: 440px;
}

.pb-440 {
  padding-bottom: 440px;
}

.pr-440 {
  padding-right: 440px;
}

.pl-440 {
  padding-left: 440px;
}

.px-440 {
  padding-left: 440px;
  padding-right: 440px;
}

.mt-445 {
  margin-top: 445px;
}

.mb-445 {
  margin-bottom: 445px;
}

.mr-445 {
  margin-right: 445px;
}

.ml-445 {
  margin-left: 445px;
}

.mx-445 {
  margin-left: 445px;
  margin-right: 445px;
}

.pt-445 {
  padding-top: 445px;
}

.pb-445 {
  padding-bottom: 445px;
}

.pr-445 {
  padding-right: 445px;
}

.pl-445 {
  padding-left: 445px;
}

.px-445 {
  padding-left: 445px;
  padding-right: 445px;
}

.mt-450 {
  margin-top: 450px;
}

.mb-450 {
  margin-bottom: 450px;
}

.mr-450 {
  margin-right: 450px;
}

.ml-450 {
  margin-left: 450px;
}

.mx-450 {
  margin-left: 450px;
  margin-right: 450px;
}

.pt-450 {
  padding-top: 450px;
}

.pb-450 {
  padding-bottom: 450px;
}

.pr-450 {
  padding-right: 450px;
}

.pl-450 {
  padding-left: 450px;
}

.px-450 {
  padding-left: 450px;
  padding-right: 450px;
}

.mt-455 {
  margin-top: 455px;
}

.mb-455 {
  margin-bottom: 455px;
}

.mr-455 {
  margin-right: 455px;
}

.ml-455 {
  margin-left: 455px;
}

.mx-455 {
  margin-left: 455px;
  margin-right: 455px;
}

.pt-455 {
  padding-top: 455px;
}

.pb-455 {
  padding-bottom: 455px;
}

.pr-455 {
  padding-right: 455px;
}

.pl-455 {
  padding-left: 455px;
}

.px-455 {
  padding-left: 455px;
  padding-right: 455px;
}

.mt-460 {
  margin-top: 460px;
}

.mb-460 {
  margin-bottom: 460px;
}

.mr-460 {
  margin-right: 460px;
}

.ml-460 {
  margin-left: 460px;
}

.mx-460 {
  margin-left: 460px;
  margin-right: 460px;
}

.pt-460 {
  padding-top: 460px;
}

.pb-460 {
  padding-bottom: 460px;
}

.pr-460 {
  padding-right: 460px;
}

.pl-460 {
  padding-left: 460px;
}

.px-460 {
  padding-left: 460px;
  padding-right: 460px;
}

.mt-465 {
  margin-top: 465px;
}

.mb-465 {
  margin-bottom: 465px;
}

.mr-465 {
  margin-right: 465px;
}

.ml-465 {
  margin-left: 465px;
}

.mx-465 {
  margin-left: 465px;
  margin-right: 465px;
}

.pt-465 {
  padding-top: 465px;
}

.pb-465 {
  padding-bottom: 465px;
}

.pr-465 {
  padding-right: 465px;
}

.pl-465 {
  padding-left: 465px;
}

.px-465 {
  padding-left: 465px;
  padding-right: 465px;
}

.mt-470 {
  margin-top: 470px;
}

.mb-470 {
  margin-bottom: 470px;
}

.mr-470 {
  margin-right: 470px;
}

.ml-470 {
  margin-left: 470px;
}

.mx-470 {
  margin-left: 470px;
  margin-right: 470px;
}

.pt-470 {
  padding-top: 470px;
}

.pb-470 {
  padding-bottom: 470px;
}

.pr-470 {
  padding-right: 470px;
}

.pl-470 {
  padding-left: 470px;
}

.px-470 {
  padding-left: 470px;
  padding-right: 470px;
}

.mt-475 {
  margin-top: 475px;
}

.mb-475 {
  margin-bottom: 475px;
}

.mr-475 {
  margin-right: 475px;
}

.ml-475 {
  margin-left: 475px;
}

.mx-475 {
  margin-left: 475px;
  margin-right: 475px;
}

.pt-475 {
  padding-top: 475px;
}

.pb-475 {
  padding-bottom: 475px;
}

.pr-475 {
  padding-right: 475px;
}

.pl-475 {
  padding-left: 475px;
}

.px-475 {
  padding-left: 475px;
  padding-right: 475px;
}

.mt-480 {
  margin-top: 480px;
}

.mb-480 {
  margin-bottom: 480px;
}

.mr-480 {
  margin-right: 480px;
}

.ml-480 {
  margin-left: 480px;
}

.mx-480 {
  margin-left: 480px;
  margin-right: 480px;
}

.pt-480 {
  padding-top: 480px;
}

.pb-480 {
  padding-bottom: 480px;
}

.pr-480 {
  padding-right: 480px;
}

.pl-480 {
  padding-left: 480px;
}

.px-480 {
  padding-left: 480px;
  padding-right: 480px;
}

.mt-485 {
  margin-top: 485px;
}

.mb-485 {
  margin-bottom: 485px;
}

.mr-485 {
  margin-right: 485px;
}

.ml-485 {
  margin-left: 485px;
}

.mx-485 {
  margin-left: 485px;
  margin-right: 485px;
}

.pt-485 {
  padding-top: 485px;
}

.pb-485 {
  padding-bottom: 485px;
}

.pr-485 {
  padding-right: 485px;
}

.pl-485 {
  padding-left: 485px;
}

.px-485 {
  padding-left: 485px;
  padding-right: 485px;
}

.mt-490 {
  margin-top: 490px;
}

.mb-490 {
  margin-bottom: 490px;
}

.mr-490 {
  margin-right: 490px;
}

.ml-490 {
  margin-left: 490px;
}

.mx-490 {
  margin-left: 490px;
  margin-right: 490px;
}

.pt-490 {
  padding-top: 490px;
}

.pb-490 {
  padding-bottom: 490px;
}

.pr-490 {
  padding-right: 490px;
}

.pl-490 {
  padding-left: 490px;
}

.px-490 {
  padding-left: 490px;
  padding-right: 490px;
}

.mt-495 {
  margin-top: 495px;
}

.mb-495 {
  margin-bottom: 495px;
}

.mr-495 {
  margin-right: 495px;
}

.ml-495 {
  margin-left: 495px;
}

.mx-495 {
  margin-left: 495px;
  margin-right: 495px;
}

.pt-495 {
  padding-top: 495px;
}

.pb-495 {
  padding-bottom: 495px;
}

.pr-495 {
  padding-right: 495px;
}

.pl-495 {
  padding-left: 495px;
}

.px-495 {
  padding-left: 495px;
  padding-right: 495px;
}

.mt-500 {
  margin-top: 500px;
}

.mb-500 {
  margin-bottom: 500px;
}

.mr-500 {
  margin-right: 500px;
}

.ml-500 {
  margin-left: 500px;
}

.mx-500 {
  margin-left: 500px;
  margin-right: 500px;
}

.pt-500 {
  padding-top: 500px;
}

.pb-500 {
  padding-bottom: 500px;
}

.pr-500 {
  padding-right: 500px;
}

.pl-500 {
  padding-left: 500px;
}

.px-500 {
  padding-left: 500px;
  padding-right: 500px;
}

.mt-505 {
  margin-top: 505px;
}

.mb-505 {
  margin-bottom: 505px;
}

.mr-505 {
  margin-right: 505px;
}

.ml-505 {
  margin-left: 505px;
}

.mx-505 {
  margin-left: 505px;
  margin-right: 505px;
}

.pt-505 {
  padding-top: 505px;
}

.pb-505 {
  padding-bottom: 505px;
}

.pr-505 {
  padding-right: 505px;
}

.pl-505 {
  padding-left: 505px;
}

.px-505 {
  padding-left: 505px;
  padding-right: 505px;
}

.mt-510 {
  margin-top: 510px;
}

.mb-510 {
  margin-bottom: 510px;
}

.mr-510 {
  margin-right: 510px;
}

.ml-510 {
  margin-left: 510px;
}

.mx-510 {
  margin-left: 510px;
  margin-right: 510px;
}

.pt-510 {
  padding-top: 510px;
}

.pb-510 {
  padding-bottom: 510px;
}

.pr-510 {
  padding-right: 510px;
}

.pl-510 {
  padding-left: 510px;
}

.px-510 {
  padding-left: 510px;
  padding-right: 510px;
}

.mt-515 {
  margin-top: 515px;
}

.mb-515 {
  margin-bottom: 515px;
}

.mr-515 {
  margin-right: 515px;
}

.ml-515 {
  margin-left: 515px;
}

.mx-515 {
  margin-left: 515px;
  margin-right: 515px;
}

.pt-515 {
  padding-top: 515px;
}

.pb-515 {
  padding-bottom: 515px;
}

.pr-515 {
  padding-right: 515px;
}

.pl-515 {
  padding-left: 515px;
}

.px-515 {
  padding-left: 515px;
  padding-right: 515px;
}

.mt-520 {
  margin-top: 520px;
}

.mb-520 {
  margin-bottom: 520px;
}

.mr-520 {
  margin-right: 520px;
}

.ml-520 {
  margin-left: 520px;
}

.mx-520 {
  margin-left: 520px;
  margin-right: 520px;
}

.pt-520 {
  padding-top: 520px;
}

.pb-520 {
  padding-bottom: 520px;
}

.pr-520 {
  padding-right: 520px;
}

.pl-520 {
  padding-left: 520px;
}

.px-520 {
  padding-left: 520px;
  padding-right: 520px;
}

.mt-525 {
  margin-top: 525px;
}

.mb-525 {
  margin-bottom: 525px;
}

.mr-525 {
  margin-right: 525px;
}

.ml-525 {
  margin-left: 525px;
}

.mx-525 {
  margin-left: 525px;
  margin-right: 525px;
}

.pt-525 {
  padding-top: 525px;
}

.pb-525 {
  padding-bottom: 525px;
}

.pr-525 {
  padding-right: 525px;
}

.pl-525 {
  padding-left: 525px;
}

.px-525 {
  padding-left: 525px;
  padding-right: 525px;
}

.mt-530 {
  margin-top: 530px;
}

.mb-530 {
  margin-bottom: 530px;
}

.mr-530 {
  margin-right: 530px;
}

.ml-530 {
  margin-left: 530px;
}

.mx-530 {
  margin-left: 530px;
  margin-right: 530px;
}

.pt-530 {
  padding-top: 530px;
}

.pb-530 {
  padding-bottom: 530px;
}

.pr-530 {
  padding-right: 530px;
}

.pl-530 {
  padding-left: 530px;
}

.px-530 {
  padding-left: 530px;
  padding-right: 530px;
}

.mt-535 {
  margin-top: 535px;
}

.mb-535 {
  margin-bottom: 535px;
}

.mr-535 {
  margin-right: 535px;
}

.ml-535 {
  margin-left: 535px;
}

.mx-535 {
  margin-left: 535px;
  margin-right: 535px;
}

.pt-535 {
  padding-top: 535px;
}

.pb-535 {
  padding-bottom: 535px;
}

.pr-535 {
  padding-right: 535px;
}

.pl-535 {
  padding-left: 535px;
}

.px-535 {
  padding-left: 535px;
  padding-right: 535px;
}

.mt-540 {
  margin-top: 540px;
}

.mb-540 {
  margin-bottom: 540px;
}

.mr-540 {
  margin-right: 540px;
}

.ml-540 {
  margin-left: 540px;
}

.mx-540 {
  margin-left: 540px;
  margin-right: 540px;
}

.pt-540 {
  padding-top: 540px;
}

.pb-540 {
  padding-bottom: 540px;
}

.pr-540 {
  padding-right: 540px;
}

.pl-540 {
  padding-left: 540px;
}

.px-540 {
  padding-left: 540px;
  padding-right: 540px;
}

.mt-545 {
  margin-top: 545px;
}

.mb-545 {
  margin-bottom: 545px;
}

.mr-545 {
  margin-right: 545px;
}

.ml-545 {
  margin-left: 545px;
}

.mx-545 {
  margin-left: 545px;
  margin-right: 545px;
}

.pt-545 {
  padding-top: 545px;
}

.pb-545 {
  padding-bottom: 545px;
}

.pr-545 {
  padding-right: 545px;
}

.pl-545 {
  padding-left: 545px;
}

.px-545 {
  padding-left: 545px;
  padding-right: 545px;
}

.mt-550 {
  margin-top: 550px;
}

.mb-550 {
  margin-bottom: 550px;
}

.mr-550 {
  margin-right: 550px;
}

.ml-550 {
  margin-left: 550px;
}

.mx-550 {
  margin-left: 550px;
  margin-right: 550px;
}

.pt-550 {
  padding-top: 550px;
}

.pb-550 {
  padding-bottom: 550px;
}

.pr-550 {
  padding-right: 550px;
}

.pl-550 {
  padding-left: 550px;
}

.px-550 {
  padding-left: 550px;
  padding-right: 550px;
}

.mt-555 {
  margin-top: 555px;
}

.mb-555 {
  margin-bottom: 555px;
}

.mr-555 {
  margin-right: 555px;
}

.ml-555 {
  margin-left: 555px;
}

.mx-555 {
  margin-left: 555px;
  margin-right: 555px;
}

.pt-555 {
  padding-top: 555px;
}

.pb-555 {
  padding-bottom: 555px;
}

.pr-555 {
  padding-right: 555px;
}

.pl-555 {
  padding-left: 555px;
}

.px-555 {
  padding-left: 555px;
  padding-right: 555px;
}

.mt-560 {
  margin-top: 560px;
}

.mb-560 {
  margin-bottom: 560px;
}

.mr-560 {
  margin-right: 560px;
}

.ml-560 {
  margin-left: 560px;
}

.mx-560 {
  margin-left: 560px;
  margin-right: 560px;
}

.pt-560 {
  padding-top: 560px;
}

.pb-560 {
  padding-bottom: 560px;
}

.pr-560 {
  padding-right: 560px;
}

.pl-560 {
  padding-left: 560px;
}

.px-560 {
  padding-left: 560px;
  padding-right: 560px;
}

.mt-565 {
  margin-top: 565px;
}

.mb-565 {
  margin-bottom: 565px;
}

.mr-565 {
  margin-right: 565px;
}

.ml-565 {
  margin-left: 565px;
}

.mx-565 {
  margin-left: 565px;
  margin-right: 565px;
}

.pt-565 {
  padding-top: 565px;
}

.pb-565 {
  padding-bottom: 565px;
}

.pr-565 {
  padding-right: 565px;
}

.pl-565 {
  padding-left: 565px;
}

.px-565 {
  padding-left: 565px;
  padding-right: 565px;
}

.mt-570 {
  margin-top: 570px;
}

.mb-570 {
  margin-bottom: 570px;
}

.mr-570 {
  margin-right: 570px;
}

.ml-570 {
  margin-left: 570px;
}

.mx-570 {
  margin-left: 570px;
  margin-right: 570px;
}

.pt-570 {
  padding-top: 570px;
}

.pb-570 {
  padding-bottom: 570px;
}

.pr-570 {
  padding-right: 570px;
}

.pl-570 {
  padding-left: 570px;
}

.px-570 {
  padding-left: 570px;
  padding-right: 570px;
}

.mt-575 {
  margin-top: 575px;
}

.mb-575 {
  margin-bottom: 575px;
}

.mr-575 {
  margin-right: 575px;
}

.ml-575 {
  margin-left: 575px;
}

.mx-575 {
  margin-left: 575px;
  margin-right: 575px;
}

.pt-575 {
  padding-top: 575px;
}

.pb-575 {
  padding-bottom: 575px;
}

.pr-575 {
  padding-right: 575px;
}

.pl-575 {
  padding-left: 575px;
}

.px-575 {
  padding-left: 575px;
  padding-right: 575px;
}

.mt-580 {
  margin-top: 580px;
}

.mb-580 {
  margin-bottom: 580px;
}

.mr-580 {
  margin-right: 580px;
}

.ml-580 {
  margin-left: 580px;
}

.mx-580 {
  margin-left: 580px;
  margin-right: 580px;
}

.pt-580 {
  padding-top: 580px;
}

.pb-580 {
  padding-bottom: 580px;
}

.pr-580 {
  padding-right: 580px;
}

.pl-580 {
  padding-left: 580px;
}

.px-580 {
  padding-left: 580px;
  padding-right: 580px;
}

.mt-585 {
  margin-top: 585px;
}

.mb-585 {
  margin-bottom: 585px;
}

.mr-585 {
  margin-right: 585px;
}

.ml-585 {
  margin-left: 585px;
}

.mx-585 {
  margin-left: 585px;
  margin-right: 585px;
}

.pt-585 {
  padding-top: 585px;
}

.pb-585 {
  padding-bottom: 585px;
}

.pr-585 {
  padding-right: 585px;
}

.pl-585 {
  padding-left: 585px;
}

.px-585 {
  padding-left: 585px;
  padding-right: 585px;
}

.mt-590 {
  margin-top: 590px;
}

.mb-590 {
  margin-bottom: 590px;
}

.mr-590 {
  margin-right: 590px;
}

.ml-590 {
  margin-left: 590px;
}

.mx-590 {
  margin-left: 590px;
  margin-right: 590px;
}

.pt-590 {
  padding-top: 590px;
}

.pb-590 {
  padding-bottom: 590px;
}

.pr-590 {
  padding-right: 590px;
}

.pl-590 {
  padding-left: 590px;
}

.px-590 {
  padding-left: 590px;
  padding-right: 590px;
}

.mt-595 {
  margin-top: 595px;
}

.mb-595 {
  margin-bottom: 595px;
}

.mr-595 {
  margin-right: 595px;
}

.ml-595 {
  margin-left: 595px;
}

.mx-595 {
  margin-left: 595px;
  margin-right: 595px;
}

.pt-595 {
  padding-top: 595px;
}

.pb-595 {
  padding-bottom: 595px;
}

.pr-595 {
  padding-right: 595px;
}

.pl-595 {
  padding-left: 595px;
}

.px-595 {
  padding-left: 595px;
  padding-right: 595px;
}

.mt-600 {
  margin-top: 600px;
}

.mb-600 {
  margin-bottom: 600px;
}

.mr-600 {
  margin-right: 600px;
}

.ml-600 {
  margin-left: 600px;
}

.mx-600 {
  margin-left: 600px;
  margin-right: 600px;
}

.pt-600 {
  padding-top: 600px;
}

.pb-600 {
  padding-bottom: 600px;
}

.pr-600 {
  padding-right: 600px;
}

.pl-600 {
  padding-left: 600px;
}

.px-600 {
  padding-left: 600px;
  padding-right: 600px;
}

.mt-605 {
  margin-top: 605px;
}

.mb-605 {
  margin-bottom: 605px;
}

.mr-605 {
  margin-right: 605px;
}

.ml-605 {
  margin-left: 605px;
}

.mx-605 {
  margin-left: 605px;
  margin-right: 605px;
}

.pt-605 {
  padding-top: 605px;
}

.pb-605 {
  padding-bottom: 605px;
}

.pr-605 {
  padding-right: 605px;
}

.pl-605 {
  padding-left: 605px;
}

.px-605 {
  padding-left: 605px;
  padding-right: 605px;
}

.mt-610 {
  margin-top: 610px;
}

.mb-610 {
  margin-bottom: 610px;
}

.mr-610 {
  margin-right: 610px;
}

.ml-610 {
  margin-left: 610px;
}

.mx-610 {
  margin-left: 610px;
  margin-right: 610px;
}

.pt-610 {
  padding-top: 610px;
}

.pb-610 {
  padding-bottom: 610px;
}

.pr-610 {
  padding-right: 610px;
}

.pl-610 {
  padding-left: 610px;
}

.px-610 {
  padding-left: 610px;
  padding-right: 610px;
}

.mt-615 {
  margin-top: 615px;
}

.mb-615 {
  margin-bottom: 615px;
}

.mr-615 {
  margin-right: 615px;
}

.ml-615 {
  margin-left: 615px;
}

.mx-615 {
  margin-left: 615px;
  margin-right: 615px;
}

.pt-615 {
  padding-top: 615px;
}

.pb-615 {
  padding-bottom: 615px;
}

.pr-615 {
  padding-right: 615px;
}

.pl-615 {
  padding-left: 615px;
}

.px-615 {
  padding-left: 615px;
  padding-right: 615px;
}

.mt-620 {
  margin-top: 620px;
}

.mb-620 {
  margin-bottom: 620px;
}

.mr-620 {
  margin-right: 620px;
}

.ml-620 {
  margin-left: 620px;
}

.mx-620 {
  margin-left: 620px;
  margin-right: 620px;
}

.pt-620 {
  padding-top: 620px;
}

.pb-620 {
  padding-bottom: 620px;
}

.pr-620 {
  padding-right: 620px;
}

.pl-620 {
  padding-left: 620px;
}

.px-620 {
  padding-left: 620px;
  padding-right: 620px;
}

.mt-625 {
  margin-top: 625px;
}

.mb-625 {
  margin-bottom: 625px;
}

.mr-625 {
  margin-right: 625px;
}

.ml-625 {
  margin-left: 625px;
}

.mx-625 {
  margin-left: 625px;
  margin-right: 625px;
}

.pt-625 {
  padding-top: 625px;
}

.pb-625 {
  padding-bottom: 625px;
}

.pr-625 {
  padding-right: 625px;
}

.pl-625 {
  padding-left: 625px;
}

.px-625 {
  padding-left: 625px;
  padding-right: 625px;
}

.mt-630 {
  margin-top: 630px;
}

.mb-630 {
  margin-bottom: 630px;
}

.mr-630 {
  margin-right: 630px;
}

.ml-630 {
  margin-left: 630px;
}

.mx-630 {
  margin-left: 630px;
  margin-right: 630px;
}

.pt-630 {
  padding-top: 630px;
}

.pb-630 {
  padding-bottom: 630px;
}

.pr-630 {
  padding-right: 630px;
}

.pl-630 {
  padding-left: 630px;
}

.px-630 {
  padding-left: 630px;
  padding-right: 630px;
}

.mt-635 {
  margin-top: 635px;
}

.mb-635 {
  margin-bottom: 635px;
}

.mr-635 {
  margin-right: 635px;
}

.ml-635 {
  margin-left: 635px;
}

.mx-635 {
  margin-left: 635px;
  margin-right: 635px;
}

.pt-635 {
  padding-top: 635px;
}

.pb-635 {
  padding-bottom: 635px;
}

.pr-635 {
  padding-right: 635px;
}

.pl-635 {
  padding-left: 635px;
}

.px-635 {
  padding-left: 635px;
  padding-right: 635px;
}

.mt-640 {
  margin-top: 640px;
}

.mb-640 {
  margin-bottom: 640px;
}

.mr-640 {
  margin-right: 640px;
}

.ml-640 {
  margin-left: 640px;
}

.mx-640 {
  margin-left: 640px;
  margin-right: 640px;
}

.pt-640 {
  padding-top: 640px;
}

.pb-640 {
  padding-bottom: 640px;
}

.pr-640 {
  padding-right: 640px;
}

.pl-640 {
  padding-left: 640px;
}

.px-640 {
  padding-left: 640px;
  padding-right: 640px;
}

.mt-645 {
  margin-top: 645px;
}

.mb-645 {
  margin-bottom: 645px;
}

.mr-645 {
  margin-right: 645px;
}

.ml-645 {
  margin-left: 645px;
}

.mx-645 {
  margin-left: 645px;
  margin-right: 645px;
}

.pt-645 {
  padding-top: 645px;
}

.pb-645 {
  padding-bottom: 645px;
}

.pr-645 {
  padding-right: 645px;
}

.pl-645 {
  padding-left: 645px;
}

.px-645 {
  padding-left: 645px;
  padding-right: 645px;
}

.mt-650 {
  margin-top: 650px;
}

.mb-650 {
  margin-bottom: 650px;
}

.mr-650 {
  margin-right: 650px;
}

.ml-650 {
  margin-left: 650px;
}

.mx-650 {
  margin-left: 650px;
  margin-right: 650px;
}

.pt-650 {
  padding-top: 650px;
}

.pb-650 {
  padding-bottom: 650px;
}

.pr-650 {
  padding-right: 650px;
}

.pl-650 {
  padding-left: 650px;
}

.px-650 {
  padding-left: 650px;
  padding-right: 650px;
}

.mt-655 {
  margin-top: 655px;
}

.mb-655 {
  margin-bottom: 655px;
}

.mr-655 {
  margin-right: 655px;
}

.ml-655 {
  margin-left: 655px;
}

.mx-655 {
  margin-left: 655px;
  margin-right: 655px;
}

.pt-655 {
  padding-top: 655px;
}

.pb-655 {
  padding-bottom: 655px;
}

.pr-655 {
  padding-right: 655px;
}

.pl-655 {
  padding-left: 655px;
}

.px-655 {
  padding-left: 655px;
  padding-right: 655px;
}

.mt-660 {
  margin-top: 660px;
}

.mb-660 {
  margin-bottom: 660px;
}

.mr-660 {
  margin-right: 660px;
}

.ml-660 {
  margin-left: 660px;
}

.mx-660 {
  margin-left: 660px;
  margin-right: 660px;
}

.pt-660 {
  padding-top: 660px;
}

.pb-660 {
  padding-bottom: 660px;
}

.pr-660 {
  padding-right: 660px;
}

.pl-660 {
  padding-left: 660px;
}

.px-660 {
  padding-left: 660px;
  padding-right: 660px;
}

.mt-665 {
  margin-top: 665px;
}

.mb-665 {
  margin-bottom: 665px;
}

.mr-665 {
  margin-right: 665px;
}

.ml-665 {
  margin-left: 665px;
}

.mx-665 {
  margin-left: 665px;
  margin-right: 665px;
}

.pt-665 {
  padding-top: 665px;
}

.pb-665 {
  padding-bottom: 665px;
}

.pr-665 {
  padding-right: 665px;
}

.pl-665 {
  padding-left: 665px;
}

.px-665 {
  padding-left: 665px;
  padding-right: 665px;
}

.mt-670 {
  margin-top: 670px;
}

.mb-670 {
  margin-bottom: 670px;
}

.mr-670 {
  margin-right: 670px;
}

.ml-670 {
  margin-left: 670px;
}

.mx-670 {
  margin-left: 670px;
  margin-right: 670px;
}

.pt-670 {
  padding-top: 670px;
}

.pb-670 {
  padding-bottom: 670px;
}

.pr-670 {
  padding-right: 670px;
}

.pl-670 {
  padding-left: 670px;
}

.px-670 {
  padding-left: 670px;
  padding-right: 670px;
}

.mt-675 {
  margin-top: 675px;
}

.mb-675 {
  margin-bottom: 675px;
}

.mr-675 {
  margin-right: 675px;
}

.ml-675 {
  margin-left: 675px;
}

.mx-675 {
  margin-left: 675px;
  margin-right: 675px;
}

.pt-675 {
  padding-top: 675px;
}

.pb-675 {
  padding-bottom: 675px;
}

.pr-675 {
  padding-right: 675px;
}

.pl-675 {
  padding-left: 675px;
}

.px-675 {
  padding-left: 675px;
  padding-right: 675px;
}

.mt-680 {
  margin-top: 680px;
}

.mb-680 {
  margin-bottom: 680px;
}

.mr-680 {
  margin-right: 680px;
}

.ml-680 {
  margin-left: 680px;
}

.mx-680 {
  margin-left: 680px;
  margin-right: 680px;
}

.pt-680 {
  padding-top: 680px;
}

.pb-680 {
  padding-bottom: 680px;
}

.pr-680 {
  padding-right: 680px;
}

.pl-680 {
  padding-left: 680px;
}

.px-680 {
  padding-left: 680px;
  padding-right: 680px;
}

.mt-685 {
  margin-top: 685px;
}

.mb-685 {
  margin-bottom: 685px;
}

.mr-685 {
  margin-right: 685px;
}

.ml-685 {
  margin-left: 685px;
}

.mx-685 {
  margin-left: 685px;
  margin-right: 685px;
}

.pt-685 {
  padding-top: 685px;
}

.pb-685 {
  padding-bottom: 685px;
}

.pr-685 {
  padding-right: 685px;
}

.pl-685 {
  padding-left: 685px;
}

.px-685 {
  padding-left: 685px;
  padding-right: 685px;
}

.mt-690 {
  margin-top: 690px;
}

.mb-690 {
  margin-bottom: 690px;
}

.mr-690 {
  margin-right: 690px;
}

.ml-690 {
  margin-left: 690px;
}

.mx-690 {
  margin-left: 690px;
  margin-right: 690px;
}

.pt-690 {
  padding-top: 690px;
}

.pb-690 {
  padding-bottom: 690px;
}

.pr-690 {
  padding-right: 690px;
}

.pl-690 {
  padding-left: 690px;
}

.px-690 {
  padding-left: 690px;
  padding-right: 690px;
}

.mt-695 {
  margin-top: 695px;
}

.mb-695 {
  margin-bottom: 695px;
}

.mr-695 {
  margin-right: 695px;
}

.ml-695 {
  margin-left: 695px;
}

.mx-695 {
  margin-left: 695px;
  margin-right: 695px;
}

.pt-695 {
  padding-top: 695px;
}

.pb-695 {
  padding-bottom: 695px;
}

.pr-695 {
  padding-right: 695px;
}

.pl-695 {
  padding-left: 695px;
}

.px-695 {
  padding-left: 695px;
  padding-right: 695px;
}

.mt-700 {
  margin-top: 700px;
}

.mb-700 {
  margin-bottom: 700px;
}

.mr-700 {
  margin-right: 700px;
}

.ml-700 {
  margin-left: 700px;
}

.mx-700 {
  margin-left: 700px;
  margin-right: 700px;
}

.pt-700 {
  padding-top: 700px;
}

.pb-700 {
  padding-bottom: 700px;
}

.pr-700 {
  padding-right: 700px;
}

.pl-700 {
  padding-left: 700px;
}

.px-700 {
  padding-left: 700px;
  padding-right: 700px;
}

.mt-705 {
  margin-top: 705px;
}

.mb-705 {
  margin-bottom: 705px;
}

.mr-705 {
  margin-right: 705px;
}

.ml-705 {
  margin-left: 705px;
}

.mx-705 {
  margin-left: 705px;
  margin-right: 705px;
}

.pt-705 {
  padding-top: 705px;
}

.pb-705 {
  padding-bottom: 705px;
}

.pr-705 {
  padding-right: 705px;
}

.pl-705 {
  padding-left: 705px;
}

.px-705 {
  padding-left: 705px;
  padding-right: 705px;
}

.mt-710 {
  margin-top: 710px;
}

.mb-710 {
  margin-bottom: 710px;
}

.mr-710 {
  margin-right: 710px;
}

.ml-710 {
  margin-left: 710px;
}

.mx-710 {
  margin-left: 710px;
  margin-right: 710px;
}

.pt-710 {
  padding-top: 710px;
}

.pb-710 {
  padding-bottom: 710px;
}

.pr-710 {
  padding-right: 710px;
}

.pl-710 {
  padding-left: 710px;
}

.px-710 {
  padding-left: 710px;
  padding-right: 710px;
}

.mt-715 {
  margin-top: 715px;
}

.mb-715 {
  margin-bottom: 715px;
}

.mr-715 {
  margin-right: 715px;
}

.ml-715 {
  margin-left: 715px;
}

.mx-715 {
  margin-left: 715px;
  margin-right: 715px;
}

.pt-715 {
  padding-top: 715px;
}

.pb-715 {
  padding-bottom: 715px;
}

.pr-715 {
  padding-right: 715px;
}

.pl-715 {
  padding-left: 715px;
}

.px-715 {
  padding-left: 715px;
  padding-right: 715px;
}

.mt-720 {
  margin-top: 720px;
}

.mb-720 {
  margin-bottom: 720px;
}

.mr-720 {
  margin-right: 720px;
}

.ml-720 {
  margin-left: 720px;
}

.mx-720 {
  margin-left: 720px;
  margin-right: 720px;
}

.pt-720 {
  padding-top: 720px;
}

.pb-720 {
  padding-bottom: 720px;
}

.pr-720 {
  padding-right: 720px;
}

.pl-720 {
  padding-left: 720px;
}

.px-720 {
  padding-left: 720px;
  padding-right: 720px;
}

.mt-725 {
  margin-top: 725px;
}

.mb-725 {
  margin-bottom: 725px;
}

.mr-725 {
  margin-right: 725px;
}

.ml-725 {
  margin-left: 725px;
}

.mx-725 {
  margin-left: 725px;
  margin-right: 725px;
}

.pt-725 {
  padding-top: 725px;
}

.pb-725 {
  padding-bottom: 725px;
}

.pr-725 {
  padding-right: 725px;
}

.pl-725 {
  padding-left: 725px;
}

.px-725 {
  padding-left: 725px;
  padding-right: 725px;
}

.mt-730 {
  margin-top: 730px;
}

.mb-730 {
  margin-bottom: 730px;
}

.mr-730 {
  margin-right: 730px;
}

.ml-730 {
  margin-left: 730px;
}

.mx-730 {
  margin-left: 730px;
  margin-right: 730px;
}

.pt-730 {
  padding-top: 730px;
}

.pb-730 {
  padding-bottom: 730px;
}

.pr-730 {
  padding-right: 730px;
}

.pl-730 {
  padding-left: 730px;
}

.px-730 {
  padding-left: 730px;
  padding-right: 730px;
}

.mt-735 {
  margin-top: 735px;
}

.mb-735 {
  margin-bottom: 735px;
}

.mr-735 {
  margin-right: 735px;
}

.ml-735 {
  margin-left: 735px;
}

.mx-735 {
  margin-left: 735px;
  margin-right: 735px;
}

.pt-735 {
  padding-top: 735px;
}

.pb-735 {
  padding-bottom: 735px;
}

.pr-735 {
  padding-right: 735px;
}

.pl-735 {
  padding-left: 735px;
}

.px-735 {
  padding-left: 735px;
  padding-right: 735px;
}

.mt-740 {
  margin-top: 740px;
}

.mb-740 {
  margin-bottom: 740px;
}

.mr-740 {
  margin-right: 740px;
}

.ml-740 {
  margin-left: 740px;
}

.mx-740 {
  margin-left: 740px;
  margin-right: 740px;
}

.pt-740 {
  padding-top: 740px;
}

.pb-740 {
  padding-bottom: 740px;
}

.pr-740 {
  padding-right: 740px;
}

.pl-740 {
  padding-left: 740px;
}

.px-740 {
  padding-left: 740px;
  padding-right: 740px;
}

.mt-745 {
  margin-top: 745px;
}

.mb-745 {
  margin-bottom: 745px;
}

.mr-745 {
  margin-right: 745px;
}

.ml-745 {
  margin-left: 745px;
}

.mx-745 {
  margin-left: 745px;
  margin-right: 745px;
}

.pt-745 {
  padding-top: 745px;
}

.pb-745 {
  padding-bottom: 745px;
}

.pr-745 {
  padding-right: 745px;
}

.pl-745 {
  padding-left: 745px;
}

.px-745 {
  padding-left: 745px;
  padding-right: 745px;
}

.mt-750 {
  margin-top: 750px;
}

.mb-750 {
  margin-bottom: 750px;
}

.mr-750 {
  margin-right: 750px;
}

.ml-750 {
  margin-left: 750px;
}

.mx-750 {
  margin-left: 750px;
  margin-right: 750px;
}

.pt-750 {
  padding-top: 750px;
}

.pb-750 {
  padding-bottom: 750px;
}

.pr-750 {
  padding-right: 750px;
}

.pl-750 {
  padding-left: 750px;
}

.px-750 {
  padding-left: 750px;
  padding-right: 750px;
}

.mt-755 {
  margin-top: 755px;
}

.mb-755 {
  margin-bottom: 755px;
}

.mr-755 {
  margin-right: 755px;
}

.ml-755 {
  margin-left: 755px;
}

.mx-755 {
  margin-left: 755px;
  margin-right: 755px;
}

.pt-755 {
  padding-top: 755px;
}

.pb-755 {
  padding-bottom: 755px;
}

.pr-755 {
  padding-right: 755px;
}

.pl-755 {
  padding-left: 755px;
}

.px-755 {
  padding-left: 755px;
  padding-right: 755px;
}

.mt-760 {
  margin-top: 760px;
}

.mb-760 {
  margin-bottom: 760px;
}

.mr-760 {
  margin-right: 760px;
}

.ml-760 {
  margin-left: 760px;
}

.mx-760 {
  margin-left: 760px;
  margin-right: 760px;
}

.pt-760 {
  padding-top: 760px;
}

.pb-760 {
  padding-bottom: 760px;
}

.pr-760 {
  padding-right: 760px;
}

.pl-760 {
  padding-left: 760px;
}

.px-760 {
  padding-left: 760px;
  padding-right: 760px;
}

.mt-765 {
  margin-top: 765px;
}

.mb-765 {
  margin-bottom: 765px;
}

.mr-765 {
  margin-right: 765px;
}

.ml-765 {
  margin-left: 765px;
}

.mx-765 {
  margin-left: 765px;
  margin-right: 765px;
}

.pt-765 {
  padding-top: 765px;
}

.pb-765 {
  padding-bottom: 765px;
}

.pr-765 {
  padding-right: 765px;
}

.pl-765 {
  padding-left: 765px;
}

.px-765 {
  padding-left: 765px;
  padding-right: 765px;
}

.mt-770 {
  margin-top: 770px;
}

.mb-770 {
  margin-bottom: 770px;
}

.mr-770 {
  margin-right: 770px;
}

.ml-770 {
  margin-left: 770px;
}

.mx-770 {
  margin-left: 770px;
  margin-right: 770px;
}

.pt-770 {
  padding-top: 770px;
}

.pb-770 {
  padding-bottom: 770px;
}

.pr-770 {
  padding-right: 770px;
}

.pl-770 {
  padding-left: 770px;
}

.px-770 {
  padding-left: 770px;
  padding-right: 770px;
}

.mt-775 {
  margin-top: 775px;
}

.mb-775 {
  margin-bottom: 775px;
}

.mr-775 {
  margin-right: 775px;
}

.ml-775 {
  margin-left: 775px;
}

.mx-775 {
  margin-left: 775px;
  margin-right: 775px;
}

.pt-775 {
  padding-top: 775px;
}

.pb-775 {
  padding-bottom: 775px;
}

.pr-775 {
  padding-right: 775px;
}

.pl-775 {
  padding-left: 775px;
}

.px-775 {
  padding-left: 775px;
  padding-right: 775px;
}

.mt-780 {
  margin-top: 780px;
}

.mb-780 {
  margin-bottom: 780px;
}

.mr-780 {
  margin-right: 780px;
}

.ml-780 {
  margin-left: 780px;
}

.mx-780 {
  margin-left: 780px;
  margin-right: 780px;
}

.pt-780 {
  padding-top: 780px;
}

.pb-780 {
  padding-bottom: 780px;
}

.pr-780 {
  padding-right: 780px;
}

.pl-780 {
  padding-left: 780px;
}

.px-780 {
  padding-left: 780px;
  padding-right: 780px;
}

.mt-785 {
  margin-top: 785px;
}

.mb-785 {
  margin-bottom: 785px;
}

.mr-785 {
  margin-right: 785px;
}

.ml-785 {
  margin-left: 785px;
}

.mx-785 {
  margin-left: 785px;
  margin-right: 785px;
}

.pt-785 {
  padding-top: 785px;
}

.pb-785 {
  padding-bottom: 785px;
}

.pr-785 {
  padding-right: 785px;
}

.pl-785 {
  padding-left: 785px;
}

.px-785 {
  padding-left: 785px;
  padding-right: 785px;
}

.mt-790 {
  margin-top: 790px;
}

.mb-790 {
  margin-bottom: 790px;
}

.mr-790 {
  margin-right: 790px;
}

.ml-790 {
  margin-left: 790px;
}

.mx-790 {
  margin-left: 790px;
  margin-right: 790px;
}

.pt-790 {
  padding-top: 790px;
}

.pb-790 {
  padding-bottom: 790px;
}

.pr-790 {
  padding-right: 790px;
}

.pl-790 {
  padding-left: 790px;
}

.px-790 {
  padding-left: 790px;
  padding-right: 790px;
}

.mt-795 {
  margin-top: 795px;
}

.mb-795 {
  margin-bottom: 795px;
}

.mr-795 {
  margin-right: 795px;
}

.ml-795 {
  margin-left: 795px;
}

.mx-795 {
  margin-left: 795px;
  margin-right: 795px;
}

.pt-795 {
  padding-top: 795px;
}

.pb-795 {
  padding-bottom: 795px;
}

.pr-795 {
  padding-right: 795px;
}

.pl-795 {
  padding-left: 795px;
}

.px-795 {
  padding-left: 795px;
  padding-right: 795px;
}

.mt-800 {
  margin-top: 800px;
}

.mb-800 {
  margin-bottom: 800px;
}

.mr-800 {
  margin-right: 800px;
}

.ml-800 {
  margin-left: 800px;
}

.mx-800 {
  margin-left: 800px;
  margin-right: 800px;
}

.pt-800 {
  padding-top: 800px;
}

.pb-800 {
  padding-bottom: 800px;
}

.pr-800 {
  padding-right: 800px;
}

.pl-800 {
  padding-left: 800px;
}

.px-800 {
  padding-left: 800px;
  padding-right: 800px;
}

.mt-805 {
  margin-top: 805px;
}

.mb-805 {
  margin-bottom: 805px;
}

.mr-805 {
  margin-right: 805px;
}

.ml-805 {
  margin-left: 805px;
}

.mx-805 {
  margin-left: 805px;
  margin-right: 805px;
}

.pt-805 {
  padding-top: 805px;
}

.pb-805 {
  padding-bottom: 805px;
}

.pr-805 {
  padding-right: 805px;
}

.pl-805 {
  padding-left: 805px;
}

.px-805 {
  padding-left: 805px;
  padding-right: 805px;
}

.mt-810 {
  margin-top: 810px;
}

.mb-810 {
  margin-bottom: 810px;
}

.mr-810 {
  margin-right: 810px;
}

.ml-810 {
  margin-left: 810px;
}

.mx-810 {
  margin-left: 810px;
  margin-right: 810px;
}

.pt-810 {
  padding-top: 810px;
}

.pb-810 {
  padding-bottom: 810px;
}

.pr-810 {
  padding-right: 810px;
}

.pl-810 {
  padding-left: 810px;
}

.px-810 {
  padding-left: 810px;
  padding-right: 810px;
}

.mt-815 {
  margin-top: 815px;
}

.mb-815 {
  margin-bottom: 815px;
}

.mr-815 {
  margin-right: 815px;
}

.ml-815 {
  margin-left: 815px;
}

.mx-815 {
  margin-left: 815px;
  margin-right: 815px;
}

.pt-815 {
  padding-top: 815px;
}

.pb-815 {
  padding-bottom: 815px;
}

.pr-815 {
  padding-right: 815px;
}

.pl-815 {
  padding-left: 815px;
}

.px-815 {
  padding-left: 815px;
  padding-right: 815px;
}

.mt-820 {
  margin-top: 820px;
}

.mb-820 {
  margin-bottom: 820px;
}

.mr-820 {
  margin-right: 820px;
}

.ml-820 {
  margin-left: 820px;
}

.mx-820 {
  margin-left: 820px;
  margin-right: 820px;
}

.pt-820 {
  padding-top: 820px;
}

.pb-820 {
  padding-bottom: 820px;
}

.pr-820 {
  padding-right: 820px;
}

.pl-820 {
  padding-left: 820px;
}

.px-820 {
  padding-left: 820px;
  padding-right: 820px;
}

.mt-825 {
  margin-top: 825px;
}

.mb-825 {
  margin-bottom: 825px;
}

.mr-825 {
  margin-right: 825px;
}

.ml-825 {
  margin-left: 825px;
}

.mx-825 {
  margin-left: 825px;
  margin-right: 825px;
}

.pt-825 {
  padding-top: 825px;
}

.pb-825 {
  padding-bottom: 825px;
}

.pr-825 {
  padding-right: 825px;
}

.pl-825 {
  padding-left: 825px;
}

.px-825 {
  padding-left: 825px;
  padding-right: 825px;
}

.mt-830 {
  margin-top: 830px;
}

.mb-830 {
  margin-bottom: 830px;
}

.mr-830 {
  margin-right: 830px;
}

.ml-830 {
  margin-left: 830px;
}

.mx-830 {
  margin-left: 830px;
  margin-right: 830px;
}

.pt-830 {
  padding-top: 830px;
}

.pb-830 {
  padding-bottom: 830px;
}

.pr-830 {
  padding-right: 830px;
}

.pl-830 {
  padding-left: 830px;
}

.px-830 {
  padding-left: 830px;
  padding-right: 830px;
}

.mt-835 {
  margin-top: 835px;
}

.mb-835 {
  margin-bottom: 835px;
}

.mr-835 {
  margin-right: 835px;
}

.ml-835 {
  margin-left: 835px;
}

.mx-835 {
  margin-left: 835px;
  margin-right: 835px;
}

.pt-835 {
  padding-top: 835px;
}

.pb-835 {
  padding-bottom: 835px;
}

.pr-835 {
  padding-right: 835px;
}

.pl-835 {
  padding-left: 835px;
}

.px-835 {
  padding-left: 835px;
  padding-right: 835px;
}

.mt-840 {
  margin-top: 840px;
}

.mb-840 {
  margin-bottom: 840px;
}

.mr-840 {
  margin-right: 840px;
}

.ml-840 {
  margin-left: 840px;
}

.mx-840 {
  margin-left: 840px;
  margin-right: 840px;
}

.pt-840 {
  padding-top: 840px;
}

.pb-840 {
  padding-bottom: 840px;
}

.pr-840 {
  padding-right: 840px;
}

.pl-840 {
  padding-left: 840px;
}

.px-840 {
  padding-left: 840px;
  padding-right: 840px;
}

.mt-845 {
  margin-top: 845px;
}

.mb-845 {
  margin-bottom: 845px;
}

.mr-845 {
  margin-right: 845px;
}

.ml-845 {
  margin-left: 845px;
}

.mx-845 {
  margin-left: 845px;
  margin-right: 845px;
}

.pt-845 {
  padding-top: 845px;
}

.pb-845 {
  padding-bottom: 845px;
}

.pr-845 {
  padding-right: 845px;
}

.pl-845 {
  padding-left: 845px;
}

.px-845 {
  padding-left: 845px;
  padding-right: 845px;
}

.mt-850 {
  margin-top: 850px;
}

.mb-850 {
  margin-bottom: 850px;
}

.mr-850 {
  margin-right: 850px;
}

.ml-850 {
  margin-left: 850px;
}

.mx-850 {
  margin-left: 850px;
  margin-right: 850px;
}

.pt-850 {
  padding-top: 850px;
}

.pb-850 {
  padding-bottom: 850px;
}

.pr-850 {
  padding-right: 850px;
}

.pl-850 {
  padding-left: 850px;
}

.px-850 {
  padding-left: 850px;
  padding-right: 850px;
}

.mt-855 {
  margin-top: 855px;
}

.mb-855 {
  margin-bottom: 855px;
}

.mr-855 {
  margin-right: 855px;
}

.ml-855 {
  margin-left: 855px;
}

.mx-855 {
  margin-left: 855px;
  margin-right: 855px;
}

.pt-855 {
  padding-top: 855px;
}

.pb-855 {
  padding-bottom: 855px;
}

.pr-855 {
  padding-right: 855px;
}

.pl-855 {
  padding-left: 855px;
}

.px-855 {
  padding-left: 855px;
  padding-right: 855px;
}

.mt-860 {
  margin-top: 860px;
}

.mb-860 {
  margin-bottom: 860px;
}

.mr-860 {
  margin-right: 860px;
}

.ml-860 {
  margin-left: 860px;
}

.mx-860 {
  margin-left: 860px;
  margin-right: 860px;
}

.pt-860 {
  padding-top: 860px;
}

.pb-860 {
  padding-bottom: 860px;
}

.pr-860 {
  padding-right: 860px;
}

.pl-860 {
  padding-left: 860px;
}

.px-860 {
  padding-left: 860px;
  padding-right: 860px;
}

.mt-865 {
  margin-top: 865px;
}

.mb-865 {
  margin-bottom: 865px;
}

.mr-865 {
  margin-right: 865px;
}

.ml-865 {
  margin-left: 865px;
}

.mx-865 {
  margin-left: 865px;
  margin-right: 865px;
}

.pt-865 {
  padding-top: 865px;
}

.pb-865 {
  padding-bottom: 865px;
}

.pr-865 {
  padding-right: 865px;
}

.pl-865 {
  padding-left: 865px;
}

.px-865 {
  padding-left: 865px;
  padding-right: 865px;
}

.mt-870 {
  margin-top: 870px;
}

.mb-870 {
  margin-bottom: 870px;
}

.mr-870 {
  margin-right: 870px;
}

.ml-870 {
  margin-left: 870px;
}

.mx-870 {
  margin-left: 870px;
  margin-right: 870px;
}

.pt-870 {
  padding-top: 870px;
}

.pb-870 {
  padding-bottom: 870px;
}

.pr-870 {
  padding-right: 870px;
}

.pl-870 {
  padding-left: 870px;
}

.px-870 {
  padding-left: 870px;
  padding-right: 870px;
}

.mt-875 {
  margin-top: 875px;
}

.mb-875 {
  margin-bottom: 875px;
}

.mr-875 {
  margin-right: 875px;
}

.ml-875 {
  margin-left: 875px;
}

.mx-875 {
  margin-left: 875px;
  margin-right: 875px;
}

.pt-875 {
  padding-top: 875px;
}

.pb-875 {
  padding-bottom: 875px;
}

.pr-875 {
  padding-right: 875px;
}

.pl-875 {
  padding-left: 875px;
}

.px-875 {
  padding-left: 875px;
  padding-right: 875px;
}

.mt-880 {
  margin-top: 880px;
}

.mb-880 {
  margin-bottom: 880px;
}

.mr-880 {
  margin-right: 880px;
}

.ml-880 {
  margin-left: 880px;
}

.mx-880 {
  margin-left: 880px;
  margin-right: 880px;
}

.pt-880 {
  padding-top: 880px;
}

.pb-880 {
  padding-bottom: 880px;
}

.pr-880 {
  padding-right: 880px;
}

.pl-880 {
  padding-left: 880px;
}

.px-880 {
  padding-left: 880px;
  padding-right: 880px;
}

.mt-885 {
  margin-top: 885px;
}

.mb-885 {
  margin-bottom: 885px;
}

.mr-885 {
  margin-right: 885px;
}

.ml-885 {
  margin-left: 885px;
}

.mx-885 {
  margin-left: 885px;
  margin-right: 885px;
}

.pt-885 {
  padding-top: 885px;
}

.pb-885 {
  padding-bottom: 885px;
}

.pr-885 {
  padding-right: 885px;
}

.pl-885 {
  padding-left: 885px;
}

.px-885 {
  padding-left: 885px;
  padding-right: 885px;
}

.mt-890 {
  margin-top: 890px;
}

.mb-890 {
  margin-bottom: 890px;
}

.mr-890 {
  margin-right: 890px;
}

.ml-890 {
  margin-left: 890px;
}

.mx-890 {
  margin-left: 890px;
  margin-right: 890px;
}

.pt-890 {
  padding-top: 890px;
}

.pb-890 {
  padding-bottom: 890px;
}

.pr-890 {
  padding-right: 890px;
}

.pl-890 {
  padding-left: 890px;
}

.px-890 {
  padding-left: 890px;
  padding-right: 890px;
}

.mt-895 {
  margin-top: 895px;
}

.mb-895 {
  margin-bottom: 895px;
}

.mr-895 {
  margin-right: 895px;
}

.ml-895 {
  margin-left: 895px;
}

.mx-895 {
  margin-left: 895px;
  margin-right: 895px;
}

.pt-895 {
  padding-top: 895px;
}

.pb-895 {
  padding-bottom: 895px;
}

.pr-895 {
  padding-right: 895px;
}

.pl-895 {
  padding-left: 895px;
}

.px-895 {
  padding-left: 895px;
  padding-right: 895px;
}

.mt-900 {
  margin-top: 900px;
}

.mb-900 {
  margin-bottom: 900px;
}

.mr-900 {
  margin-right: 900px;
}

.ml-900 {
  margin-left: 900px;
}

.mx-900 {
  margin-left: 900px;
  margin-right: 900px;
}

.pt-900 {
  padding-top: 900px;
}

.pb-900 {
  padding-bottom: 900px;
}

.pr-900 {
  padding-right: 900px;
}

.pl-900 {
  padding-left: 900px;
}

.px-900 {
  padding-left: 900px;
  padding-right: 900px;
}

.mt-905 {
  margin-top: 905px;
}

.mb-905 {
  margin-bottom: 905px;
}

.mr-905 {
  margin-right: 905px;
}

.ml-905 {
  margin-left: 905px;
}

.mx-905 {
  margin-left: 905px;
  margin-right: 905px;
}

.pt-905 {
  padding-top: 905px;
}

.pb-905 {
  padding-bottom: 905px;
}

.pr-905 {
  padding-right: 905px;
}

.pl-905 {
  padding-left: 905px;
}

.px-905 {
  padding-left: 905px;
  padding-right: 905px;
}

.mt-910 {
  margin-top: 910px;
}

.mb-910 {
  margin-bottom: 910px;
}

.mr-910 {
  margin-right: 910px;
}

.ml-910 {
  margin-left: 910px;
}

.mx-910 {
  margin-left: 910px;
  margin-right: 910px;
}

.pt-910 {
  padding-top: 910px;
}

.pb-910 {
  padding-bottom: 910px;
}

.pr-910 {
  padding-right: 910px;
}

.pl-910 {
  padding-left: 910px;
}

.px-910 {
  padding-left: 910px;
  padding-right: 910px;
}

.mt-915 {
  margin-top: 915px;
}

.mb-915 {
  margin-bottom: 915px;
}

.mr-915 {
  margin-right: 915px;
}

.ml-915 {
  margin-left: 915px;
}

.mx-915 {
  margin-left: 915px;
  margin-right: 915px;
}

.pt-915 {
  padding-top: 915px;
}

.pb-915 {
  padding-bottom: 915px;
}

.pr-915 {
  padding-right: 915px;
}

.pl-915 {
  padding-left: 915px;
}

.px-915 {
  padding-left: 915px;
  padding-right: 915px;
}

.mt-920 {
  margin-top: 920px;
}

.mb-920 {
  margin-bottom: 920px;
}

.mr-920 {
  margin-right: 920px;
}

.ml-920 {
  margin-left: 920px;
}

.mx-920 {
  margin-left: 920px;
  margin-right: 920px;
}

.pt-920 {
  padding-top: 920px;
}

.pb-920 {
  padding-bottom: 920px;
}

.pr-920 {
  padding-right: 920px;
}

.pl-920 {
  padding-left: 920px;
}

.px-920 {
  padding-left: 920px;
  padding-right: 920px;
}

.mt-925 {
  margin-top: 925px;
}

.mb-925 {
  margin-bottom: 925px;
}

.mr-925 {
  margin-right: 925px;
}

.ml-925 {
  margin-left: 925px;
}

.mx-925 {
  margin-left: 925px;
  margin-right: 925px;
}

.pt-925 {
  padding-top: 925px;
}

.pb-925 {
  padding-bottom: 925px;
}

.pr-925 {
  padding-right: 925px;
}

.pl-925 {
  padding-left: 925px;
}

.px-925 {
  padding-left: 925px;
  padding-right: 925px;
}

.mt-930 {
  margin-top: 930px;
}

.mb-930 {
  margin-bottom: 930px;
}

.mr-930 {
  margin-right: 930px;
}

.ml-930 {
  margin-left: 930px;
}

.mx-930 {
  margin-left: 930px;
  margin-right: 930px;
}

.pt-930 {
  padding-top: 930px;
}

.pb-930 {
  padding-bottom: 930px;
}

.pr-930 {
  padding-right: 930px;
}

.pl-930 {
  padding-left: 930px;
}

.px-930 {
  padding-left: 930px;
  padding-right: 930px;
}

.mt-935 {
  margin-top: 935px;
}

.mb-935 {
  margin-bottom: 935px;
}

.mr-935 {
  margin-right: 935px;
}

.ml-935 {
  margin-left: 935px;
}

.mx-935 {
  margin-left: 935px;
  margin-right: 935px;
}

.pt-935 {
  padding-top: 935px;
}

.pb-935 {
  padding-bottom: 935px;
}

.pr-935 {
  padding-right: 935px;
}

.pl-935 {
  padding-left: 935px;
}

.px-935 {
  padding-left: 935px;
  padding-right: 935px;
}

.mt-940 {
  margin-top: 940px;
}

.mb-940 {
  margin-bottom: 940px;
}

.mr-940 {
  margin-right: 940px;
}

.ml-940 {
  margin-left: 940px;
}

.mx-940 {
  margin-left: 940px;
  margin-right: 940px;
}

.pt-940 {
  padding-top: 940px;
}

.pb-940 {
  padding-bottom: 940px;
}

.pr-940 {
  padding-right: 940px;
}

.pl-940 {
  padding-left: 940px;
}

.px-940 {
  padding-left: 940px;
  padding-right: 940px;
}

.mt-945 {
  margin-top: 945px;
}

.mb-945 {
  margin-bottom: 945px;
}

.mr-945 {
  margin-right: 945px;
}

.ml-945 {
  margin-left: 945px;
}

.mx-945 {
  margin-left: 945px;
  margin-right: 945px;
}

.pt-945 {
  padding-top: 945px;
}

.pb-945 {
  padding-bottom: 945px;
}

.pr-945 {
  padding-right: 945px;
}

.pl-945 {
  padding-left: 945px;
}

.px-945 {
  padding-left: 945px;
  padding-right: 945px;
}

.mt-950 {
  margin-top: 950px;
}

.mb-950 {
  margin-bottom: 950px;
}

.mr-950 {
  margin-right: 950px;
}

.ml-950 {
  margin-left: 950px;
}

.mx-950 {
  margin-left: 950px;
  margin-right: 950px;
}

.pt-950 {
  padding-top: 950px;
}

.pb-950 {
  padding-bottom: 950px;
}

.pr-950 {
  padding-right: 950px;
}

.pl-950 {
  padding-left: 950px;
}

.px-950 {
  padding-left: 950px;
  padding-right: 950px;
}

.mt-955 {
  margin-top: 955px;
}

.mb-955 {
  margin-bottom: 955px;
}

.mr-955 {
  margin-right: 955px;
}

.ml-955 {
  margin-left: 955px;
}

.mx-955 {
  margin-left: 955px;
  margin-right: 955px;
}

.pt-955 {
  padding-top: 955px;
}

.pb-955 {
  padding-bottom: 955px;
}

.pr-955 {
  padding-right: 955px;
}

.pl-955 {
  padding-left: 955px;
}

.px-955 {
  padding-left: 955px;
  padding-right: 955px;
}

.mt-960 {
  margin-top: 960px;
}

.mb-960 {
  margin-bottom: 960px;
}

.mr-960 {
  margin-right: 960px;
}

.ml-960 {
  margin-left: 960px;
}

.mx-960 {
  margin-left: 960px;
  margin-right: 960px;
}

.pt-960 {
  padding-top: 960px;
}

.pb-960 {
  padding-bottom: 960px;
}

.pr-960 {
  padding-right: 960px;
}

.pl-960 {
  padding-left: 960px;
}

.px-960 {
  padding-left: 960px;
  padding-right: 960px;
}

.mt-965 {
  margin-top: 965px;
}

.mb-965 {
  margin-bottom: 965px;
}

.mr-965 {
  margin-right: 965px;
}

.ml-965 {
  margin-left: 965px;
}

.mx-965 {
  margin-left: 965px;
  margin-right: 965px;
}

.pt-965 {
  padding-top: 965px;
}

.pb-965 {
  padding-bottom: 965px;
}

.pr-965 {
  padding-right: 965px;
}

.pl-965 {
  padding-left: 965px;
}

.px-965 {
  padding-left: 965px;
  padding-right: 965px;
}

.mt-970 {
  margin-top: 970px;
}

.mb-970 {
  margin-bottom: 970px;
}

.mr-970 {
  margin-right: 970px;
}

.ml-970 {
  margin-left: 970px;
}

.mx-970 {
  margin-left: 970px;
  margin-right: 970px;
}

.pt-970 {
  padding-top: 970px;
}

.pb-970 {
  padding-bottom: 970px;
}

.pr-970 {
  padding-right: 970px;
}

.pl-970 {
  padding-left: 970px;
}

.px-970 {
  padding-left: 970px;
  padding-right: 970px;
}

.mt-975 {
  margin-top: 975px;
}

.mb-975 {
  margin-bottom: 975px;
}

.mr-975 {
  margin-right: 975px;
}

.ml-975 {
  margin-left: 975px;
}

.mx-975 {
  margin-left: 975px;
  margin-right: 975px;
}

.pt-975 {
  padding-top: 975px;
}

.pb-975 {
  padding-bottom: 975px;
}

.pr-975 {
  padding-right: 975px;
}

.pl-975 {
  padding-left: 975px;
}

.px-975 {
  padding-left: 975px;
  padding-right: 975px;
}

.mt-980 {
  margin-top: 980px;
}

.mb-980 {
  margin-bottom: 980px;
}

.mr-980 {
  margin-right: 980px;
}

.ml-980 {
  margin-left: 980px;
}

.mx-980 {
  margin-left: 980px;
  margin-right: 980px;
}

.pt-980 {
  padding-top: 980px;
}

.pb-980 {
  padding-bottom: 980px;
}

.pr-980 {
  padding-right: 980px;
}

.pl-980 {
  padding-left: 980px;
}

.px-980 {
  padding-left: 980px;
  padding-right: 980px;
}

.mt-985 {
  margin-top: 985px;
}

.mb-985 {
  margin-bottom: 985px;
}

.mr-985 {
  margin-right: 985px;
}

.ml-985 {
  margin-left: 985px;
}

.mx-985 {
  margin-left: 985px;
  margin-right: 985px;
}

.pt-985 {
  padding-top: 985px;
}

.pb-985 {
  padding-bottom: 985px;
}

.pr-985 {
  padding-right: 985px;
}

.pl-985 {
  padding-left: 985px;
}

.px-985 {
  padding-left: 985px;
  padding-right: 985px;
}

.mt-990 {
  margin-top: 990px;
}

.mb-990 {
  margin-bottom: 990px;
}

.mr-990 {
  margin-right: 990px;
}

.ml-990 {
  margin-left: 990px;
}

.mx-990 {
  margin-left: 990px;
  margin-right: 990px;
}

.pt-990 {
  padding-top: 990px;
}

.pb-990 {
  padding-bottom: 990px;
}

.pr-990 {
  padding-right: 990px;
}

.pl-990 {
  padding-left: 990px;
}

.px-990 {
  padding-left: 990px;
  padding-right: 990px;
}

.mt-995 {
  margin-top: 995px;
}

.mb-995 {
  margin-bottom: 995px;
}

.mr-995 {
  margin-right: 995px;
}

.ml-995 {
  margin-left: 995px;
}

.mx-995 {
  margin-left: 995px;
  margin-right: 995px;
}

.pt-995 {
  padding-top: 995px;
}

.pb-995 {
  padding-bottom: 995px;
}

.pr-995 {
  padding-right: 995px;
}

.pl-995 {
  padding-left: 995px;
}

.px-995 {
  padding-left: 995px;
  padding-right: 995px;
}

.mt-1000 {
  margin-top: 1000px;
}

.mb-1000 {
  margin-bottom: 1000px;
}

.mr-1000 {
  margin-right: 1000px;
}

.ml-1000 {
  margin-left: 1000px;
}

.mx-1000 {
  margin-left: 1000px;
  margin-right: 1000px;
}

.pt-1000 {
  padding-top: 1000px;
}

.pb-1000 {
  padding-bottom: 1000px;
}

.pr-1000 {
  padding-right: 1000px;
}

.pl-1000 {
  padding-left: 1000px;
}

.px-1000 {
  padding-left: 1000px;
  padding-right: 1000px;
}

/* width, max-width, height, max-height */
.w-0 {
  width: 0px;
}

.mw-0 {
  max-width: 0px;
}

.h-0 {
  height: 0px;
}

.mh-0 {
  max-height: 0px;
}

.w-10 {
  width: 10px;
}

.mw-10 {
  max-width: 10px;
}

.h-10 {
  height: 10px;
}

.mh-10 {
  max-height: 10px;
}

.w-20 {
  width: 20px;
}

.mw-20 {
  max-width: 20px;
}

.h-20 {
  height: 20px;
}

.mh-20 {
  max-height: 20px;
}

.w-30 {
  width: 30px;
}

.mw-30 {
  max-width: 30px;
}

.h-30 {
  height: 30px;
}

.mh-30 {
  max-height: 30px;
}

.w-40 {
  width: 40px;
}

.mw-40 {
  max-width: 40px;
}

.h-40 {
  height: 40px;
}

.mh-40 {
  max-height: 40px;
}

.w-50 {
  width: 50px;
}

.mw-50 {
  max-width: 50px;
}

.h-50 {
  height: 50px;
}

.mh-50 {
  max-height: 50px;
}

.w-60 {
  width: 60px;
}

.mw-60 {
  max-width: 60px;
}

.h-60 {
  height: 60px;
}

.mh-60 {
  max-height: 60px;
}

.w-70 {
  width: 70px;
}

.mw-70 {
  max-width: 70px;
}

.h-70 {
  height: 70px;
}

.mh-70 {
  max-height: 70px;
}

.w-80 {
  width: 80px;
}

.mw-80 {
  max-width: 80px;
}

.h-80 {
  height: 80px;
}

.mh-80 {
  max-height: 80px;
}

.w-90 {
  width: 90px;
}

.mw-90 {
  max-width: 90px;
}

.h-90 {
  height: 90px;
}

.mh-90 {
  max-height: 90px;
}

.w-100 {
  width: 100px;
}

.mw-100 {
  max-width: 100px;
}

.h-100 {
  height: 100px;
}

.mh-100 {
  max-height: 100px;
}

.w-110 {
  width: 110px;
}

.mw-110 {
  max-width: 110px;
}

.h-110 {
  height: 110px;
}

.mh-110 {
  max-height: 110px;
}

.w-120 {
  width: 120px;
}

.mw-120 {
  max-width: 120px;
}

.h-120 {
  height: 120px;
}

.mh-120 {
  max-height: 120px;
}

.w-130 {
  width: 130px;
}

.mw-130 {
  max-width: 130px;
}

.h-130 {
  height: 130px;
}

.mh-130 {
  max-height: 130px;
}

.w-140 {
  width: 140px;
}

.mw-140 {
  max-width: 140px;
}

.h-140 {
  height: 140px;
}

.mh-140 {
  max-height: 140px;
}

.w-150 {
  width: 150px;
}

.mw-150 {
  max-width: 150px;
}

.h-150 {
  height: 150px;
}

.mh-150 {
  max-height: 150px;
}

.w-160 {
  width: 160px;
}

.mw-160 {
  max-width: 160px;
}

.h-160 {
  height: 160px;
}

.mh-160 {
  max-height: 160px;
}

.w-170 {
  width: 170px;
}

.mw-170 {
  max-width: 170px;
}

.h-170 {
  height: 170px;
}

.mh-170 {
  max-height: 170px;
}

.w-180 {
  width: 180px;
}

.mw-180 {
  max-width: 180px;
}

.h-180 {
  height: 180px;
}

.mh-180 {
  max-height: 180px;
}

.w-190 {
  width: 190px;
}

.mw-190 {
  max-width: 190px;
}

.h-190 {
  height: 190px;
}

.mh-190 {
  max-height: 190px;
}

.w-200 {
  width: 200px;
}

.mw-200 {
  max-width: 200px;
}

.h-200 {
  height: 200px;
}

.mh-200 {
  max-height: 200px;
}

.w-210 {
  width: 210px;
}

.mw-210 {
  max-width: 210px;
}

.h-210 {
  height: 210px;
}

.mh-210 {
  max-height: 210px;
}

.w-220 {
  width: 220px;
}

.mw-220 {
  max-width: 220px;
}

.h-220 {
  height: 220px;
}

.mh-220 {
  max-height: 220px;
}

.w-230 {
  width: 230px;
}

.mw-230 {
  max-width: 230px;
}

.h-230 {
  height: 230px;
}

.mh-230 {
  max-height: 230px;
}

.w-240 {
  width: 240px;
}

.mw-240 {
  max-width: 240px;
}

.h-240 {
  height: 240px;
}

.mh-240 {
  max-height: 240px;
}

.w-250 {
  width: 250px;
}

.mw-250 {
  max-width: 250px;
}

.h-250 {
  height: 250px;
}

.mh-250 {
  max-height: 250px;
}

.w-260 {
  width: 260px;
}

.mw-260 {
  max-width: 260px;
}

.h-260 {
  height: 260px;
}

.mh-260 {
  max-height: 260px;
}

.w-270 {
  width: 270px;
}

.mw-270 {
  max-width: 270px;
}

.h-270 {
  height: 270px;
}

.mh-270 {
  max-height: 270px;
}

.w-280 {
  width: 280px;
}

.mw-280 {
  max-width: 280px;
}

.h-280 {
  height: 280px;
}

.mh-280 {
  max-height: 280px;
}

.w-290 {
  width: 290px;
}

.mw-290 {
  max-width: 290px;
}

.h-290 {
  height: 290px;
}

.mh-290 {
  max-height: 290px;
}

.w-300 {
  width: 300px;
}

.mw-300 {
  max-width: 300px;
}

.h-300 {
  height: 300px;
}

.mh-300 {
  max-height: 300px;
}

.w-310 {
  width: 310px;
}

.mw-310 {
  max-width: 310px;
}

.h-310 {
  height: 310px;
}

.mh-310 {
  max-height: 310px;
}

.w-320 {
  width: 320px;
}

.mw-320 {
  max-width: 320px;
}

.h-320 {
  height: 320px;
}

.mh-320 {
  max-height: 320px;
}

.w-330 {
  width: 330px;
}

.mw-330 {
  max-width: 330px;
}

.h-330 {
  height: 330px;
}

.mh-330 {
  max-height: 330px;
}

.w-340 {
  width: 340px;
}

.mw-340 {
  max-width: 340px;
}

.h-340 {
  height: 340px;
}

.mh-340 {
  max-height: 340px;
}

.w-350 {
  width: 350px;
}

.mw-350 {
  max-width: 350px;
}

.h-350 {
  height: 350px;
}

.mh-350 {
  max-height: 350px;
}

.w-360 {
  width: 360px;
}

.mw-360 {
  max-width: 360px;
}

.h-360 {
  height: 360px;
}

.mh-360 {
  max-height: 360px;
}

.w-370 {
  width: 370px;
}

.mw-370 {
  max-width: 370px;
}

.h-370 {
  height: 370px;
}

.mh-370 {
  max-height: 370px;
}

.w-380 {
  width: 380px;
}

.mw-380 {
  max-width: 380px;
}

.h-380 {
  height: 380px;
}

.mh-380 {
  max-height: 380px;
}

.w-390 {
  width: 390px;
}

.mw-390 {
  max-width: 390px;
}

.h-390 {
  height: 390px;
}

.mh-390 {
  max-height: 390px;
}

.w-400 {
  width: 400px;
}

.mw-400 {
  max-width: 400px;
}

.h-400 {
  height: 400px;
}

.mh-400 {
  max-height: 400px;
}

.w-410 {
  width: 410px;
}

.mw-410 {
  max-width: 410px;
}

.h-410 {
  height: 410px;
}

.mh-410 {
  max-height: 410px;
}

.w-420 {
  width: 420px;
}

.mw-420 {
  max-width: 420px;
}

.h-420 {
  height: 420px;
}

.mh-420 {
  max-height: 420px;
}

.w-430 {
  width: 430px;
}

.mw-430 {
  max-width: 430px;
}

.h-430 {
  height: 430px;
}

.mh-430 {
  max-height: 430px;
}

.w-440 {
  width: 440px;
}

.mw-440 {
  max-width: 440px;
}

.h-440 {
  height: 440px;
}

.mh-440 {
  max-height: 440px;
}

.w-450 {
  width: 450px;
}

.mw-450 {
  max-width: 450px;
}

.h-450 {
  height: 450px;
}

.mh-450 {
  max-height: 450px;
}

.w-460 {
  width: 460px;
}

.mw-460 {
  max-width: 460px;
}

.h-460 {
  height: 460px;
}

.mh-460 {
  max-height: 460px;
}

.w-470 {
  width: 470px;
}

.mw-470 {
  max-width: 470px;
}

.h-470 {
  height: 470px;
}

.mh-470 {
  max-height: 470px;
}

.w-480 {
  width: 480px;
}

.mw-480 {
  max-width: 480px;
}

.h-480 {
  height: 480px;
}

.mh-480 {
  max-height: 480px;
}

.w-490 {
  width: 490px;
}

.mw-490 {
  max-width: 490px;
}

.h-490 {
  height: 490px;
}

.mh-490 {
  max-height: 490px;
}

.w-500 {
  width: 500px;
}

.mw-500 {
  max-width: 500px;
}

.h-500 {
  height: 500px;
}

.mh-500 {
  max-height: 500px;
}

.w-510 {
  width: 510px;
}

.mw-510 {
  max-width: 510px;
}

.h-510 {
  height: 510px;
}

.mh-510 {
  max-height: 510px;
}

.w-520 {
  width: 520px;
}

.mw-520 {
  max-width: 520px;
}

.h-520 {
  height: 520px;
}

.mh-520 {
  max-height: 520px;
}

.w-530 {
  width: 530px;
}

.mw-530 {
  max-width: 530px;
}

.h-530 {
  height: 530px;
}

.mh-530 {
  max-height: 530px;
}

.w-540 {
  width: 540px;
}

.mw-540 {
  max-width: 540px;
}

.h-540 {
  height: 540px;
}

.mh-540 {
  max-height: 540px;
}

.w-550 {
  width: 550px;
}

.mw-550 {
  max-width: 550px;
}

.h-550 {
  height: 550px;
}

.mh-550 {
  max-height: 550px;
}

.w-560 {
  width: 560px;
}

.mw-560 {
  max-width: 560px;
}

.h-560 {
  height: 560px;
}

.mh-560 {
  max-height: 560px;
}

.w-570 {
  width: 570px;
}

.mw-570 {
  max-width: 570px;
}

.h-570 {
  height: 570px;
}

.mh-570 {
  max-height: 570px;
}

.w-580 {
  width: 580px;
}

.mw-580 {
  max-width: 580px;
}

.h-580 {
  height: 580px;
}

.mh-580 {
  max-height: 580px;
}

.w-590 {
  width: 590px;
}

.mw-590 {
  max-width: 590px;
}

.h-590 {
  height: 590px;
}

.mh-590 {
  max-height: 590px;
}

.w-600 {
  width: 600px;
}

.mw-600 {
  max-width: 600px;
}

.h-600 {
  height: 600px;
}

.mh-600 {
  max-height: 600px;
}

.w-610 {
  width: 610px;
}

.mw-610 {
  max-width: 610px;
}

.h-610 {
  height: 610px;
}

.mh-610 {
  max-height: 610px;
}

.w-620 {
  width: 620px;
}

.mw-620 {
  max-width: 620px;
}

.h-620 {
  height: 620px;
}

.mh-620 {
  max-height: 620px;
}

.w-630 {
  width: 630px;
}

.mw-630 {
  max-width: 630px;
}

.h-630 {
  height: 630px;
}

.mh-630 {
  max-height: 630px;
}

.w-640 {
  width: 640px;
}

.mw-640 {
  max-width: 640px;
}

.h-640 {
  height: 640px;
}

.mh-640 {
  max-height: 640px;
}

.w-650 {
  width: 650px;
}

.mw-650 {
  max-width: 650px;
}

.h-650 {
  height: 650px;
}

.mh-650 {
  max-height: 650px;
}

.w-660 {
  width: 660px;
}

.mw-660 {
  max-width: 660px;
}

.h-660 {
  height: 660px;
}

.mh-660 {
  max-height: 660px;
}

.w-670 {
  width: 670px;
}

.mw-670 {
  max-width: 670px;
}

.h-670 {
  height: 670px;
}

.mh-670 {
  max-height: 670px;
}

.w-680 {
  width: 680px;
}

.mw-680 {
  max-width: 680px;
}

.h-680 {
  height: 680px;
}

.mh-680 {
  max-height: 680px;
}

.w-690 {
  width: 690px;
}

.mw-690 {
  max-width: 690px;
}

.h-690 {
  height: 690px;
}

.mh-690 {
  max-height: 690px;
}

.w-700 {
  width: 700px;
}

.mw-700 {
  max-width: 700px;
}

.h-700 {
  height: 700px;
}

.mh-700 {
  max-height: 700px;
}

.w-710 {
  width: 710px;
}

.mw-710 {
  max-width: 710px;
}

.h-710 {
  height: 710px;
}

.mh-710 {
  max-height: 710px;
}

.w-720 {
  width: 720px;
}

.mw-720 {
  max-width: 720px;
}

.h-720 {
  height: 720px;
}

.mh-720 {
  max-height: 720px;
}

.w-730 {
  width: 730px;
}

.mw-730 {
  max-width: 730px;
}

.h-730 {
  height: 730px;
}

.mh-730 {
  max-height: 730px;
}

.w-740 {
  width: 740px;
}

.mw-740 {
  max-width: 740px;
}

.h-740 {
  height: 740px;
}

.mh-740 {
  max-height: 740px;
}

.w-750 {
  width: 750px;
}

.mw-750 {
  max-width: 750px;
}

.h-750 {
  height: 750px;
}

.mh-750 {
  max-height: 750px;
}

.w-760 {
  width: 760px;
}

.mw-760 {
  max-width: 760px;
}

.h-760 {
  height: 760px;
}

.mh-760 {
  max-height: 760px;
}

.w-770 {
  width: 770px;
}

.mw-770 {
  max-width: 770px;
}

.h-770 {
  height: 770px;
}

.mh-770 {
  max-height: 770px;
}

.w-780 {
  width: 780px;
}

.mw-780 {
  max-width: 780px;
}

.h-780 {
  height: 780px;
}

.mh-780 {
  max-height: 780px;
}

.w-790 {
  width: 790px;
}

.mw-790 {
  max-width: 790px;
}

.h-790 {
  height: 790px;
}

.mh-790 {
  max-height: 790px;
}

.w-800 {
  width: 800px;
}

.mw-800 {
  max-width: 800px;
}

.h-800 {
  height: 800px;
}

.mh-800 {
  max-height: 800px;
}

.w-810 {
  width: 810px;
}

.mw-810 {
  max-width: 810px;
}

.h-810 {
  height: 810px;
}

.mh-810 {
  max-height: 810px;
}

.w-820 {
  width: 820px;
}

.mw-820 {
  max-width: 820px;
}

.h-820 {
  height: 820px;
}

.mh-820 {
  max-height: 820px;
}

.w-830 {
  width: 830px;
}

.mw-830 {
  max-width: 830px;
}

.h-830 {
  height: 830px;
}

.mh-830 {
  max-height: 830px;
}

.w-840 {
  width: 840px;
}

.mw-840 {
  max-width: 840px;
}

.h-840 {
  height: 840px;
}

.mh-840 {
  max-height: 840px;
}

.w-850 {
  width: 850px;
}

.mw-850 {
  max-width: 850px;
}

.h-850 {
  height: 850px;
}

.mh-850 {
  max-height: 850px;
}

.w-860 {
  width: 860px;
}

.mw-860 {
  max-width: 860px;
}

.h-860 {
  height: 860px;
}

.mh-860 {
  max-height: 860px;
}

.w-870 {
  width: 870px;
}

.mw-870 {
  max-width: 870px;
}

.h-870 {
  height: 870px;
}

.mh-870 {
  max-height: 870px;
}

.w-880 {
  width: 880px;
}

.mw-880 {
  max-width: 880px;
}

.h-880 {
  height: 880px;
}

.mh-880 {
  max-height: 880px;
}

.w-890 {
  width: 890px;
}

.mw-890 {
  max-width: 890px;
}

.h-890 {
  height: 890px;
}

.mh-890 {
  max-height: 890px;
}

.w-900 {
  width: 900px;
}

.mw-900 {
  max-width: 900px;
}

.h-900 {
  height: 900px;
}

.mh-900 {
  max-height: 900px;
}

.w-910 {
  width: 910px;
}

.mw-910 {
  max-width: 910px;
}

.h-910 {
  height: 910px;
}

.mh-910 {
  max-height: 910px;
}

.w-920 {
  width: 920px;
}

.mw-920 {
  max-width: 920px;
}

.h-920 {
  height: 920px;
}

.mh-920 {
  max-height: 920px;
}

.w-930 {
  width: 930px;
}

.mw-930 {
  max-width: 930px;
}

.h-930 {
  height: 930px;
}

.mh-930 {
  max-height: 930px;
}

.w-940 {
  width: 940px;
}

.mw-940 {
  max-width: 940px;
}

.h-940 {
  height: 940px;
}

.mh-940 {
  max-height: 940px;
}

.w-950 {
  width: 950px;
}

.mw-950 {
  max-width: 950px;
}

.h-950 {
  height: 950px;
}

.mh-950 {
  max-height: 950px;
}

.w-960 {
  width: 960px;
}

.mw-960 {
  max-width: 960px;
}

.h-960 {
  height: 960px;
}

.mh-960 {
  max-height: 960px;
}

.w-970 {
  width: 970px;
}

.mw-970 {
  max-width: 970px;
}

.h-970 {
  height: 970px;
}

.mh-970 {
  max-height: 970px;
}

.w-980 {
  width: 980px;
}

.mw-980 {
  max-width: 980px;
}

.h-980 {
  height: 980px;
}

.mh-980 {
  max-height: 980px;
}

.w-990 {
  width: 990px;
}

.mw-990 {
  max-width: 990px;
}

.h-990 {
  height: 990px;
}

.mh-990 {
  max-height: 990px;
}

.w-1000 {
  width: 1000px;
}

.mw-1000 {
  max-width: 1000px;
}

.h-1000 {
  height: 1000px;
}

.mh-1000 {
  max-height: 1000px;
}

.w-1010 {
  width: 1010px;
}

.mw-1010 {
  max-width: 1010px;
}

.h-1010 {
  height: 1010px;
}

.mh-1010 {
  max-height: 1010px;
}

.w-1020 {
  width: 1020px;
}

.mw-1020 {
  max-width: 1020px;
}

.h-1020 {
  height: 1020px;
}

.mh-1020 {
  max-height: 1020px;
}

.w-1030 {
  width: 1030px;
}

.mw-1030 {
  max-width: 1030px;
}

.h-1030 {
  height: 1030px;
}

.mh-1030 {
  max-height: 1030px;
}

.w-1040 {
  width: 1040px;
}

.mw-1040 {
  max-width: 1040px;
}

.h-1040 {
  height: 1040px;
}

.mh-1040 {
  max-height: 1040px;
}

.w-1050 {
  width: 1050px;
}

.mw-1050 {
  max-width: 1050px;
}

.h-1050 {
  height: 1050px;
}

.mh-1050 {
  max-height: 1050px;
}

.w-1060 {
  width: 1060px;
}

.mw-1060 {
  max-width: 1060px;
}

.h-1060 {
  height: 1060px;
}

.mh-1060 {
  max-height: 1060px;
}

.w-1070 {
  width: 1070px;
}

.mw-1070 {
  max-width: 1070px;
}

.h-1070 {
  height: 1070px;
}

.mh-1070 {
  max-height: 1070px;
}

.w-1080 {
  width: 1080px;
}

.mw-1080 {
  max-width: 1080px;
}

.h-1080 {
  height: 1080px;
}

.mh-1080 {
  max-height: 1080px;
}

.w-1090 {
  width: 1090px;
}

.mw-1090 {
  max-width: 1090px;
}

.h-1090 {
  height: 1090px;
}

.mh-1090 {
  max-height: 1090px;
}

.w-1100 {
  width: 1100px;
}

.mw-1100 {
  max-width: 1100px;
}

.h-1100 {
  height: 1100px;
}

.mh-1100 {
  max-height: 1100px;
}

.w-1110 {
  width: 1110px;
}

.mw-1110 {
  max-width: 1110px;
}

.h-1110 {
  height: 1110px;
}

.mh-1110 {
  max-height: 1110px;
}

.w-1120 {
  width: 1120px;
}

.mw-1120 {
  max-width: 1120px;
}

.h-1120 {
  height: 1120px;
}

.mh-1120 {
  max-height: 1120px;
}

.w-1130 {
  width: 1130px;
}

.mw-1130 {
  max-width: 1130px;
}

.h-1130 {
  height: 1130px;
}

.mh-1130 {
  max-height: 1130px;
}

.w-1140 {
  width: 1140px;
}

.mw-1140 {
  max-width: 1140px;
}

.h-1140 {
  height: 1140px;
}

.mh-1140 {
  max-height: 1140px;
}

.w-1150 {
  width: 1150px;
}

.mw-1150 {
  max-width: 1150px;
}

.h-1150 {
  height: 1150px;
}

.mh-1150 {
  max-height: 1150px;
}

.w-1160 {
  width: 1160px;
}

.mw-1160 {
  max-width: 1160px;
}

.h-1160 {
  height: 1160px;
}

.mh-1160 {
  max-height: 1160px;
}

.w-1170 {
  width: 1170px;
}

.mw-1170 {
  max-width: 1170px;
}

.h-1170 {
  height: 1170px;
}

.mh-1170 {
  max-height: 1170px;
}

.w-full {
  width: 100%;
}

.mw-full {
  max-width: 100%;
}

.h-full {
  height: 100%;
}

.mh-full {
  max-height: 100%;
}

/* Rewrite some bootstrap's classes */
.h-1 {
  height: 1px;
}

.w-1 {
  width: 1px;
}

.ml-1 {
  margin-left: 1px !important;
}

.mr-1 {
  margin-right: 1px !important;
}

.mx-1 {
  margin-left: 1px !important;
  margin-right: 1px !important;
}

.h-2 {
  height: 2px;
}

.w-2 {
  width: 2px;
}

.ml-2 {
  margin-left: 2px !important;
}

.mr-2 {
  margin-right: 2px !important;
}

.mx-2 {
  margin-left: 2px !important;
  margin-right: 2px !important;
}

.h-3 {
  height: 3px;
}

.w-3 {
  width: 3px;
}

.ml-3 {
  margin-left: 3px !important;
}

.mr-3 {
  margin-right: 3px !important;
}

.mx-3 {
  margin-left: 3px !important;
  margin-right: 3px !important;
}

.h-4 {
  height: 4px;
}

.w-4 {
  width: 4px;
}

.ml-4 {
  margin-left: 4px !important;
}

.mr-4 {
  margin-right: 4px !important;
}

.mx-4 {
  margin-left: 4px !important;
  margin-right: 4px !important;
}

.h-5 {
  height: 5px;
}

.w-5 {
  width: 5px;
}

.ml-5 {
  margin-left: 5px !important;
}

.mr-5 {
  margin-right: 5px !important;
}

.mx-5 {
  margin-left: 5px !important;
  margin-right: 5px !important;
}

.h-6 {
  height: 6px;
}

.w-6 {
  width: 6px;
}

.ml-6 {
  margin-left: 6px !important;
}

.mr-6 {
  margin-right: 6px !important;
}

.mx-6 {
  margin-left: 6px !important;
  margin-right: 6px !important;
}

.h-7 {
  height: 7px;
}

.w-7 {
  width: 7px;
}

.ml-7 {
  margin-left: 7px !important;
}

.mr-7 {
  margin-right: 7px !important;
}

.mx-7 {
  margin-left: 7px !important;
  margin-right: 7px !important;
}

.h-8 {
  height: 8px;
}

.w-8 {
  width: 8px;
}

.ml-8 {
  margin-left: 8px !important;
}

.mr-8 {
  margin-right: 8px !important;
}

.mx-8 {
  margin-left: 8px !important;
  margin-right: 8px !important;
}

.h-9 {
  height: 9px;
}

.w-9 {
  width: 9px;
}

.ml-9 {
  margin-left: 9px !important;
}

.mr-9 {
  margin-right: 9px !important;
}

.mx-9 {
  margin-left: 9px !important;
  margin-right: 9px !important;
}

.h-10 {
  height: 10px;
}

.w-10 {
  width: 10px;
}

.ml-10 {
  margin-left: 10px !important;
}

.mr-10 {
  margin-right: 10px !important;
}

.mx-10 {
  margin-left: 10px !important;
  margin-right: 10px !important;
}

.w-25 {
  width: 25px !important;
}

.w-50 {
  width: 50px !important;
}

.w-75 {
  width: 75px !important;
}

.w-100 {
  width: 100px !important;
}

/* Font families */
.f-heading {
  font-family: 'Poppins';
}

.f-main {
  font-family: 'Poppins';
}

/* Font sizes */
.f-12 {
  font-size: 12px;
  line-height: 22px;
}

.f-14 {
  font-size: 14px;
  line-height: 24px;
}

.f-16 {
  font-size: 16px;
  line-height: 26px;
}

.f-18 {
  font-size: 18px;
  line-height: 28px;
}

.f-20 {
  font-size: 20px;
  line-height: 30px;
}

.f-22 {
  font-size: 22px;
  line-height: 32px;
}

.f-24 {
  font-size: 24px;
  line-height: 34px;
}

.f-26 {
  font-size: 26px;
  line-height: 36px;
}

.f-28 {
  font-size: 28px;
  line-height: 38px;
}

.f-30 {
  font-size: 30px;
  line-height: 40px;
}

.f-32 {
  font-size: 32px;
  line-height: 42px;
}

.f-34 {
  font-size: 34px;
  line-height: 44px;
}

.f-36 {
  font-size: 36px;
  line-height: 46px;
}

.f-38 {
  font-size: 38px;
  line-height: 48px;
}

.f-40 {
  font-size: 40px;
  line-height: 50px;
}

.f-42 {
  font-size: 42px;
  line-height: 52px;
}

.f-44 {
  font-size: 44px;
  line-height: 54px;
}

.f-46 {
  font-size: 46px;
  line-height: 56px;
}

.f-48 {
  font-size: 48px;
  line-height: 58px;
}

.f-50 {
  font-size: 50px;
  line-height: 60px;
}

.f-52 {
  font-size: 52px;
  line-height: 62px;
}

.f-54 {
  font-size: 54px;
  line-height: 64px;
}

.f-56 {
  font-size: 56px;
  line-height: 66px;
}

.f-58 {
  font-size: 58px;
  line-height: 68px;
}

.f-60 {
  font-size: 60px;
  line-height: 70px;
}

.f-62 {
  font-size: 62px;
  line-height: 72px;
}

.f-64 {
  font-size: 64px;
  line-height: 74px;
}

.f-66 {
  font-size: 66px;
  line-height: 76px;
}

.f-68 {
  font-size: 68px;
  line-height: 78px;
}

.f-70 {
  font-size: 70px;
  line-height: 80px;
}

.f-72 {
  font-size: 72px;
  line-height: 82px;
}

.f-74 {
  font-size: 74px;
  line-height: 84px;
}

.f-76 {
  font-size: 76px;
  line-height: 86px;
}

.f-78 {
  font-size: 78px;
  line-height: 88px;
}

.f-80 {
  font-size: 80px;
  line-height: 90px;
}

.f-82 {
  font-size: 82px;
  line-height: 92px;
}

.f-84 {
  font-size: 84px;
  line-height: 94px;
}

.f-86 {
  font-size: 86px;
  line-height: 96px;
}

.f-88 {
  font-size: 88px;
  line-height: 98px;
}

.f-90 {
  font-size: 90px;
  line-height: 100px;
}

.f-92 {
  font-size: 92px;
  line-height: 102px;
}

.f-94 {
  font-size: 94px;
  line-height: 104px;
}

.f-96 {
  font-size: 96px;
  line-height: 106px;
}

.f-98 {
  font-size: 98px;
  line-height: 108px;
}

.f-100 {
  font-size: 100px;
  line-height: 110px;
}

.f-102 {
  font-size: 102px;
  line-height: 112px;
}

.lh-12 {
  line-height: 12px;
}

.lh-14 {
  line-height: 14px;
}

.lh-16 {
  line-height: 16px;
}

.lh-18 {
  line-height: 18px;
}

.lh-20 {
  line-height: 20px;
}

.lh-22 {
  line-height: 22px;
}

.lh-24 {
  line-height: 24px;
}

.lh-26 {
  line-height: 26px;
}

.lh-28 {
  line-height: 28px;
}

.lh-30 {
  line-height: 30px;
}

.lh-32 {
  line-height: 32px;
}

.lh-34 {
  line-height: 34px;
}

.lh-36 {
  line-height: 36px;
}

.lh-38 {
  line-height: 38px;
}

.lh-40 {
  line-height: 40px;
}

.lh-42 {
  line-height: 42px;
}

.lh-44 {
  line-height: 44px;
}

.lh-46 {
  line-height: 46px;
}

.lh-48 {
  line-height: 48px;
}

.lh-50 {
  line-height: 50px;
}

.lh-52 {
  line-height: 52px;
}

.lh-54 {
  line-height: 54px;
}

.lh-56 {
  line-height: 56px;
}

.lh-58 {
  line-height: 58px;
}

.lh-60 {
  line-height: 60px;
}

.lh-62 {
  line-height: 62px;
}

.lh-64 {
  line-height: 64px;
}

.lh-66 {
  line-height: 66px;
}

.lh-68 {
  line-height: 68px;
}

.lh-70 {
  line-height: 70px;
}

.lh-72 {
  line-height: 72px;
}

.lh-74 {
  line-height: 74px;
}

.lh-76 {
  line-height: 76px;
}

.lh-78 {
  line-height: 78px;
}

.lh-80 {
  line-height: 80px;
}

.lh-82 {
  line-height: 82px;
}

.lh-84 {
  line-height: 84px;
}

.lh-86 {
  line-height: 86px;
}

.lh-88 {
  line-height: 88px;
}

.lh-90 {
  line-height: 90px;
}

.lh-92 {
  line-height: 92px;
}

.lh-94 {
  line-height: 94px;
}

.lh-96 {
  line-height: 96px;
}

.lh-98 {
  line-height: 98px;
}

.lh-100 {
  line-height: 100px;
}

.lh-102 {
  line-height: 102px;
}

.lh-104 {
  line-height: 104px;
}

.lh-106 {
  line-height: 106px;
}

.lh-108 {
  line-height: 108px;
}

.lh-110 {
  line-height: 110px;
}

.lh-112 {
  line-height: 112px;
}

.lh-114 {
  line-height: 114px;
}

.lh-116 {
  line-height: 116px;
}

.lh-118 {
  line-height: 118px;
}

.lh-120 {
  line-height: 120px;
}

.lh-122 {
  line-height: 122px;
}

.lh-124 {
  line-height: 124px;
}

.lh-126 {
  line-height: 126px;
}

.lh-128 {
  line-height: 128px;
}

.lh-130 {
  line-height: 130px;
}

.lh-132 {
  line-height: 132px;
}

.lh-134 {
  line-height: 134px;
}

.lh-136 {
  line-height: 136px;
}

.lh-138 {
  line-height: 138px;
}

.lh-140 {
  line-height: 140px;
}

.lh-142 {
  line-height: 142px;
}

.lh-144 {
  line-height: 144px;
}

.lh-146 {
  line-height: 146px;
}

.lh-148 {
  line-height: 148px;
}

.lh-150 {
  line-height: 150px;
}

.lh-152 {
  line-height: 152px;
}

.lh-154 {
  line-height: 154px;
}

.lh-156 {
  line-height: 156px;
}

.lh-158 {
  line-height: 158px;
}

.lh-160 {
  line-height: 160px;
}

.lh-162 {
  line-height: 162px;
}

.lh-164 {
  line-height: 164px;
}

.lh-166 {
  line-height: 166px;
}

.lh-168 {
  line-height: 168px;
}

.lh-170 {
  line-height: 170px;
}

.lh-172 {
  line-height: 172px;
}

.lh-174 {
  line-height: 174px;
}

.lh-176 {
  line-height: 176px;
}

.lh-178 {
  line-height: 178px;
}

.lh-180 {
  line-height: 180px;
}

.lh-182 {
  line-height: 182px;
}

.lh-184 {
  line-height: 184px;
}

.lh-186 {
  line-height: 186px;
}

.lh-188 {
  line-height: 188px;
}

.lh-190 {
  line-height: 190px;
}

.lh-192 {
  line-height: 192px;
}

.lh-194 {
  line-height: 194px;
}

.lh-196 {
  line-height: 196px;
}

.lh-198 {
  line-height: 198px;
}

.lh-200 {
  line-height: 200px;
}

.lh-202 {
  line-height: 202px;
}

/* Colors */
.action-1 {
  color: #666666;
}
.action-1.link:hover {
  color: black;
}

.action-2 {
  color: #000d79;
}
.action-2.link:hover {
  color: #072d8f;
}

.action-3 {
  color: #000d79;
}
.action-3.link:hover {
  color: #cbab0a;
}

.color-main {
  color: #666666;
  background-color:#ffffff
}
.color-main.link:hover {
  color: #333333;
}

.color-heading {
  color: #000d79;
}
.color-heading.link:hover {
  color: #dd1717;
}

.color-white {
  color: #ffffff;
}
.color-white.link:hover {
  color: rgba(255, 255, 255, 0.7);
}

.color-transparent-white {
  color: rgba(255, 255, 255, 0.8);
}
.color-transparent-white.link:hover {
  color: #cccccc;
}

.color-gray {
  color: #EDEDED;
}
.color-gray.link:hover {
  color: #bababa;
}

.color-bg-light {
  color: #cccccc;
}
.color-bg-light.link:hover {
  color: #e6e6e6;
}

.color-bg-dark {
  color: #000d79;
}
.color-bg-dark.link:hover {
  color: #dd1717;
}

.color-red {
  color: #EA3223;
}
.color-red.link:hover {
  color: #c72113;
}

.color-twitter {
  color: #0aa0f5;
}
.color-twitter.link:hover {
  color: #0880c4;
}

.color-facebook {
  color: #2e4da7;
}
.color-facebook.link:hover {
  color: #3e62ca;
}

.color-dribbble {
  color: #E93A7D;
}
.color-dribbble.link:hover {
  color: #d81862;
}

/* background-colors */
.bg-light {
  background-color: #ffffff !important;
}
.bg-dark {
  background-color: #000d79 !important;
}
.bg-main {
  background-color: #666666;
}
.bg-heading {
  background-color: #000d79;
}
.bg-white {
  background-color: #cccccc;
}
.bg-gray {
  background-color: #EDEDED;
}
.bg-action-1 {
  background-color: #666666;
}
.bg-action-2 {
  background-color: #000d79;
}
.bg-action-3 {
  background-color: #000d79;
}

/* color filters */
[class^=color-filter-]:not(.absolute), [class^=color-filter-]:not(.fixed), [class*=" color-filter-"]:not(.absolute), [class*=" color-filter-"]:not(.fixed) {
  position: relative;
}
[class^=color-filter-]:before, [class*=" color-filter-"]:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
}

/* 
picture[class^="color-filter-"], picture[class*=" color-filter-"]{
	display:inline-block;
	overflow:hidden;
	&:before{
		z-index:1;
	}
}
 */
.color-filter-light-1:before {
  background-color: rgba(255, 255, 255, 0.1);
}

.color-filter-dark-1:before {
  background-color: rgba(175, 18, 18, 0.1);
}

.color-filter-light-2:before {
  background-color: rgba(255, 255, 255, 0.2);
}

.color-filter-dark-2:before {
  background-color: rgba(175, 18, 18, 0.2);
}

.color-filter-light-3:before {
  background-color: rgba(255, 255, 255, 0.3);
}

.color-filter-dark-3:before {
  background-color: rgba(175, 18, 18, 0.3);
}

.color-filter-light-4:before {
  background-color: rgba(255, 255, 255, 0.4);
}

.color-filter-dark-4:before {
  background-color: rgba(175, 18, 18, 0.4);
}

.color-filter-light-5:before {
  background-color: rgba(255, 255, 255, 0.5);
}

.color-filter-dark-5:before {
  background-color: rgba(175, 18, 18, 0.5);
}

.color-filter-light-6:before {
  background-color: rgba(255, 255, 255, 0.6);
}

.color-filter-dark-6:before {
  background-color: rgba(175, 18, 18, 0.6);
}

.color-filter-light-7:before {
  background-color: rgba(255, 255, 255, 0.7);
}

.color-filter-dark-7:before {
  background-color: rgba(175, 18, 18, 0.7);
}

.color-filter-light-8:before {
  background-color: rgba(255, 255, 255, 0.8);
}

.color-filter-dark-8:before {
  background-color: rgba(175, 18, 18, 0.8);
}

.color-filter-light-9:before {
  background-color: rgba(255, 255, 255, 0.9);
}

.color-filter-dark-9:before {
  background-color: rgba(175, 18, 18, 0.9);
}

/* Border-radiuses */
.radius4 {
  border-radius: 4px;
}

.radius6 {
  border-radius: 6px;
}

.radius8 {
  border-radius: 8px;
}

.radius10 {
  border-radius: 10px;
}

.radius12 {
  border-radius: 12px;
}

.radius14 {
  border-radius: 14px;
}

.radius16 {
  border-radius: 16px;
}

.radius18 {
  border-radius: 18px;
}

.radius20 {
  border-radius: 20px;
}

.radius22 {
  border-radius: 22px;
}

.radius24 {
  border-radius: 24px;
}

.radius26 {
  border-radius: 26px;
}

.radius28 {
  border-radius: 28px;
}

.radius30 {
  border-radius: 30px;
}

.radius32 {
  border-radius: 32px;
}

.radius_full {
  border-radius: 50%;
}

.noradius {
  border-radius: 0;
}

.noradius_top {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.noradius_right {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.noradius_bottom {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.noradius_left {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

/* Position */
.relative {
  position: relative;
}

.absolute {
  position: absolute;
}
/* (A) SAME DIMENSIONS FOR OUTER CONTAINER & SLIDES */
.vwrap, .vslide {
  box-sizing: border-box;
  width: 100%;
  height: 0px;
}
       
      /* (B) OUTER CONTAINER */
.vwrap {
        overflow: hidden; /* HIDE SCROLLBARS */
    }
	     
	    /* (C) MIDDLE CONTAINER */
    .vmove {
	      position: relative;
              bottom: 0%;
	}
		 
		/* (D) SLIDES */
		.vslide { padding: 5px; }

		/* (E) SLIDE ANIMATION */
		@keyframes slidev {
			  /* (E0) THE IDEA - USE KEYFRAMES TO SHIFT SLIDES *
			   *   0% { bottom: 0; }
			   *   33% { bottom: 100%; }
			   *   66% { bottom: 200%; }
			   *   100% { right: 0; }
			   *       
			   *   /* (E1) BUT THE ABOVE WILL SHIFT NON-STOP */
			     /* SO WE ADD PAUSES BETWEEN EACH SLIDE */
			     0% { bottom: 0; }
			     24% { bottom: 0; }
			     25% { bottom: 100%; }
			     49% { bottom: 100%; }
			     50% { bottom: 200%; }
			     74% { bottom: 200%; }
			     75% { bottom: 300%; }
			     99% { bottom: 300%; }
			     100% { right: 0; }
			 }
		.vmove { animation: slidev ease-in 12s infinite; }
		.vmove:hover { animation-play-state: paused; }
