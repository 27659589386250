/* Use a media query to add a break point at 800px: */

    .video-sec {
	    padding:20px;
	    float:left;
	    width:40%; /* The width is 60%, by default */
    }
        .form {
	       padding:20px;
	       float:right;
	       width:60%; /* The width is 20%, by default */
	}
	max-width < 800px {
	.login {
	       padding:20px;
	       float:right;
	       width:50%; /* The width is 20%, by default */
	       flex: 1 1;
	}
	.loginimg {
		width:40%;
	}
}
	
	input.login{
	float:left;
	margin-left:100px;
	}
	
	.limsthumb {
	transition: transform 0.5s;
	}
	.limsthumb:hover {
	-ms-transform: scale(3);
	-webkit-transform: scale(3);
	transform: scale(3);
  z-index: 10;
	
	}
        .flex-container {
	      display: flex;
	      font-size: 20px;
	      text-align: right;
	      flex-flow:row wrap;
	}
	.row.invite{
		margin-right: -169px;
		margin-left:0px;
	}

	.flex-item-left {
	    padding: 10px;
	    flex: 50%;
	}

     .flex-item-right {
	    padding: 10px;
	    flex: 50%;
	}
     .flex-item{
	  display: flex;
	  padding: 10px;
	  flex: 33%;
     }
     
     .css-serial {
      counter-reset: serial-number;  /* Set the serial number counter to 0 */
     }

      .css-serial td:first-child:before #exam_id {
        counter-increment: serial-number;  /* Increment the serial number counter */
        content: counter(serial-number);  /* Display the counter */
      }

        /* Responsive layout - makes a one column layout instead of a two-column layout */
       @media (max-width: 800px) {
	         .flex-container {
	                flex-direction: column;
	        }
	 
	        .login {
		            width:"100%";
	        }
	 
	        .loginimg {
		         width:"100%";
	        }
       }
       
       input {
           width:100%; /* width is 70% when viewport is larger  than 800px*/
	   border:none;
	   margin:auto;
       } 
       @media screen and (max-width:800px) {
	        .form, .video-sec, .section,.article, input, select, textarea {
	            width:90%; /* The width is 100%, when the viewport is 800px or smaller */
	        }
	    }
	h1,h2,h3,h4,h5,p {
		margin-bottom:0.3rem;

	}

	tr:hover {background-color:#ebeff3; color:"white"}

	/*td, th {
		    border-top: solid 1px #eee;
	        padding: 8px;
		    color:#444;
	}

	 th {
		      padding-top: 12px;
	          padding-bottom: 12px;
		      text-align: left;
      }*/
		      

